import { Slide } from '@/components/templates/homepage/campaignSlider';
import base from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const { autopromo, type, path } = action.payload;

  const pathName = path.split('/')[1];
  const eventData = autopromo.map((promo: Slide, index: number) => {
    const campaignName = promo.badge
      ? `${promo.badge.split(' ').join('_')}::${promo.campaign}`
      : promo.campaign;
    return {
      autopromo_name: campaignName?.toLowerCase(),
      /* autopromo_creative: '', */
      autopromo_position: index + 1,
      autopromo_type: `${pathName}::${type}`
    };
  });

  return {
    ...baseData,
    e_action: 'autopromo impression',
    e_category: 'ecommerce',
    event: 'autopromo impression',
    e_label: type,
    autopromo_impression: [...eventData]
  };
};

export default getData;
