import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  return {
    ...baseData,
    e_action: 'cross sell::clic produit',
    e_category: 'marketplace',
    event: 'generic',
    e_label: action.payload.itemId
  };
};

export default getData;
