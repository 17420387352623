export const TOGGLE_MINICART_OPENED = 'TOGGLE_MINICART_OPENED';
export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_TRACKING_BY_PHONE = 'UPDATE_TRACKING_BY_PHONE';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const ADD_SLOTS = 'ADD_SLOTS';
export const ADD_SELECTED_SLOT = 'ADD_SELECTED_SLOT';
export const UPDATE_SELECTED_VOUCHER = 'UPDATE_SELECTED_VOUCHER';
export const UPDATE_SELECTED_FID = 'UPDATE_SELECTED_FID';
export const DELETE_ITEM_TO_CART = 'DELETE_ITEM_TO_CART';
export const CHANGE_NUMBER_ITEM_TO_CART = 'CHANGE_NUMBER_ITEM_TO_CART';
export const RESET_SLOTS = 'RESET_SLOTS';
export const DELETE_SELLER_SUB_CART = 'DELETE_SELLER_SUB_CART';
export const ACCEPT_GAMES_CONTEST = 'ACCEPT_GAMES_CONTEST';
export const UPDATE_DELIVERY_COMMENT = 'UPDATE_DELIVERY_COMMENT';
export const DELETE_ALL_CARTS = 'DELETE_ALL_CARTS';
export const SELECT_PROVIDER = 'SELECT_PROVIDER';
export const CONFIRM_PROVIDER = 'CONFIRM_PROVIDER';
