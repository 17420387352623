import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import { NavStateType } from '@/store/nav/navReducer';
import dayjs from 'dayjs';
import usePdv from '@/lib/hooks/usePdv';
import { getNavPromotions } from '@/store/nav/actions/promotions';

const NavPromotions = () => {
  const dispatch = useDispatch();
  const { user, promotions, navIsHydrated } = useSelector(
    ({ auth, nav }: { auth: AuthStateType; nav: NavStateType }) => {
      return {
        navIsHydrated: nav.isHydrated,
        user: auth.user,
        promotions: nav.promotions
      };
    }
  );
  const isAuthenticated = !!user;

  useEffect(() => {
    (async () => {
      if (navIsHydrated) {
        const { ref, isVirtual } = usePdv();

        if (!ref || isVirtual) return;

        const storePromotions = promotions[ref] ?? null;
        const promotionsUpdatedDate = storePromotions?.updatedAt;

        if (
          !storePromotions ||
          !promotionsUpdatedDate ||
          dayjs().diff(promotionsUpdatedDate, 'minute') > 15
        ) {
          await dispatch(getNavPromotions(ref));
        }
      }
    })();
  }, [user?.id, isAuthenticated, dispatch, navIsHydrated, promotions]);
  return <></>;
};

export default NavPromotions;
