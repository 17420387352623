import base from './base';

const getData = async (action: any, state: any) => {
  const baseData = base(action, state);

  const categories = action.payload.baseUrl
    .replace('rayons', '')
    .replace(/-/g, '_')
    .split('/')
    .filter((n: string) => n);
  categories.pop();

  const { catTilte } = action.payload;

  return {
    ...baseData,
    page_type: 'rayons',
    page_name: action.payload.filters.length ? 'famille_filtre' : 'famille',
    page_virtual_path: `/courses/rayon/${categories[0] ?? ''}/${
      categories[1] ?? ''
    }/${catTilte}`
      .replace(/ /g, '_')
      .toLowerCase(),
    page_sort: '',
    page_filter: '',
    page_category_id: action.payload.categoryId ?? '',
    page_category: categories[0] ?? '',
    page_sub_category: categories[1] ?? '',
    page_sub_sub_category: action.payload.catTilte
  };
};

export default getData;
