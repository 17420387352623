import React, { useEffect, useCallback, useState } from 'react';
import './style.scss';
import getConfigValue from '@/lib/config';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import { CLEAR_EVENTS } from '@/store/cart/cartActions';
import {
  SESSION_EXPIRED,
  RESET_SESSION_EXPIRED,
  LOGOUT
} from '@/store/auth/authActions';
import security from '@/lib/api/security';
import { SessionExpiredException } from '@/lib/api/security/exceptions';
import Modal from '@/components/organisms/modal';
import t from '@/lib/i18n';

const TTL_INTERVAL_PING =
  getConfigValue('AUTH_PING_INTERVAL', 60).toNumber() * 1000;

const Auth = () => {
  const [isSecuredSpace, setIsSecuredSpace] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const dispatch = useDispatch();

  const { userId, stateSessionExpired, doLogout } = useSelector(
    ({ auth }: { auth: AuthStateType }) => {
      return {
        userId: auth.user?.id,
        stateSessionExpired: auth.sessionExpired,
        doLogout: auth.doLogout
      };
    }
  );

  const ping = useCallback(async () => {
    try {
      await security.ping();
    } catch (error: any) {
      if (error instanceof SessionExpiredException) {
        dispatch({
          type: CLEAR_EVENTS
        });
        dispatch({
          type: SESSION_EXPIRED
        });

        if (isSecuredSpace) {
          window.location.href = '/home';
        }
      }
    }
  }, [dispatch, isSecuredSpace]);

  useEffect(() => {
    setIsSessionExpired(stateSessionExpired);
  }, [stateSessionExpired]);

  useEffect(() => {
    let interval: number | undefined;

    if (!userId && isSecuredSpace) {
      window.location.href = '/home';

      return undefined;
    }

    if (userId) {
      interval = window.setInterval(ping, TTL_INTERVAL_PING);
      ping();
    }

    return () => (interval ? clearInterval(interval) : undefined);
  }, [userId, isSecuredSpace, ping]);

  useEffect(() => {
    const isSecuredSpaceRegex = /^(\/account-management\/.*)$/;
    const match = window.location.pathname.match(isSecuredSpaceRegex);

    setIsSecuredSpace(Array.isArray(match) && match.length > 0);
  }, []);

  useEffect(() => {
    if (doLogout && userId) {
      (async () => {
        await security.logout();
        dispatch({
          type: CLEAR_EVENTS
        });
        dispatch({
          type: LOGOUT
        });
      })();
    }
  }, [doLogout, dispatch, userId]);

  return (
    <>
      <Modal
        className="authCheck"
        open={isSessionExpired && !isSecuredSpace}
        onClose={() => {
          dispatch({
            type: RESET_SESSION_EXPIRED
          });
        }}
      >
        <h2 className="authCheck__title">{t('authCheck.title')}</h2>
        <p className="authCheck__content">{t('authCheck.content')}</p>
      </Modal>
    </>
  );
};

export default Auth;
