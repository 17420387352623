import base, { GlobalProductFormatter } from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const { items, step, options, deliveryPartner, path } = action.payload;

  const route = path.split('/').splice(1).join('/');

  const ProductsPayload = (productsList: any): Array<any> =>
    productsList.map((item: any) => {
      const globalData = GlobalProductFormatter(item.product, route, item.qty);
      let productList;
      switch (step) {
        case '1':
          productList = globalData;
          break;
        case '2':
          productList = {
            ...globalData,
            product_substituable: item.acceptSubstitution ? '1' : '0'
          };
          break;
        case '3':
        case '3bis':
        case '4':
          productList = {
            ...globalData,
            product_substituable: item.acceptSubstitution ? '1' : '0',
            checkout_delivery_option: options?.deliveryType,
            checkout_slot_time: `${options?.slot?.day}_${options?.slot?.startTime}-${options?.slot?.endTime}`
          };
          if (deliveryPartner) {
            productList = {
              ...productList,
              checkout_delivery_partner: deliveryPartner
            };
          }
          break;
      }
      return productList;
    });

  const productsCheckout = ProductsPayload(items);

  return {
    ...baseData,
    e_action: `checkout`,
    e_category: 'ecommerce',
    e_label: `step ${step}`,
    p_path: path,
    product_checkout: [...productsCheckout]
  };
};

export default getData;
