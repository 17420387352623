import React from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';

const config = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined
} as ToastOptions;

export const notification = {
  success(message: string) {
    toast.success(message, config);
  },
  error(message: string) {
    toast.error(message, {
      ...config,
      autoClose: false
    });
  },
  info(message: string) {
    toast.error(message, config);
  }
};

export const NotificationContainer = () => {
  return (
    <ToastContainer
      className="notificationContainer"
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};
