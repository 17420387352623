/* eslint max-classes-per-file: off */
import t from '@/lib/i18n';

export class TimeOutException extends Error {
  constructor(message = 'Timeout exception.') {
    super(message);
  }
}
export class UnknownErrorException extends Error {
  constructor(message = 'Unknown error exception.') {
    super(message);
  }
}

export interface ICardValidationErrorException {
  getTrad(p?: string): string;
}

export class CardSizeErrorException
  extends Error
  implements ICardValidationErrorException {
  tradId = 'form.error.fidelityCardNumber.size';

  constructor(message = 'Invalid size') {
    super(message);
  }

  getTrad() {
    return t(this.tradId);
  }
}
export class CardResignedErrorException
  extends Error
  implements ICardValidationErrorException {
  tradId = 'form.error.fidelityCardNumber.isResigned';

  constructor(message = 'Card isResigned') {
    super(message);
  }

  getTrad() {
    return t(this.tradId);
  }
}
export class CardOwnerErrorException
  extends Error
  implements ICardValidationErrorException {
  tradId = 'form.error.fidelityCardNumber.isOwner';

  constructor(message = 'Card Owner invalid') {
    super(message);
  }

  getTrad() {
    return t(this.tradId);
  }
}
export class CardSynchroErrorException
  extends Error
  implements ICardValidationErrorException {
  tradId = 'form.error.fidelityCardNumber.synchroError';

  constructor(message = 'Card synchro Error') {
    super(message);
  }

  getTrad() {
    return t(this.tradId);
  }
}
export class CardTechnicalErrorException
  extends Error
  implements ICardValidationErrorException {
  tradId = '';

  constructor(message = 'TECHNICAL_ERROR') {
    super(message);
    this.tradId = `form.error.fidelityCardNumber.${message}`;
  }

  getTrad() {
    return t(this.tradId);
  }
}

export const getFidelityCardNumberValidationError = (code: string) => {
  switch (code) {
    case '1':
    case '2':
    case '5':
    case '9':
      return new CardSizeErrorException();
    case '3':
      return new CardResignedErrorException();
    case '4':
      return new CardOwnerErrorException();
    case 'erreur.synchro.compte.inactif.impossible':
    case 'erreur.synchro.compte.actif.impossible':
      return new CardSynchroErrorException(code);
    case 'TECHNICAL_ERROR':
    case 'CARD_INVALID':
    case 'BAD_REQUEST':
    case 'CARD_OUTDATED':
    case 'CARD_RESILATED':
    case 'UNKNOWN':
    case 'USER_INFO_INCORRECT':
    case 'METHOD_ARGUMENT_NOT_VALID':
    case 'LOYALTY_CARD_ALREADY_ASSOCIATED':
    case '503':
    case 'MULTI_STATUS':
      return new CardTechnicalErrorException(code);
    default:
      return new CardTechnicalErrorException();
  }
};
