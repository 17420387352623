import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const type = action.payload.type as 'search' | 'autocomplete';
  let data = {};

  switch (type) {
    case 'autocomplete':
      data = {
        ...data,
        e_action: 'classique::mot cle'
      };
      break;
    case 'search':
      data = {
        ...data,
        e_action: 'autocompletion::mot cle'
      };
      break;
  }

  return {
    ...baseData,
    ...data,
    e_category: 'recherche produit',
    e_label: action.payload.keyword
  };
};

export default getData;
