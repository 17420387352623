/* eslint max-classes-per-file: off */

export class DisabledAccountException extends Error {
  constructor(message = 'Account disabled exception.') {
    super(message);
  }
}

export class BlockedAccountException extends Error {
  constructor(message = 'Blocked account exception.') {
    super(message);
  }
}

export class SessionExpiredException extends Error {
  constructor(message = 'Session expired exception.') {
    super(message);
  }
}

export class UnauthorizedException extends Error {
  constructor(message = 'Unauthorized exception.') {
    super(message);
  }
}

export class InternalServerException extends Error {
  constructor(message = 'Internal server exception.') {
    super(message);
  }
}

export class LogoutException extends Error {
  constructor(message = 'Unable to logout.') {
    super(message);
  }
}
