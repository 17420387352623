import { AxiosError } from 'axios';
import pino, { Logger as PinoLogger } from 'pino';
import getConfigValue from '@/lib/config';

export const baseConfig = {
  timestamp: pino.stdTimeFunctions.isoTime,
  base: null,
  messageKey: 'message',
  formatters: {
    level(label: string) {
      return {
        level: label
      };
    }
  }
};

type Params = {
  channel?: string;
  message: string;
  context?: { [key: string]: any };
  error?: Error | AxiosError;
};

export class Logger {
  pino: PinoLogger;

  constructor() {
    this.pino = pino({
      ...baseConfig
    });
  }

  info = (params: Params) => {
    const data = this._getData(params);

    if (data) {
      this.pino.info(data);
    }
  };

  warn = (params: Params) => {
    const data = this._getData(params);

    if (data) {
      this.pino.warn(data);
    }
  };

  error = (params: Params) => {
    const data = this._getData(params);

    if (data) {
      this.pino.error(data);
    }
  };

  _getData = ({
    channel = 'app',
    message,
    context = {},
    error
  }: Params): { [key: string]: any } | null => {
    const data: { [key: string]: any } = {
      channel,
      message,
      context
    };

    if (error) {
      const axiosError = error as AxiosError;

      if (axiosError.isAxiosError ?? false) {
        data.trace = {
          ...data.trace,
          'x-red-traceid': axiosError.response?.headers['x-red-traceid'],
          'x-red-error': axiosError.response?.headers['x-red-error']
        };
      }

      data.context = {
        ...data.context,
        error: error.message
      };
    }

    if (!getConfigValue(`LOGGER_${channel.toUpperCase()}`, false).toBoolean()) {
      return null;
    }

    return data;
  };
}

export default new Logger();
