import getConfigValue from '../config';
import Product from './product';

export const API_FILTER_BRANDS = '1';
export const API_FILTER_PREFERENCES = '2';
export const API_FILTER_PROMOTIONS = '3';
export const API_FILTER_CATEGORIES = '4';

export const FILTER_BRANDS = 'marques';
export const FILTER_PREFERENCES = 'preferences';
export const FILTER_PROMOTIONS = 'promotions';
export const FILTER_CATEGORY = 'categories';

const CDN_HOST = getConfigValue('CDN_HOST').toString();
const CDN_FOR_IMAGES = getConfigValue('CDN_FOR_IMAGES', false).toBoolean();

export const SORT_RELEVANCE = 'pertinence';
export const SORT_PRICE = 'prix';
export const SORT_PRICE_PER_KG = 'prixkg';

export const SORT_ASCENDING = 'croissant';
export const SORT_DESCENDING = 'decroissant';

export type Sort = {
  type: string;
  direction?: string;
};

export type Meta = {
  total: number;
  page: number;
  perPage: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export type FilterValue = {
  id: number;
  label: string;
  countProducts: number;
  active?: boolean;
  disabled?: boolean;
  extra?: { [key: string]: string | number | null };
};

export type FilterType = {
  type: string;
  values: Array<string>;
};

export type FiltersType = Array<FilterType>;

export type Filter = {
  type: string;
  values: Array<FilterValue>;
};

export type Filters = Array<Filter>;

class ProductList {
  shop?: {
    id: string;
    name: string;
  };

  banner?: string;

  products: Array<Product> = [];

  meta: Meta = {
    total: 0,
    page: 1,
    perPage: 40,
    hasPreviousPage: false,
    hasNextPage: false
  };

  filters: Filters = [];

  hasAlcohol = false;

  headings: Array<FilterValue> = [];

  constructor(data: any) {
    this.computeMeta(data);
    this.computeProducts(data);
    this.computeFilters(data);
    this.computeHeadings(data);

    if (data?.images?.boutiqueFond) {
      let img: string = data?.images?.boutiqueFond;
      if (CDN_FOR_IMAGES && img.match(/^.*\.intermarche\.com\/.*/)) {
        const url = new URL(img);
        img = CDN_HOST + url.pathname;
      }
      this.banner = img;
    }
    //  this.banner = data?.images?.boutiqueFond;

    if (data?.id) {
      this.shop = {
        id: data?.id,
        name: data?.titre
      };
    }
  }

  computeProducts(data: any) {
    const products = data.produits ?? [];

    products.forEach((product: any) => {
      this.hasAlcohol = this.hasAlcohol || product.isPresentAlcoholProduct;
      this.products.push(new Product(product));
    });
  }

  computeMeta(data: any) {
    let page;
    let total;
    let perPage;
    let maxPage;

    if (!data.searchResultsMetaData) {
      // Todo : withdraw this code and ask service to add data for virtual pdv
      // more infos, see https://mousquetaires.atlassian.net/browse/ECITM-14065
      page = 1;
      total = data.produits?.length || 0;
      perPage = 40;
      maxPage = Math.round(total / perPage);
    } else {
      page = data.searchResultsMetaData.currentPage;
      total = data.searchResultsMetaData.resultNbre;
      perPage = data.searchResultsMetaData.resultNbrePerPage;
      maxPage = Math.round(total / perPage);
    }

    this.meta = {
      total,
      page,
      perPage,
      hasPreviousPage: page > 1 && page <= maxPage,
      hasNextPage: total / perPage > page
    };
  }

  computeFilters(data: any) {
    const filtersData = data['filtres'] ?? [];

    filtersData.forEach((filterData: any) => {
      let type: string | null = null;

      switch (filterData['type'].toString()) {
        case API_FILTER_BRANDS:
          type = FILTER_BRANDS;
          break;
        case API_FILTER_PREFERENCES:
          type = FILTER_PREFERENCES;
          break;
        case API_FILTER_PROMOTIONS:
          type = FILTER_PROMOTIONS;
          break;
        case API_FILTER_CATEGORIES:
          type = FILTER_CATEGORY;
          break;
      }

      if (!type) {
        return;
      }

      let filter = this.filters.find((f: Filter) => {
        return f.type === type;
      });

      if (!filter) {
        filter = {
          type,
          values: []
        };

        this.filters.push(filter);
      }

      filter.values.push({
        id: filterData['id'],
        label: filterData['libelle'],
        countProducts: filterData['nbProduits']
      });
    });
  }

  computeHeadings(data: any) {
    const headings = data.rubriques ?? [];

    headings.forEach((heading: any) => {
      this.headings.push({
        id: Number(heading.id),
        label: heading.libelle,
        countProducts: heading.nbProduits
      });
    });
  }
}

export default ProductList;
