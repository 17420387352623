import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLEANING_REGISTER_STORE } from '@/store/register/registerActions';
import { useRouter } from 'next/router';
import { GlobalState } from '@/store';

const Register = () => {
  const dispatch = useDispatch();

  const router = useRouter();
  const isHydrated = useSelector<GlobalState>(
    ({ register }) => register.isHydrated
  );

  useEffect(() => {
    // Make sure the cleaning is done after the hydratation process
    if (isHydrated && !router.asPath.match(/\/create-my-account\.*/)?.length) {
      dispatch({ type: CLEANING_REGISTER_STORE });
    }
  }, [dispatch, router.asPath, isHydrated]);
  return <></>;
};

export default Register;
