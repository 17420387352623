import { StateType as CartStateType } from '@/store/cart/types';
import { OrderDetail } from '@/lib/api/order/types';
import { slugify } from '@/lib/utils/stringUtils';
import { getCategoriesName } from '@/lib/utils/categories';
import base from './base';

type PurchaseItmPayloadType = {
  order_coupon_value: number;
  order_delivery_partner: string | null;
  order_payment_option: string | null;
  order_product_quantity: number;
  order_service_type: string | null;
  order_slot_time: string | null;
  order_voucher_code_value: number;
  product_purchase: Array<PurchaseProductType>;
};

export type PurchasePayloadType = {
  e_label: string | null;
  order_id: string | null;
  order_total_value: number;
  order_itm_total: number;
  order_mkp_total: number;
  order_type: string;
  purchase_itm: Array<PurchaseItmPayloadType>;
  purchase_mkp: [];
  stickers_optin: number;
};

type PurchaseProductType = {
  product_id: string | null;
  product_name: string | null;
  product_brand: string | null;
  product_type: string | null;
  product_category: string | null;
  product_sub_category: string | null;
  product_sub_sub_category: string | null;
  product_price: number;
  // product_promo_type: string | null;
  // product_promo_mechanics: string | null;
  product_quantity: number;
  product_substituable: string | null;
  product_seller_name: string | null;
  product_seller_ranking: string | null;
  product_seller_rating_number: string | null;
  product_offer_number: string | null;
};

type EventItemEcommerceOrder = {
  index: number;
  item_id: string;
  item_name: string;
  item_category: string;
  item_category2: string;
  item_category3: string;
  item_category4?: string;
  item_category5?: string;
  item_brand: string;
  item_variant?: string;
  size_availability?: number;
  price: number;
  full_price: number;
  discount?: number;
  currency: string;
  quantity: number;
  loyalty_product?: boolean;
  affiliation?: string;
};

type EventEcommerceOrder = {
  checkout_step: number;
  value: number;
  currency: string;
  items: EventItemEcommerceOrder[];
};

type EventOrder = {
  event: string;
  ecommerce: EventEcommerceOrder;
};

export const purchasePayload = (
  command: OrderDetail,
  cartStore: CartStateType,
  collectors: [],
  referer: string
) => {
  const currency = 'EUR';

  const gtmData: EventOrder = {
    event: 'purchase',
    ecommerce: {
      checkout_step: 4,
      value: command.total.value,
      currency,
      items: []
    }
  };

  command.confirmItems?.forEach((item, index) => {
    const {
      productCategory,
      productSubCategory,
      productSubSubCategory
    } = getCategoriesName({
      universId: item.universId?.toString(),
      famillyId: item.famillyId?.toString(),
      departmentId: item.departmentId?.toString(),
      productType: item?.type
    });

    gtmData.ecommerce.items.push({
      index,
      currency,
      item_id: item.id,
      item_name: slugify(item.name),
      item_brand: slugify(item.brand || ''),
      item_category: productCategory,
      item_category2: productSubCategory,
      item_category3: productSubSubCategory,
      price: item.price,
      full_price: item.price,
      quantity: item.quantity,
      loyalty_product: false,
      affiliation: item.seller.name
    });
  });
  return gtmData;
};

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const data = purchasePayload(
    action.payload.order,
    action.payload.cart,
    action.payload.collectors,
    action.payload.referer
  );

  const orderType: 'PDV' | 'MKP' = action.payload.order.type;
  const purchaseTrackingEvent: {
    [orderType in 'PDV' | 'MKP']: { event: string; e_action: string };
  } = {
    PDV: {
      event: 'purchase',
      e_action: 'purchase'
    },
    MKP: {
      event: 'purchase partenaire',
      e_action: 'purchase mkp'
    }
  };

  return {
    ...baseData,
    e_action: purchaseTrackingEvent[orderType].e_action,
    e_category: 'ecommerce',
    type: 'purchase',
    ...data
  };
};

export default getData;
