import { CartItemType } from '@/store/cart/types';
import base from './base';
import { GlobalProductFormatter } from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  let data = {};

  const route = action.payload.route.split('/').splice(1).join('/');

  const allProductToCartPayload = (
    itemsList: Array<CartItemType>
  ): Array<any> => {
    return itemsList.map((item: CartItemType) => {
      const res: any = GlobalProductFormatter(item.product, route, item.qty);

      res.product_price = item.total.toString();
      res.product_substituable = item.acceptSubstitution ?? false;

      return res;
    });
  };

  switch (action.payload.type) {
    case 'single':
      data = {
        type: 'ecommerce',
        e_category: 'checkout',
        e_label: action.payload.sellerName,
        e_action: `supprimer panier ${
          action.payload.sellerName !== 'Intermarché'
            ? 'partenaire'
            : 'intermarché'
        }`,
        product_removefromcart: allProductToCartPayload(action.payload.products)
      };
      break;
    default:
      data = {
        type: 'ecommerce',
        event: 'remove from cart total',
        e_category: 'ecommerce',
        e_label: action.payload.isCheckout ? 'panier' : 'mini_panier',
        e_action: 'remove from cart::total',
        product_removefromcart: allProductToCartPayload(action.payload.products)
      };
      break;
  }
  return {
    ...baseData,
    ...data
  };
};

export default getData;
