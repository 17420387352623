import path from 'path';
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import getConfigValue from '@/lib/config';

const isNode = typeof window === 'undefined';

const baseConfig: AxiosRequestConfig = {
  timeout: getConfigValue('API_TIMEOUT', 3000).toNumber()
};

const axiosMiddlewares: {
  [key: string]: (instance: AxiosInstance) => void;
} = {};

const axiosMiddlewaresRequire = require.context(
  './middleware',
  true,
  /\w+\.ts$/
);
axiosMiddlewaresRequire.keys().forEach((fileName) => {
  const middleware: any = axiosMiddlewaresRequire(fileName);
  const name = path.basename(fileName, '.ts');

  axiosMiddlewares[name] = middleware.default;
});

const CreateAxios = (
  config: AxiosRequestConfig = {},
  middlewares: Array<string> = []
): AxiosInstance => {
  if (isNode) {
    /* eslint-disable no-param-reassign */
    config.headers = {
      ...config.headers,
      'User-Agent': getConfigValue('AXIOS_USER_AGENT', 'itm-front')
    };
  }

  const instance = axios.create({
    ...baseConfig,
    ...config
  });

  for (const middleware of ['logger', ...middlewares]) {
    if (axiosMiddlewares[middleware]) {
      axiosMiddlewares[middleware](instance);
    }
  }

  return instance;
};

export default CreateAxios;
