import { AnyAction } from 'redux';
import { REHYDRATE } from 'redux-persist';
import {
  LOGIN,
  LOGOUT,
  DO_LOGOUT,
  SESSION_EXPIRED,
  RESET_SESSION_EXPIRED,
  CHANGE_LAST_VISITED_PDV,
  UPDATE_INFOS
} from './authActions';

export type AuthUserStateType = {
  id: string;
  lastName: string;
  firstName: string;
  mobilePhone: string;
  lastVisitedPdv?: string;
  countVisits: number;
  email: string;
  civility: string;
};

export type AuthStateType = {
  doLogout: boolean;
  sessionExpired: boolean;
  isHydrated: boolean;
  user?: AuthUserStateType;
};

const defaultState = {
  doLogout: false,
  sessionExpired: false,
  user: undefined,
  isHydrated: false
};

const AuthReducer = (
  state: AuthStateType = defaultState,
  action: AnyAction
) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        sessionExpired: false,
        user: action.payload,
        doLogout: false
      };
    case SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true,
        user: undefined
      };
    case RESET_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: false
      };
    case CHANGE_LAST_VISITED_PDV:
      return {
        ...state,
        user: {
          ...state.user,
          lastVisitedPdv: action.payload
        }
      };
    case LOGOUT:
      return {
        ...state,
        doLogout: false,
        sessionExpired: false,
        user: undefined
      };
    case DO_LOGOUT:
      return {
        ...state,
        doLogout: true
      };
    case REHYDRATE:
      if (action.key === 'auth' && action.payload) {
        return {
          ...state,
          isHydrated: action.payload['_persist'].rehydrated
        };
      }
      return state;
    case UPDATE_INFOS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default AuthReducer;
