import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const type = action.payload.type as
    | 'cancelOrder'
    | 'changeStore'
    | 'removeStore'
    | 'subscribeNews'
    | 'unsubscribeNews'
    | 'ordersFilter'
    | 'removeShoppingList';
  const {
    orderId,
    storeId,
    newsName,
    filterType,
    productsList
  } = action.payload;

  let data = {};

  switch (type) {
    case 'cancelOrder':
      data = {
        e_action: 'annuler commande',
        e_label: orderId
      };
      break;
    case 'changeStore':
      data = {
        e_action: 'changer magasin',
        e_label: storeId
      };
      break;
    case 'removeStore':
      data = {
        e_action: 'supprimer magasin',
        e_label: storeId
      };
      break;
    case 'subscribeNews':
      data = {
        e_action: 'inscription newsletters',
        e_label: newsName
      };
      break;
    case 'unsubscribeNews':
      data = {
        e_action: 'desabonnement newsletters',
        e_label: newsName
      };
      break;
    case 'ordersFilter':
      data = {
        e_action: 'filtre commande',
        e_label: filterType
      };
      break;
    case 'removeShoppingList':
      data = {
        e_action: 'supprimer liste de course',
        e_label: productsList
      };
      break;
  }
  return {
    ...baseData,
    ...data,
    e_category: 'compte'
  };
};

export default getData;
