import React, { useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import useClickOut from '@/lib/hooks/useClickOut';
import Button from '@/components/atoms/button';
import usePageView from '@/lib/hooks/usePageView';
import usePdv from '@/lib/hooks/usePdv';

export type ModalProps = {
  className?: string;
  classNameContainer?: string;
  classNameContent?: string;
  open: boolean;
  confirmBtn?: {
    disabled?: boolean;
    color?: 'primary' | 'secondary' | 'thirdary';
    label: string;
    loading?: boolean;
    onClick: () => void;
    id?: string;
    isLink?: boolean;
  };
  cancelBtn?: {
    disabled?: boolean;
    color?: 'primary' | 'secondary' | 'thirdary';
    label: string;
    loading?: boolean;
    onClick: () => void;
    id?: string;
    isLink?: boolean;
  };
  closable?: boolean;
  scrollable?: boolean;
  onClose?: () => void;
  children: JSX.Element | Array<JSX.Element> | string | null;
  pageView?: string;
  pageViewData?: any;
};

const Modal = ({
  className,
  classNameContainer,
  classNameContent,
  open = false,
  onClose,
  confirmBtn,
  cancelBtn,
  closable = true,
  scrollable = true,
  children,
  pageView,
  pageViewData
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const [clickOutRef, clickOutHandler] = useClickOut();
  const pv = usePageView();

  clickOutHandler(() => {
    if (closable) {
      setIsOpen(false);
      if (onClose) onClose();
    }
  });

  useEffect(() => {
    if (isOpen && pageView) {
      const { ref } = usePdv();

      pv.send(pageView, {
        ...pageViewData,
        pdvRef: ref
      });
    }
  }, [pv, pageView, pageViewData, isOpen]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';
    }

    return () => {
      document.documentElement.style.overflow = 'visible';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={classnames('modal', className, {
        'modal--scrollable': scrollable
      })}
    >
      <div className="modal__overlay" />
      <section
        className={classnames('modal__container', classNameContainer)}
        ref={clickOutRef}
      >
        {closable && (
          <header>
            <span
              className="modal__close"
              onClick={() => {
                setIsOpen(false);
                if (onClose) onClose();
              }}
            >
              &#x2715;
            </span>
          </header>
        )}
        <div className={classnames('modal__content', classNameContent)}>
          {children}
        </div>
        {(confirmBtn || cancelBtn) && (
          <footer className="modal__footer">
            {cancelBtn && (
              <Button
                className="modal__btn"
                color={cancelBtn.color || 'secondary'}
                disabled={cancelBtn.disabled}
                loading={cancelBtn.loading}
                onClick={() => {
                  cancelBtn.onClick();
                }}
                id={cancelBtn.id || cancelBtn.label}
                isLink={cancelBtn.isLink}
              >
                {cancelBtn.label}
              </Button>
            )}
            {confirmBtn && (
              <Button
                className="modal__btn"
                color={confirmBtn.color}
                disabled={confirmBtn.disabled}
                loading={confirmBtn.loading}
                onClick={() => {
                  confirmBtn.onClick();
                }}
                id={confirmBtn.id || confirmBtn.label}
                isLink={confirmBtn.isLink}
              >
                {confirmBtn.label}
              </Button>
            )}
          </footer>
        )}
      </section>
    </div>
  );
};

export default Modal;
