import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCart } from '@/store/cart/actions/cart';
import { StateType as CartStateType } from '@/store/cart/types';
import { AuthStateType } from '@/store/auth/authReducer';
import { NavStateType } from '@/store/nav/navReducer';

const Cart = () => {
  const dispatch = useDispatch();
  const { isHydrated, user } = useSelector(
    ({
      cart,
      auth
    }: {
      cart: CartStateType;
      auth: AuthStateType;
      nav: NavStateType;
    }) => {
      return {
        cart,
        isHydrated: cart.isHydrated,
        user: auth.user
      };
    }
  );
  const isAuthenticated = !!user;

  useEffect(() => {
    (async () => {
      const blackList = [
        '/',
        '/connection',
        '/orders/slots',
        '/orders/delivery',
        '/orders/payment',
        '/home/orders/payment/validation',
        '/orders/confirmation'
      ];
      const synchroIsUnAvailable = blackList.includes(window.location.pathname);
      if (synchroIsUnAvailable) {
        return;
      }

      if (isHydrated) {
        try {
          if (isAuthenticated && !synchroIsUnAvailable) {
            const isCheckoutStep1 = window.location.pathname === '/orders/cart';

            await dispatch(getCart(isCheckoutStep1));
          }
        } catch (error: any) {
          // Todo: handle user error display unable to get the cart
        }
      }
    })();
  }, [user?.id, isAuthenticated, isHydrated, dispatch]);
  return <></>;
};

export default Cart;
