import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { createMiddleware } from 'redux-beacon';
import logger from '@redux-beacon/logger';
import path from 'path';
import base from './pageviews/base';

const isDev = process.env.NODE_ENV !== 'production';

declare global {
  interface Window {
    gaDebug: boolean;
  }
}

const pageviews: {
  [key: string]: (data: any) => any;
} = {};

const events: {
  [key: string]: (data: any) => any;
} = {};

const pageviewsRequire = import.meta.webpackContext('./pageviews', {
  recursive: true,
  regExp: /\w+\.ts$/
});
const eventsRequire = import.meta.webpackContext('./events', {
  recursive: true,
  regExp: /\w+\.ts$/
});

eventsRequire.keys().forEach((fileName) => {
  const normalizer: any = eventsRequire(fileName);
  const page = path.basename(fileName, '.ts');

  events[`event_${page}`] = normalizer.default;
});

pageviewsRequire.keys().forEach((fileName) => {
  const normalizer: any = pageviewsRequire(fileName);
  const page = path.basename(fileName, '.ts');

  pageviews[`pageview_${page}`] = normalizer.default;
});

const eventsMap = {
  ...pageviews,
  ...events,
  /* eslint-disable consistent-return */
  '*': (action: any, state: any) => {
    const type = action?.type;
    const pageviewMatch = type?.match(/^pageview_([a-zA-Z_]+)$/) ?? [];

    if (pageviewMatch.length > 0 && !pageviews[type]) {
      return base(action, state);
    }
  }
};

const gtm = GoogleTagManager();

export default createMiddleware(eventsMap, gtm, {
  logger: isDev ? logger : undefined
});
