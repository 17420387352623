import { useEffect, useRef, RefObject, useCallback } from 'react';

type ReturnType = [RefObject<any>, (callback: () => void) => void];

const useClickOut = (): ReturnType => {
  const ref = useRef<any>();
  const callbackRef = useRef(() => {});

  const callback = useCallback((handler: () => void) => {
    callbackRef.current = handler;
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!!ref.current && !ref.current?.contains(event.target)) {
        callbackRef.current();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return [ref, callback];
};

export default useClickOut;
