import ProductList from '@/lib/model/productList';
import base from './base';

const getData = (action: any, state: any) => {
  let baseData: any = base(action, state);

  const list = action.payload.list as ProductList;

  baseData = {
    ...baseData,
    page_type: 'boutique',
    page_name: action.payload.filters.length ? 'boutique filtre' : 'boutique',
    page_virtual_path: `/courses/boutique/${list?.shop?.name}`
      .replace(/ /g, '_')
      .toLowerCase(),
    page_boutique_name: list?.shop?.name,
    page_sort: '',
    page_filter: '',
    page_boutique_id: list?.shop?.id?.toString(),
    page_boutique_category: action.payload.category ?? 'Tous',
    page_category_id: action.payload.categoryId ?? ''
  };

  switch (action.payload.type) {
    case 'headingFilter':
      baseData = {
        ...baseData,
        page_name: 'boutique_category',
        page_virtual_path: `/courses/boutique/${list?.shop?.name}/${action.payload.category}`
          .replace(/ /g, '_')
          .toLowerCase()
      };
      break;
    // others cases related to shop
    default:
      break;
  }

  return baseData;
};

export default getData;
