import ProductModel from '@/lib/model/product';
import base, { GlobalProductFormatter } from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const { products, path } = action.payload;

  const route = path.split('/').splice(1).join('/');
  const pageType = path.split('/')[1];

  const allProductToCartPayload = (
    productsList: Array<ProductModel>
  ): Array<any> =>
    productsList.map((product: ProductModel, index: number) => {
      const item = GlobalProductFormatter(product, route, index);
      return { ...item, product_seller_name: product.bestOffer?.seller.name };
    });

  const data = allProductToCartPayload(products);

  return {
    ...baseData,
    e_action: 'product impression',
    e_category: 'ecommerce',
    event: 'product impression',
    e_label: pageType,
    product_impression: [...data]
  };
};

export default getData;
