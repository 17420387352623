import { Dayjs } from 'dayjs';
import { Price } from './price';

export type LotType = {
  type: string;
  products: Array<{
    id: string;
    quantity: number;
    brand: string;
    itemParentId: string;
    itemId: string;
    productId: string;
    description: string;
    image: string;
    price: string;
    crossPrice: string;
    priceBy: string;
    promotions: Array<PromotionType>;
  }>;
};

export type Image = {
  src: string;
  alt: string;
};

export type DynamicFieldInformation = {
  id: string;
  type: string;
  label: string;
  value: string;
};

export type ExtensiondInformation = {
  label: string;
  section: DynamicFieldInformation[];
};

export type Informations = {
  title: string;
  description?: string;
  image?: Image;
  allImages?: Image[];
  highlight?: {
    endDate: Dayjs;
  };
  originPlp?: string;
  originPdp?: string;
  packaging?: string;
  brand?: string;
  category?: string; // for fruits or vegetables
  variety?: string;
  species?: string;
  slaughterCountry?: string;
  animalCountryOrigin?: string;
  animalCountryGrowing?: string;
  fishingArea?: string;
  subFishingArea?: string;
  methodeCapture?: string;
  alcoholRate?: string;
  conservation?: string;
  ingredients?: string;
  fabricant?: string;
  emballage?: string;
  allergens?: string;
  practicalInformation?: string;
  legalInformation?: string;
  advantages?: string;
  caliber?: string;
  hasAlcohol: boolean;
  chemicalTreatment?: string;
  nutritionalValues?: NutritionalValues;
  nutritionalScores?: NutritionalScores;
  countryTransformation?: string;
  flags: Array<string>;
  pvi: number | null;
  poidsMinimum: number | null;
  extension?: {
    label: string;
    picto: string;
    sections: { [key: string]: ExtensionSection };
  };
  features: {
    label: string;
    picto: string;
    sections: { [key: string]: ExtensionSection };
  } | null;
  nutriscore: string;
  dynamicFieldInformations: ExtensiondInformation;
};

export type Offer = {
  itemParentId?: string;
  itemId: string;
  seller: Seller;
  bestOffer: boolean;
  state?: OfferState;
  delivery?: OfferDelivery;
  ecoPart?: number;
  price?: Price;
  crossedOutPrice?: Price;
  promotions?: Array<PromotionType>;
};

export type ExtensionSection = {
  id: string;
  value: string;
  label: string;
  type: string;
};

export type OfferState = 'NEW' | 'ASNEW' | 'VERYGOOD' | 'GOOD' | 'CORRECT';

export const OFFER_STATE_MAPPING: { [key: string]: OfferState } = {
  neuf: 'NEW',
  'reconditionné - comme neuf': 'ASNEW',
  'reconditionné - très bon état': 'VERYGOOD',
  'reconditionné - bon état': 'GOOD',
  'reconditionné - etat correct': 'CORRECT'
};

export type OfferDelivery = {
  shippingMode: string;
  shippingPrice: Price;
  modes: Array<OfferDeliveryMode> | [];
};

export type OfferDeliveryMode = {
  code: string;
  label: string;
};

export type Seller = {
  id: string;
  name: string;
  rating?: {
    rate: number;
    reviewsCount: number;
  };
  url?: string;
};

export type PromotionLabelType =
  | 'immediateDiscount'
  | 'deferredDiscount'
  | 'quantityDiscount'
  | 'offeredDiscount'
  | 'collectorPartner';

export type ScoreRatingType =
  | 'excellent'
  | 'good'
  | 'ok'
  | 'weak'
  | 'bad'
  | 'insignificant';

export type PromotionType = {
  endDate?: Date;
  quantity?: number;
  unit: string;
  type: PromotionLabelType | string;
  value: number | string;
  flashSale?: boolean;
  picto?: string;
  discount?: string;
};

export type NutritionalValues = {
  rate: string;
  energy: {
    kj?: string;
    kcal?: string;
  };
  fat: {
    value?: string;
    detail: {
      saturatedFattyAcid?: string;
      monounsaturatedFattyAcid?: string;
      polyunsaturatedFattyAcid?: string;
    };
  };
  carbohydrates: {
    value?: string;
    detail: {
      sugar?: string;
      polyols?: string;
      starch?: string;
    };
  };
  protein: string;
  salt: string;
  dietaryFiber: string;
};

export type SmartConso = {
  additives: [];
  default: number;
  qualifications: Array<{ key: number }>;
  avoidables: Array<{ key: number }>;
  rating: any;
  isCustom?: boolean;
};

export type NutritionalScores = {
  energy: {
    kj?: string;
    kcal?: string;
  };
  fat: {
    value?: string;
    detail: {
      saturatedFattyAcid?: string;
      monounsaturatedFattyAcid?: string;
      polyunsaturatedFattyAcid?: string;
    };
  };
  carbohydrates: {
    value?: string;
    detail: {
      sugar?: string;
      polyols?: string;
      starch?: string;
    };
  };
  protein: string;
  salt: string;
  dietaryFiber: string;
};
