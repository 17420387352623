import React, { useEffect } from 'react';
import getConfigValue from '@/lib/config';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import Date from '@/lib/utils/date';

// Set "itm_usid" cookie, if not set yet.
// usid = User Session ID

const SetCookieITMSessionId = () => {
  useEffect(() => {
    if (Cookies.get('itm_usid')) return;
    Cookies.set('itm_usid', uuidv4(), {
      secure: getConfigValue('COOKIE_SECURE', true).toBoolean(),
      path: '/',
      expires: Date().add(30, 'day').toDate()
    });
  }, []);
  return <></>;
};

export default SetCookieITMSessionId;
