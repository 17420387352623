import React from 'react';
import Auth from './auth';
import Cart from './cart';
import LastVisitedPdv from './lastVisitedPdv';
import MainCategories from './mainCategories';
import ShoppingList from './shoppingList';
import Register from './register';
import Slots from './slots';
import Sentry from './sentry';
import { NotificationContainer } from '../notification';
import NavPromotions from './navPromotions';
import SetCookieITMDeviceId from './setCookieITMDeviceId';
import SetCookieITMSessionId from './setCookieITMSessionId';
import PdvCheck from './pdvCheck';

const Init = ({ path }: { path: string }) => {
  const isPrehome = ['/', ''].includes(path);

  return (
    <>
      <SetCookieITMDeviceId />
      <SetCookieITMSessionId />
      {!isPrehome && <PdvCheck />}
      <Auth />
      <Cart />
      <MainCategories />
      <NavPromotions />
      <LastVisitedPdv />
      <ShoppingList />
      <Register />
      <Slots />
      <Sentry />
      <NotificationContainer />
    </>
  );
};

export default Init;
