import React from 'react';
import '@/assets/styles/global.scss';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { Provider } from 'react-redux';
import { store } from '@/store';
import t from '@/lib/i18n';
import Init from '@/lib/init';
import ThirdPartyApi from '@/lib/thirdPartyApi';
import { useRouter } from 'next/router';

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const path = router?.pathname?.split('?')?.[0];
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <DefaultSeo
        title={t('seo.default.title')}
        description={t('seo.default.description')}
        canonical={router.asPath}
      />
      <Provider store={store}>
        <Init path={path} />
        <ThirdPartyApi />
        <Component {...pageProps} />
      </Provider>
    </>
  );
}

export default App;
