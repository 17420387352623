/* eslint @typescript-eslint/no-var-requires: off */

import cartReducer from '@/store/cart/cartReducer';
import navReducer from '@/store/nav/navReducer';
import listReducer from '@/store/list/listReducer';
import authReducer from '@/store/auth/authReducer';
import registerReducer from '@/store/register/registerReducer';
import surveyReducer from '@/store/survey/surveyReducer';
import cardCreationReducer from '@/store/cardCreation/reducer';
import storeLocatorReducer from '@/store/storeLocator/reducer';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import CommonReducer from './common/commonReducer';

const isClient = typeof window !== 'undefined';

// eslint-disable-next-line import/no-mutable-exports
let persistedRootReducer: any;
if (isClient) {
  const localForage = require('localforage');
  const localStorage = require('redux-persist/lib/storage').default;

  const localForageStorage = localForage.createInstance({
    name: 'intermarche',
    storeName: 'redux'
  });

  const authPersistConfig = {
    key: 'auth',
    storage: localStorage
  };

  const cartPersistConfig = {
    key: 'cart',
    storage: localForageStorage,
    blacklist: ['isMiniCartOpened', 'isHydrated']
  };

  const categoriesPersistConfig = {
    key: 'nav',
    storage: localForageStorage,
    blacklist: ['isActive']
  };

  const listPersistConfig = {
    key: 'list',
    storage: localForageStorage,
    blacklist: ['list', 'selected']
  };

  const registerPersistConfig = {
    key: 'register',
    storage: localForageStorage,
    timeout: 0
  };

  const cardPersistConfig = {
    key: 'cardCreation',
    storage: localForageStorage,
    blacklist: ['status', 'lsCreation']
  };

  const storeLocatorPersistConfig = {
    key: 'storeLocator',
    storage: localForageStorage
  };

  const surveyPersistConfig = {
    key: 'survey',
    storage: localForageStorage
  };

  const CommonPersistConfig = {
    key: 'common',
    storage: localForageStorage,
    blacklist: ['recommendationsPopinDisplayed']
  };

  const rootReducer = combineReducers({
    cart: persistReducer(cartPersistConfig, cartReducer),
    list: persistReducer(listPersistConfig, listReducer),
    nav: persistReducer(categoriesPersistConfig, navReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    cardCreation: persistReducer(cardPersistConfig, cardCreationReducer),
    register: persistReducer(registerPersistConfig, registerReducer),
    survey: persistReducer(surveyPersistConfig, surveyReducer),
    storeLocator: persistReducer(
      storeLocatorPersistConfig,
      storeLocatorReducer
    ),
    common: persistReducer(CommonPersistConfig, CommonReducer)
  });

  persistedRootReducer = rootReducer;
} else {
  persistedRootReducer = combineReducers({
    cart: cartReducer,
    list: listReducer,
    nav: navReducer,
    auth: authReducer,
    cardCreation: cardCreationReducer,
    register: registerReducer,
    survey: surveyReducer,
    storeLocator: storeLocatorReducer,
    common: CommonReducer
  });
}

export default persistedRootReducer;
