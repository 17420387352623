import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import {
  SessionExpiredException,
  UnauthorizedException,
  InternalServerException,
  DisabledAccountException,
  BlockedAccountException
} from './exceptions';

type LoginResponse = {
  id: string;
};

class Security {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({
      baseURL: '/api/auth'
    });
  }

  login = async (
    username: string,
    password: string
  ): Promise<LoginResponse> => {
    let response;

    try {
      response = await this.axios.post('/login', {
        username,
        password
      });
    } catch (error: any) {
      if (error.response?.status === 401) {
        throw new UnauthorizedException();
      } else if (error.response?.status === 400) {
        throw new DisabledAccountException();
      } else if (error.response?.status === 403) {
        throw new BlockedAccountException();
      } else {
        logger.error({
          message: 'Unable to login',
          error
        });

        throw new InternalServerException();
      }
    }

    return response?.data;
  };

  logout = async (): Promise<void> => {
    try {
      await this.axios.get('/logout');
    } catch (error: any) {
      logger.error({
        message: 'Unable to logout',
        error
      });
    }
  };

  ping = async (): Promise<void> => {
    try {
      await this.axios.get('/ping');
    } catch (error: any) {
      if (error.response.status === 404) {
        throw new SessionExpiredException();
      } else {
        logger.error({
          message: 'Unable to ping session',
          error
        });

        throw error;
      }
    }
  };
}

export default new Security();
