import pino, { Logger as PinoLogger } from 'pino';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import getConfigValue from '@/lib/config';
import { baseConfig } from './base';

type Params = {
  channel?: string;
  message: string;
  request?: AxiosRequestConfig | any;
  response?: AxiosResponse;
  error?: AxiosError;
  stopwatch?: {
    startAt: number;
    endAt: number;
  };
};

export class Logger {
  pino: PinoLogger;

  constructor() {
    this.pino = pino({
      ...baseConfig
    });
  }

  info = (params: Params) => {
    const data = this._getData(params);

    if (data) {
      this.pino.info(data);
    }
  };

  warn = (params: Params) => {
    const data = this._getData(params);

    if (data) {
      this.pino.warn(data);
    }
  };

  error = (params: Params) => {
    const data = this._getData(params);

    if (data) {
      this.pino.error(data);
    }
  };

  _getData = ({
    channel = 'app',
    message,
    request,
    response,
    error,
    stopwatch
  }: Params) => {
    let data: any = {
      channel,
      message,
      req: {
        method: request?.method,
        url: request?.path ?? request?.url,
        headers: request?.headers
      }
    };

    if (response) {
      data = {
        ...data,
        res: {
          statusCode: response?.status,
          headers: response?.headers
        }
      };
    }

    if (stopwatch) {
      data = {
        ...data,
        responseTime: stopwatch.endAt - stopwatch.startAt
      };
    }

    if (error) {
      data.trace = {
        ...data.trace,
        'x-red-traceid': error.response?.headers['x-red-traceid'],
        'x-red-error': error.response?.headers['x-red-error']
      };
    } else {
      data.trace = {
        ...data.trace,
        'x-red-traceid': response?.headers['x-red-traceid']
      };
    }

    if (!getConfigValue(`LOGGER_${channel.toUpperCase()}`, false).toBoolean()) {
      return null;
    }

    return data;
  };
}

export default new Logger();
