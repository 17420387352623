import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import logger from '@/lib/logger/axios';

type Stopwatch = { startAt: number };
type Response = AxiosResponse & {
  config: AxiosRequestConfig & { stopwatch?: Stopwatch };
};

const isNode = typeof window === 'undefined';

const LoggerMiddleware = (axios: AxiosInstance) => {
  if (!isNode) return;

  axios.interceptors.request.use(
    (requestConfig) => {
      const newConfig: AxiosRequestConfig & { stopwatch: Stopwatch } = {
        ...requestConfig,
        stopwatch: {
          startAt: new Date().getTime()
        }
      };

      return newConfig;
    },
    (error) => {
      logger.error({
        channel: 'service',
        message: 'Error occured on request',
        request: error.request,
        error
      });

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    async (response: Response) => {
      logger.info({
        channel: 'service',
        message: 'request completed',
        response,
        request: response.request,
        stopwatch: response.config.stopwatch
          ? {
              startAt: response.config.stopwatch.startAt,
              endAt: new Date().getTime()
            }
          : undefined
      });

      return response;
    },
    (error) => {
      const originalRequest = error.config;

      logger.error({
        channel: 'service',
        message: 'Error occured on response',
        request: originalRequest,
        response: error.response,
        error
      });

      return Promise.reject(error);
    }
  );
};

export default LoggerMiddleware;
