export const fixDecimalCost = (value: number | null): string | null =>
  value?.toFixed(2).replace('.', ',') ?? null;

export const wordsInSentence = (sentence: string) =>
  (sentence || '')
    .split(/[\s]/g)
    .map((w) => (w || '').trim())
    .filter((w) => w);

export const getFirstWordOfSentence = (sentence: string) =>
  wordsInSentence(sentence)[0];

export const camelCaseSentence = (sentence: string) =>
  wordsInSentence(sentence)
    .filter((w) => w)
    .map((w) => `${w.charAt(0).toUpperCase()}${w.substr(1).toLowerCase()}`)
    .join('');

export const camelCaseSentenceWithSpace = (sentence: string) =>
  wordsInSentence(sentence)
    .filter((w: string) => w)
    .map(
      (w: string) => `${w.charAt(0).toUpperCase()}${w.substr(1).toLowerCase()}`
    )
    .join(' ');

export function match(string: string, matchWith: string) {
  const specialChar = /(\(|\[|\]|\?|\*|\+|\))/g;
  return matchWith
    .replace(specialChar, '')
    .split(' ')
    .filter((piece) => piece.length > 0)
    .reduce((finalString, piece) => {
      if (typeof finalString === 'string') {
        return finalString.replace(
          new RegExp(`(${piece})`, 'i'),
          '<strong>$1</strong>'
        );
      }
      return '';
    }, string);
}

export function stringToCamelCase(string: string) {
  return string
    .toLowerCase()
    .replace(/(\S)(\S+)/g, (m, g1, g2) => g1.toUpperCase() + g2);
}

export function removeAccents(str = '') {
  const accents =
    'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut =
    'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
  return str
    .split('')
    .map((letter) => {
      const idx = accents.indexOf(letter);
      if (idx !== -1) {
        return accentsOut[idx];
      }
      return letter;
    })
    .join('');
}

export function filterDigitFromString(string: string) {
  return string.replace(/(\d+)/g, '').trim();
}

export const normalize = (word: string, replaceChar = '_') => {
  const re = RegExp(`${replaceChar}+`, 'gi');

  return removeAccents(
    word
      .trim()
      .replace(/[~`!@#$%^&*(){}[\];:"'<,.>?/\\|_\-+=]/g, replaceChar)
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/(_| )+/g, replaceChar)
      .toLowerCase()
      .replace(/æ/g, 'ae')
      .replace(/œ/g, 'oe')
      .replace(re, replaceChar)
  );
};

export const slugify = (word: string) => {
  if (word === undefined) return 'product';

  let data = word;

  data = data.replace(/^\s+|\s+$/g, '');

  data = data.toLowerCase();
  data = removeAccents(data);

  data = data
    .replace(/[^a-z0-9 -œ]/g, '')
    .replace(/,/g, '')
    .replace(/\//g, '-ou-')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .replace(/%/g, '%25');

  return data;
};

export const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const emailValidationRegExp = new RegExp(
  /^([\w+-]+(?:\.[\w+-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$/i
);

export const isValidAddress = (input: string) => {
  const regex = /^[0-9A-Za-zäáàâãÄÁÀÂÃçÇëéèêËÉÈÊíìÍÌöóòôõÖÓÒÔÕüúùÜÚÙẍẌ&ºª: ,.'-]*$/i;
  if (!regex.test(input)) {
    return false;
  }

  if (input.length > 38) {
    return false;
  }

  return true;
};

export const isValidNotPTZipCode = new RegExp(/^[a-zA-Z0-9-]{1,10}$/);

export const isValidZipCode = new RegExp(/^\d{4}-\d{3}$/);

export const isFloat = (value: string) =>
  /^\d*(\.?\d{1,2}|,?\d{1,2})$/.test(value);
