import { AxiosInstance } from 'axios';

const OptionalOAuthMiddleware = (axios: AxiosInstance) => {
  /* eslint-disable no-param-reassign */
  axios.defaults.headers = {
    ...axios.defaults.headers,
    'x-optional-oauth': true
  };
};

export default OptionalOAuthMiddleware;
