import getConfigValue from '@/lib/config';

const CDN_HOST = getConfigValue('CDN_HOST').toString();
const CDN_FOR_IMAGES = getConfigValue('CDN_FOR_IMAGES', false).toBoolean();

class Category {
  static PROMO = 'promo';

  id: string;

  key: string;

  title: string;

  slug: string | null;

  picto: string | null;

  children: Array<Category> = [];

  link: string;

  level = 1;

  isPromo = false;

  hasAlcohol: boolean | null;

  constructor(
    id: string,
    title: string,
    link: string,
    slug: string | null = null,
    picto: string | null = null,
    hasAlcohol: boolean | null = null
  ) {
    this.id = id;
    this.key = id;
    this.title = title;
    this.slug = slug;
    this.link = link;
    this.picto = picto;
    this.hasAlcohol = hasAlcohol;

    this.isPromo = id === Category.PROMO;

    if (picto && CDN_HOST && CDN_FOR_IMAGES) {
      const url = new URL(picto);
      this.picto = CDN_HOST + url.pathname;
    }
  }
}

export default Category;
