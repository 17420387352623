/* eslint no-param-reassign: off */

import { CartType, SubCartType, EventCart } from '@/store/cart/types';
import ProductModel from '@/lib/model/product';
import { getPdvRef } from '@/lib/hooks/usePdv';
import cartApi from '@/lib/api/cart';
import {
  UPDATE_CART,
  DELETE_SELLER_SUB_CART,
  DELETE_ALL_CARTS
} from '@/store/cart/cartActions';
import Date from '@/lib/utils/date';

export const updateAuthorizeCart = (
  authorization: boolean,
  allowValorisation = false
) => {
  return async (dispatch: any, getState: any) => {
    const cartState = getState().cart;
    const { user } = getState().auth;

    cartState.events = [
      ...cartState.events,
      {
        acceptSubstitution: authorization,
        typeCatalog: 'PDV',
        date: Date(),
        type: 'SUBSTITUTION_PANIER'
      }
    ];

    try {
      await updateWithApi(cartState, dispatch, allowValorisation, user?.id);
    } catch (error: any) {
      throw new Error(error);
    }
  };
};

export const updateAuthorizeProduct = (
  authorization: boolean,
  product: ProductModel,
  allowValorisation = false
) => {
  return async (dispatch: any, getState: any) => {
    const cartState = getState().cart;
    const { user } = getState().auth;

    cartState.events = [
      ...cartState.events,
      {
        typeCatalog: product.type,
        acceptSubstitution: authorization,
        date: Date(),
        productId: product.id,
        productEan: product.ean,
        type: 'SUBSTITUTION_PRODUIT'
      }
    ];

    const subCarts: Array<SubCartType> = cartState.subCarts.map(
      (sub: SubCartType) => {
        if (sub.type !== 'PDV') {
          return {
            ...sub
          };
        }
        return {
          ...sub,
          items: {
            ...sub.items,
            [product.id]: {
              ...sub.items[product.id],
              acceptSubstitution: authorization
            }
          }
        };
      }
    );

    subCarts.forEach((subCart: SubCartType) => {
      if (subCart.type === 'PDV') {
        const item = Object.keys(subCart.items);
        const nbrAuthorized = item.filter(
          (i: string) => subCart.items[i].acceptSubstitution
        ).length;
        subCart.acceptSubstitution = nbrAuthorized > 0;
      }
    });
    try {
      await updateWithApi(cartState, dispatch, allowValorisation, user?.id);
    } catch (error: any) {
      throw new Error(error);
    }
  };
};

export const updateCommentProduct = (
  comment: string,
  product: ProductModel,
  allowValorisation = false
) => {
  return async (dispatch: any, getState: any) => {
    const cartState = getState().cart;
    const { user } = getState().auth;

    cartState.events = [
      ...cartState.events,
      {
        comment,
        typeCatalog: product.type,
        date: Date(),
        productId: product.id,
        productEan: product.ean,
        type: 'COMMENTAIRE'
      }
    ];

    try {
      await updateWithApi(cartState, dispatch, allowValorisation, user?.id);
    } catch (error: any) {
      throw new Error(error);
    }
  };
};

const createEventsFromItem = (
  subNewCart: SubCartType,
  key: string
): Array<EventCart> => {
  const eventAction: UpdateCartQtyElt = {
    product: subNewCart.items[key].product,
    quantity: subNewCart.items[key].qty,
    sellerId: subNewCart.seller.sellerId,
    itemParentId: subNewCart.items[key].product.itemParentId
  };
  return createEventsFromUpdateCartQtyElt(eventAction, null);
};

export const createConnectedCartToEventsBins = (newCart: CartType) => {
  const productListToRecreate: Array<EventCart> = newCart.subCarts.reduce(
    (acc: Array<EventCart>, subNewCart: SubCartType) => {
      Object.keys(subNewCart.items).forEach((key: string) => {
        acc = [...createEventsFromItem(subNewCart, key), ...acc];
      });

      return acc;
    },
    []
  );
  return productListToRecreate;
};

const updateWithApi = async (
  cart: CartType,
  dispatch: any,
  allowValorisation: boolean,
  userId: string
): Promise<CartType | null> => {
  const pdvRef = getPdvRef();

  if (!pdvRef) {
    return null;
  }

  const firstConnectedSynchro = !cart.isLastCartConnected && !!userId;

  let newCart;

  try {
    newCart = await cartApi.updateCart(
      pdvRef,
      cart,
      allowValorisation,
      userId,
      firstConnectedSynchro
    );
  } catch (error: any) {
    newCart = await cartApi.updateCart(
      pdvRef,
      cart,
      allowValorisation,
      userId,
      firstConnectedSynchro
    );
  }

  const connectedCartToEventBins = firstConnectedSynchro
    ? createConnectedCartToEventsBins(newCart)
    : [];

  await dispatch({
    type: UPDATE_CART,
    payload: {
      ...newCart,
      slots: cart.slots,
      mkpPackages: cart.mkpPackages,
      events: firstConnectedSynchro
        ? [...connectedCartToEventBins]
        : [...cart.events]
    },
    isAuthentificated: !!userId
  });

  return newCart;
};

export const getCart = (allowValorisation = false) => {
  return async (dispatch: any, getState: any) => {
    const { cart, auth } = getState();

    try {
      await updateWithApi(
        cart,
        dispatch,
        allowValorisation,
        auth.user?.id || null
      );
    } catch (error: any) {
      throw new Error(error);
    }
  };
};

export type UpdateCartQtyElt = {
  product: ProductModel;
  quantity: number;
  sellerId?: string;
  itemParentId?: string;
  currentOfferItemId?: string;
};
export const createEventsFromUpdateCartQtyElt = (
  item: UpdateCartQtyElt,
  subCart: SubCartType | null
): Array<EventCart> => {
  const productId = item.currentOfferItemId ?? item.product.id;

  if (item.product.lot && item.product.lot.type.toUpperCase() !== 'VIRTUEL') {
    return item.product.lot.products.map((pr) => {
      return {
        date: Date().format('YYYY-MM-DDTHH:mm:ssZ'),
        typeCatalog: item.product.type,
        productId: pr.itemId,
        productEan: pr.productId,
        itemParentId: item.itemParentId,
        nfLotId: pr.itemParentId,
        quantity:
          subCart && subCart.items[item.product.id]
            ? parseInt(String(pr.quantity), 10) -
              subCart.items[item.product.id].qty
            : parseInt(String(pr.quantity), 10),
        type: 'QUANTITY'
      };
    });
  }
  return [
    {
      date: Date().format('YYYY-MM-DDTHH:mm:ssZ'),
      typeCatalog: item.product.type,
      productId,
      productEan: item.product.ean,
      itemParentId: item.itemParentId ?? item.product.itemParentId,
      quantity:
        subCart && subCart.items[productId]
          ? parseInt(String(item.quantity), 10) - subCart.items[productId].qty
          : parseInt(String(item.quantity), 10),
      type: 'QUANTITY'
    }
  ];
};

export const updateCartQuantity = (
  updateArray: Array<UpdateCartQtyElt>,
  allowValorisation = false
) => {
  return async (dispatch: any, getState: any): Promise<CartType | null> => {
    const { cart, auth } = getState();
    const newCart = {
      ...cart
    };

    updateArray.forEach((item) => {
      const subCart: SubCartType | null =
        cart.subCarts.find((sub: SubCartType) => {
          return item.sellerId && item.sellerId === sub.seller.sellerId;
        }) || null;
      newCart.events = [
        ...newCart.events,
        ...createEventsFromUpdateCartQtyElt(item, subCart)
      ];
    });

    let newUpdatedCart: CartType | null;

    try {
      newUpdatedCart = await updateWithApi(
        newCart,
        dispatch,
        allowValorisation,
        auth.user?.id || null
      );
    } catch (error: any) {
      throw new Error(error);
    }

    return newUpdatedCart;
  };
};

export const deleteCart = (sellerId: string) => {
  return async (dispatch: any, getState: any) => {
    const { auth } = getState();
    if (auth.user?.id) {
      try {
        await cartApi.deleteCart(auth.user.id, sellerId);
      } catch (error: any) {
        throw new Error(error);
      }
    }
    await dispatch({
      type: DELETE_SELLER_SUB_CART,
      payload: {
        sellerId,
        isAuthentificated: !!auth.user?.id
      }
    });
    await dispatch(getCart(true));
  };
};

export const deleteAllCarts = () => {
  return async (dispatch: any, getState: any) => {
    const { auth } = getState();
    if (auth.user?.id) {
      try {
        await cartApi.deleteCart(auth.user.id);
      } catch (error: any) {
        throw new Error(error);
      }
    }
    dispatch({
      type: DELETE_ALL_CARTS,
      payload: {
        isAuthentificated: !!auth.user?.id
      }
    });
  };
};
