import React, { MouseEvent, useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import Loader from '../loader';

export type ButtonProps = {
  id?: string;
  className?: string;
  color?: 'primary' | 'secondary' | 'thirdary';
  label?: string;
  children?: JSX.Element | string;
  type?: 'button' | 'submit' | 'reset';
  isLink?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  loading?: boolean;
};

const Button = ({
  id,
  label,
  type = 'button',
  children,
  onClick,
  color = 'primary',
  className,
  disabled = false,
  isLink = false,
  loading = false
}: ButtonProps) => {
  const [isLoading, setIsLoading] = useState(loading);
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <button
      className={classnames('button', `button--${color}`, className, {
        'button--loading': isLoading,
        'button--link': isLink
      })}
      id={id}
      type={type}
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        if (!isLoading && onClick) {
          onClick(event);
        }
      }}
      disabled={disabled}
    >
      {isLoading && <Loader color="white" />}
      {!isLoading && (
        <span className="button__content">{label || children}</span>
      )}
    </button>
  );
};

export default Button;
