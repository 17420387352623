import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  return {
    ...baseData,
    page_type: 'store selector',
    page_name: 'store selector popin',
    page_virtual_path: `${window.location.pathname.toLowerCase()}/store_selector`
  };
};

export default getData;
