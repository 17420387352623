import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';

export const getHoursColor = (endDate: Dayjs | null) => {
  const endDateDiff = endDate ? endDate.diff(dayjs(), 'minute') : 0;
  return endDateDiff < 60 ? 'orange' : 'green';
};

require('dayjs/locale/pt');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
dayjs.locale('pt');

const Date = (
  date?: string | Dayjs,
  format?: string,
  strict?: boolean
): Dayjs => {
  let dayjsDate = null;

  if (date) {
    dayjsDate = dayjs(date, format, strict);
  } else {
    dayjsDate = dayjs();
  }

  return dayjsDate.tz('Europe/Paris');
};

export default Date;
