import { slugify } from '@/lib/utils/stringUtils';
import Category from './category';

class Categories {
  tree: Array<Category> = [];

  constructor(data: any, maxDepth?: number) {
    this.tree = Categories.buildTree(data['categories'] ?? [], maxDepth);

    this.tree.unshift(new Category(Category.PROMO, 'promoções', '/shop/2119'));
  }

  static buildTree(
    categoriesData: Array<any>,
    maxDepth?: number,
    level = 1,
    basePath = '/shelves'
  ): Array<Category> {
    const categories: Array<Category> = [];

    categoriesData.forEach((categoryData: any) => {
      const slug = slugify(categoryData['libelle']);
      const path = `${basePath}/${slug}`;

      let seeAll = null;

      if (level < 3) {
        let i = level;
        let thirdLevelCategories = categoryData;

        do {
          if (!thirdLevelCategories['categories']?.length) {
            break;
          }

          thirdLevelCategories = thirdLevelCategories['categories'];
          i += 1;

          if (i < 3) {
            [thirdLevelCategories] = thirdLevelCategories;
          }
        } while (i < 3);

        if (thirdLevelCategories && i === 3) {
          seeAll = thirdLevelCategories.find((elt: any) => {
            return String(elt['libelle']).toLowerCase() === 'voir tout';
          });

          if (!seeAll) {
            /* eslint-disable-next-line prefer-destructuring */
            seeAll = thirdLevelCategories[0];
          }
        }
      }

      const idCategory = seeAll
        ? seeAll['id_categorie']
        : categoryData['id_categorie'];

      const category = new Category(
        categoryData['id_categorie'].toString(),
        categoryData['libelle'],
        `${path}/${idCategory}`,
        slug,
        categoryData['image'],
        categoryData['isPresentAlcoholProduct']
      );

      category.level = level;

      if (
        categoryData['categories'] &&
        (!maxDepth || (maxDepth && level < maxDepth))
      ) {
        const childLevel = level + 1;

        category.children = Categories.buildTree(
          categoryData['categories'],
          maxDepth,
          childLevel,
          path
        );
      }

      categories.push(category);
    });

    return categories;
  }
}

export default Categories;
