import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const type = action.payload.type as
    | 'service'
    | 'geolocation'
    | 'position'
    | 'select'
    | 'search';
  const { storeType, storeInfo, key, keyword } = action.payload;
  let data = {};

  switch (type) {
    case 'service':
      data = {
        e_action: 'store selector::type de course',
        e_label: storeType
      };
      break;
    case 'select':
      data = {
        e_action: 'store selector::choix pdv',
        e_label: storeInfo
      };
      break;
    case 'position':
      data = {
        e_action: 'store selector::position pdv clique',
        e_label: key
      };
      break;
    case 'geolocation':
      data = {
        e_action: 'store selector::geolocalisation',
        e_label: keyword
      };
      break;
    case 'search':
      data = {
        e_action: 'store selector::recherche pdv',
        e_label: keyword
      };
      break;
  }

  return {
    ...baseData,
    ...data,
    e_category: 'recherche pdv'
  };
};

export default getData;
