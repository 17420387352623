const isNode = typeof window === 'undefined';

if (isNode) {
  require('dotenv-flow')
    .config({
      node_env: process.env.APP_ENV || process.env.NODE_ENV || 'development'
    });
}

class ConfigValue {
  value: string;

  constructor (value: string) {
    this.value = value;
  }

  toString() {
    return this.value
  }

  toBoolean(): boolean {
    return this.value === 'true'
  }

  toNumber(): number {
    return Number(this.value)
  }

  toArray(separator: string = ','): Array<string> {
    return this.value.split(separator);
  }
}

const getConfigValue = (key: string, defaultValue?: string | number | boolean): ConfigValue => {
  const isNode = typeof window === 'undefined';

  let value: string | undefined;

  if (isNode) {
    value = process.env[key]
  } else {
    value = ITM_CONFIG[key] ?? undefined
  }

  if (!value && !!defaultValue) {
    value = defaultValue.toString()
  }

  return new ConfigValue(value ?? "");
}

export default getConfigValue