import { SubCartType } from '@/store/cart/types';

const elt = (pdvCart: SubCartType) => {
  return Object.keys(pdvCart.items).map((id: string) => {
    const item = pdvCart.items[id];
    return {
      commentaire: item.comment,
      montant: item.total,
      accepteSubstitution: item.acceptSubstitution,
      quantity: item.qty,
      product: {
        avantages: [],
        catalog: 'PDV',
        ean: item.product.ean,
        idProduit: item.product.ean,
        privateData: item.product.privateData
      }
    };
  });
};

export const isEan = (ean: string) => /^\d{13}$/.test(ean);

export const isSkuEan = (sku: string) => /^[a-zA-Z0-9-]{36}$/.test(sku);

export default elt;
