import createAxios from '@/lib/axios';
import { AxiosInstance, AxiosResponse } from 'axios';
import logger from '@/lib/logger/base';
import Pdv from '@/lib/model/pdv';
import { StoreInfo, StoreInfoList } from './types';

type LatLng = {
  lat: number;
  lng: number;
};

type PdvStoreInfoScope = 'AUTOCOMPLETE' | 'SEO';

class PdvApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi']);
  }

  getPdvsDelivery = async (
    zipCode: string,
    city = '',
    max?: number
  ): Promise<Array<Pdv>> => {
    let response = null;

    try {
      response = await this.axios.get('/pdvs/v3/pdvs/search-by-commune', {
        params: {
          code_postal: zipCode,
          commune: city
        }
      });
    } catch (error: any) {
      logger.error({
        message: 'Unable to get delivery pdvs',
        error
      });
    }

    const data = response?.data ?? [];
    let pdvs: Array<Pdv> = [];

    data.forEach((elt: any) => {
      pdvs.push(new Pdv(elt));
    });

    if (max) {
      pdvs = pdvs.slice(0, max - 1);
    }

    return pdvs;
  };

  getPdvsByArea = async (
    { lat, lng }: LatLng,
    onlyDrive = false,
    min: number,
    max?: number,
    onlyDelivery?: boolean
  ): Promise<Array<Pdv>> => {
    let response = null;

    try {
      response = await this.axios.get('/pdvs/v4/pdvs/zone', {
        params: {
          r: 10000,
          lat,
          lon: lng,
          min
        }
      });
    } catch (error: any) {
      logger.error({
        message: 'Unable to get pdvs by area',
        error
      });
    }

    const data = response?.data.resultats ?? [];
    let pdvs: Array<Pdv> = [];

    data.forEach((elt: any) => {
      pdvs.push(new Pdv(elt));
    });

    if (onlyDelivery) {
      pdvs = pdvs.filter((pdv: Pdv) => {
        return pdv.isDelivery();
      });
    }

    if (onlyDrive) {
      pdvs = pdvs.filter((pdv: Pdv) => {
        return pdv.isDrive() || pdv.isDrive24() || pdv.isPedestrianDrive();
      });
    }

    if (max) {
      pdvs = pdvs.slice(0, max - 1);
    }

    return pdvs;
  };

  getPdvInfo = async (ref: string): Promise<Pdv | null> => {
    let response = null;

    try {
      response = await this.axios.get(`/pdvs/v3/pdvs/${ref}`);
    } catch (error: any) {
      logger.error({
        message: 'Unable to get pdv info',
        context: {
          ref
        },
        error
      });
    }

    return response ? new Pdv(response.data) : null;
  };

  checkPdvCode = async (ref: string, code: string): Promise<boolean> => {
    let response = null;

    try {
      response = await this.axios.get(
        `/pdvs/v3/pdvs/${ref}/verification-code/${code}`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to check pdv code',
        error
      });
    }

    return [200, 204].includes(response?.status ?? 500);
  };

  getStoresInfo = async (
    scope: PdvStoreInfoScope,
    keyword?: string
  ): Promise<StoreInfoList | null> => {
    try {
      const response: AxiosResponse<StoreInfoList> = await this.axios.get(
        '/pdvs/v1/stores',
        {
          params: {
            scope,
            postalCodeOrCity: keyword
          }
        }
      );
      if (scope === 'AUTOCOMPLETE') {
        return {
          storeInfoList: response.data.storeInfoList.map((storeInfo: any) => {
            return {
              storeId: storeInfo.storeId,
              name: storeInfo.modelLabel,
              address: storeInfo.address.address,
              zipCode: storeInfo.address.postCode,
              townLabel: storeInfo.address.townLabel
            } as StoreInfo;
          })
        };
      }
      const finalData = response.data ?? null;
      return finalData;
    } catch (error) {
      logger.error({
        message: 'Unable to get pdv info',
        error
      });
      return null;
    }
  };
}

export default new PdvApi();
