import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const type = action.payload.type as
    | 'mySticker'
    | 'modality'
    | 'details'
    | 'shop'
    | 'shareMyStickers'
    | 'share';
  const { partner } = action.payload;
  let data = {};

  switch (type) {
    case 'mySticker':
      data = {
        e_action: 'full investi',
        e_label: 'acceder'
      };
      break;
    case 'modality':
      data = {
        e_action: 'vignettes modalite',
        e_label: partner
      };
      break;
    case 'details':
      data = {
        e_action: 'vignettes detail',
        e_label: partner
      };
      break;
    case 'shop':
      data = {
        e_action: 'vignettes boutique partenaire',
        e_label: partner
      };
      break;
    case 'share':
      data = {
        e_action: 'partager vignettes',
        e_label: partner
      };
      break;
    case 'shareMyStickers':
      data = {
        e_action: 'validation partage vignettes',
        e_label: partner
      };
      break;
  }
  return {
    ...baseData,
    ...data,
    e_category: 'fid'
  };
};

export default getData;
