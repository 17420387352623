import getConfigValue from '@/lib/config';
import { Request } from 'express';
import Cookie from 'js-cookie';
import { Redirect } from 'next';

export type ReturnType = {
  ref: string;
  isEcommerce: boolean;
  isVirtual: boolean;
  redirect: Redirect;
};

const isNode = typeof window === 'undefined';

const usePdv = (req?: Request): ReturnType => {
  const pdv = getPdvCookie(req);

  return {
    ref: pdv?.ref ?? getConfigValue('VIRTUAL_PDV_REF', '00000'),
    isEcommerce: pdv?.isEcommerce ?? false,
    isVirtual: !pdv?.ref,
    redirect: {
      statusCode: 303,
      destination: '/'
    }
  };
};

export const getPdvRef = (req?: Request) => {
  const pdv = getPdvCookie(req);

  return pdv?.ref ?? undefined;
};

export const getPdvCookie = (req?: Request) => {
  let pdvCookie = null;

  if (isNode) {
    if (!req) {
      throw Error('Request parameter required.');
    } else {
      pdvCookie = req.cookies.itm_pdv;
    }
  } else {
    pdvCookie = Cookie.get('itm_pdv');
  }

  let data;

  try {
    data = pdvCookie ? JSON.parse(pdvCookie) : undefined;
  } catch (error: any) {
    data = undefined;
  }

  return data;
};

export default usePdv;
