import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import { getMainCategories } from '@/store/nav/actions/categories';
import { NavStateType } from '@/store/nav/navReducer';
import dayjs from 'dayjs';
import usePdv from '@/lib/hooks/usePdv';

const MainCategories = () => {
  const dispatch = useDispatch();
  const { user, mainCategories, navIsHydrated } = useSelector(
    ({ auth, nav }: { auth: AuthStateType; nav: NavStateType }) => {
      return {
        navIsHydrated: nav.isHydrated,
        user: auth.user,
        mainCategories: nav.mainCategories
      };
    }
  );
  const isAuthenticated = !!user;

  useEffect(() => {
    (async () => {
      if (navIsHydrated) {
        const { ref, isVirtual } = usePdv();

        if (!ref || isVirtual) return;

        const storeMainCategories = mainCategories[ref] ?? null;
        const categoriesUpdatedDate = storeMainCategories?.updatedAt;

        if (
          !storeMainCategories ||
          !categoriesUpdatedDate ||
          dayjs().diff(categoriesUpdatedDate, 'hour') > 6
        ) {
          await dispatch(getMainCategories(ref));
        }
      }
    })();
  }, [user?.id, isAuthenticated, dispatch, navIsHydrated, mainCategories]);
  return <></>;
};

export default MainCategories;
