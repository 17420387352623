import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import ProductList from '@/lib/model/productList';
import Product from '@/lib/model/product';
import getConfigValue from '@/lib/config';
import { isSkuEan } from '@/lib/utils/products';
import axiosRetry from 'axios-retry';
import queryString from 'query-string';

export type Filter = {
  type: string;
  id: string;
};

export type FamilyType = {
  id: number;
  label: string;
};

export type Filters = Array<Filter>;

export type EmerchRequest = {
  productId: string;
  scope: 'PRODUCT' | 'CART';
  limit?: number;
  excludeFromResponse?: Array<string>;
  userConsent?: boolean;
};
class ProductApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios(
      {
        timeout: 10000
      },
      ['redApi', 'optionalOAuth']
    );

    // https://github.com/vercel/fetch/tree/main/packages/fetch-retry#rationale
    axiosRetry(this.axios, {
      retries: 2,
      retryDelay: (retryCount) => {
        const delay = Math.pow(5, retryCount - 1) * 200;
        const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay

        return delay + randomSum;
      },
      shouldResetTimeout: true, // https://stackoverflow.com/a/60142639
      // https://github.com/softonic/axios-retry/issues/116#issuecomment-639318857
      retryCondition: (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) ||
          error.response?.status === 504
        );
      }
    });
  }

  findByCategory = async (
    codePdv: string,
    category: string,
    page = 1,
    perPage = 40,
    filtres: Filters = [],
    sort = 'pertinence',
    sortDirection?: string | null
  ): Promise<ProductList> => {
    let response = null;
    const isMkpEnabled = getConfigValue('IS_MKP_ENABLED', false).toBoolean();

    const body = {
      category,
      page,
      size: perPage,
      filtres,
      tri: sort,
      ordreTri: sortDirection,
      catalog: isMkpEnabled ? ['PDV', 'MKP'] : ['PDV']
    };

    try {
      response = await this.axios.post(
        `/produits/v2/pdvs/${codePdv}/products/byKeywordAndCategory`,
        body
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch products for category',
        error,
        context: {
          pdv: codePdv,
          type: 'findByCategory',
          body
        }
      });
    }

    return new ProductList(response?.data ?? []);
  };

  findByKeyword = async (
    codePdv: string,
    keyword: string,
    page = 1,
    perPage = 40,
    filtres: Filters = [],
    sort = 'pertinence',
    sortDirection?: string | null
  ): Promise<ProductList> => {
    let response = null;

    const isMkpEnabled = getConfigValue('IS_MKP_ENABLED', false).toBoolean();
    const body = {
      keyword,
      page,
      size: perPage,
      filtres,
      tri: sort,
      ordreTri: sortDirection,
      catalog: isMkpEnabled ? ['PDV', 'MKP'] : ['PDV']
    };

    try {
      response = await this.axios.post(
        `/produits/v2/pdvs/${codePdv}/products/byKeywordAndCategory`,
        body
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch products for keyword',
        error,
        context: {
          pdv: codePdv,
          type: 'findByKeyword',
          body
        }
      });
    }

    return new ProductList(response?.data ?? []);
  };

  findByShop = async (
    codePdv: string,
    shopId: string,
    page = 1,
    perPage = 40,
    filtres: Filters = [],
    sort = 'pertinence',
    sortDirection?: string | null
  ): Promise<ProductList> => {
    let response = null;
    const isMkpEnabled = getConfigValue('IS_MKP_ENABLED', false).toBoolean();
    const body = {
      page,
      size: perPage,
      filtres,
      tri: sort,
      ordreTri: sortDirection,
      catalog: isMkpEnabled ? ['PDV', 'MKP'] : ['PDV']
    };

    try {
      response = await this.axios.post(
        `/produits/v2/pdvs/${codePdv}/boutiques/${shopId}`,
        body
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch products for shop',
        error,
        context: {
          pdv: codePdv,
          shopId,
          body
        }
      });
    }

    return new ProductList(response?.data ?? []);
  };

  findByHeading = async (
    codePdv: string,
    shopId: string,
    headingId?: number,
    page = 1,
    perPage = 40,
    filtres: Filters = [],
    sort = 'pertinence',
    sortDirection?: string | null
  ): Promise<ProductList> => {
    let response = null;
    const isMkpEnabled = getConfigValue('IS_MKP_ENABLED', false).toBoolean();
    const body = {
      page,
      size: perPage,
      filtres,
      tri: sort,
      ordreTri: sortDirection,
      catalog: isMkpEnabled ? ['PDV', 'MKP'] : ['PDV']
    };

    try {
      response = await this.axios.post(
        headingId && headingId !== -1
          ? `/produits/v2/pdvs/${codePdv}/boutiques/${shopId}/rubriques/${headingId}`
          : `/produits/v2/pdvs/${codePdv}/boutiques/${shopId}`,
        body
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch products for heading',
        error,
        context: {
          pdv: codePdv,
          shopId,
          body
        }
      });
    }

    return new ProductList(response?.data ?? []);
  };

  findByEan = async (
    codePdv: string,
    ean: string,
    proxy = true,
    tracking?: { code: string; type: string }
  ) => {
    let response = null;

    const isMkpEnabled = getConfigValue('IS_MKP_ENABLED', false).toBoolean();
    const body: any = {
      params:
        isMkpEnabled && isSkuEan(ean)
          ? {
              catalog: 'MKP',
              extensions: 'INFORMATIONS'
            }
          : null
    };

    if (tracking) {
      body.headers = {
        'x-itm-tracking-code': tracking.code,
        'x-itm-tracking-type': tracking.type
      };
    }

    try {
      const targetProtocol = getConfigValue(
        'API_HOST_PROTOCOL',
        'http'
      ).toString();
      const targetDomain = getConfigValue(
        'API_HOST_DOMAIN',
        'localhost'
      ).toString();
      const endUrl = `/produits/v2/pdvs/${codePdv}/produits/${ean}?extensions=CATEGORISATION&extensions=INFORMATIONS&extensions=NUTRISCORE&extensions=NUTRITION&extensions=PRIX&extensions=ORIGINE&extensions=FEATURES`;
      const url = proxy
        ? endUrl
        : `${targetProtocol}://${targetDomain}${endUrl}`;
      response = await this.axios.get(url, body);
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch product by ean or sku',
        error,
        context: {
          pdv: codePdv,
          body
        }
      });
      throw new Error(error);
    }
    return new Product(response?.data ?? []);
  };

  getAdditivesInfos = async (additives: string[]) => {
    let response = null;
    const qs = queryString.stringify({ keys: additives });

    try {
      response = await this.axios.get(`/produits/v1/products/additives?${qs}`);
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch product by ean or sku',
        error
      });
      throw new Error(error);
    }

    return response?.data;
  };

  suggestionForShop = async (
    pdvRef: string,
    shopId: string,
    limit = 30
  ): Promise<Array<Product>> => {
    let response = null;

    const body = {
      productNumber: limit,
      criterias: [{ type: 'boutique', value: shopId }]
    };

    try {
      response = await this.axios.post(
        `/produits/v1/pdvs/${pdvRef}/products/suggestion`,
        body
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable te get shop suggestion',
        error,
        context: {
          pdvRef,
          shopId,
          body
        }
      });
    }

    const products = response?.data
      .shift()
      .products.reduce((reducedProducts: Array<Product>, product: any) => {
        reducedProducts.push(new Product(product));

        return reducedProducts;
      }, []);

    return products;
  };

  productSuggestions = async (codePdv: string, keyword: string) => {
    let productSuggestions = {
      families: [] as Array<FamilyType>,
      products: [] as Array<Product>,
      suggestions: []
    };

    const body = {
      params: {
        keyword
      }
    };
    try {
      const response = await this.axios.get(
        `/produits/v3/pdvs/${codePdv}/products/suggestions`,
        body
      );

      const families: Array<FamilyType> = [];
      response.data.familles.forEach((family: any) => {
        families.push({ id: family.idFamille, label: family.libelle });
      });

      const products: Array<Product> = [];
      response.data.produits.forEach((product: any) => {
        products.push(new Product(product));
      });

      productSuggestions = {
        families,
        products,
        suggestions: response.data.propositions
      };
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch products suggestions',
        error,
        context: {
          pdv: codePdv,
          body
        }
      });
    }

    return productSuggestions ?? {};
  };

  findByIds = async (
    codePdv: string,
    nfProductIds: Array<number>
  ): Promise<ProductList> => {
    let response = null;

    const body = {
      nfProductIds
    };

    try {
      response = await this.axios.post(
        `/produits/v3/pdvs/${codePdv}/products/byProperty`,
        {
          ...body
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch products findByIds',
        error,
        context: {
          pdv: codePdv,
          body
        }
      });
    }
    const productObj = {
      produits: response?.data?.results ?? []
    };
    return new ProductList(productObj);
  };

  findByIdsMkp = async (
    codePdv: string,
    productIds: Array<{ id: string; parentId: string }>
  ): Promise<ProductList> => {
    let response = null;

    const items = productIds.map((productId) => ({
      ...productId,
      catalog: 'MKP'
    }));
    const body = {
      items
    };

    try {
      response = await this.axios.post(
        `/produits/v3/pdvs/${codePdv}/products/retrieve`,
        {
          ...body
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch products findByIds',
        error,
        context: {
          pdv: codePdv,
          body
        }
      });
    }
    const productObj = {
      produits: response?.data?.products ?? []
    };
    return new ProductList(productObj);
  };

  getRecommendations = async (
    req: EmerchRequest,
    codePdv: string
  ): Promise<ProductList> => {
    // if no req.productId then we retrieve recommendations otherwise similar products
    let response = null;
    const body = {
      productIds: [req.productId],
      scope: req.scope || 'PRODUCT',
      limit: req.limit || 10,
      excludeFromResponse: req.excludeFromResponse,
      userConsent: req.userConsent || false
    };

    try {
      response = await this.axios.post(
        `/produits/v3/stores/${codePdv}/products/recommendations`,
        body
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch products Recommendations',
        error,
        context: {
          pdv: codePdv,
          body
        }
      });
    }
    return new ProductList({ produits: response?.data || [] });
  };

  getGaleries = async (codePdv: string, scope: string) => {
    const params = scope ? { scope } : {};
    try {
      const response = await this.axios.get(
        `/produits/v2/pdvs/${codePdv}/galeries`,
        { params }
      );
      return response.data?.galeries;
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch galeries',
        error,
        context: {
          pdv: codePdv,
          type: 'getGaleries'
        }
      });
      return error;
    }
  };
}

export default new ProductApi();
