import ProductApi from '@/lib/api/product';
import ProductModel from '@/lib/model/product';
import { Offer } from '@/lib/model/productTypes';
import getConfigValue from '@/lib/config';
import logger from '@/lib/logger/base';
import {
  UpdateCartQtyElt,
  updateCartQuantity
} from '@/store/cart/actions/cart';
import { Dispatch } from 'react';

export const addToCartApi = async (
  dispatch: Dispatch<any>,
  ean: string,
  quantity = 1
) => {
  try {
    const product: ProductModel = await ProductApi.findByEan(
      getConfigValue('VIRTUAL_PDV_REF', '00000').toString(),
      ean
    );
    const offer: Offer | undefined = product.getBestOffer();
    await addToCartBasic(
      dispatch,
      [
        {
          product,
          quantity,
          sellerId: offer?.seller?.id,
          itemParentId: product.itemParentId
        }
      ],
      false
    );
  } catch (error: any) {
    logger.error({
      message: 'Unable to add to cart',
      error,
      context: {
        type: 'addToCartApi',
        quantity,
        body: ean
      }
    });
  }
};

// common addToCart function (for exposed apis or not)
// update here if changes need to be shared

export const addToCartBasic = async (
  dispatch: Dispatch<any>,
  updateArray: UpdateCartQtyElt[],
  fullSync = false
) => {
  await dispatch(updateCartQuantity(updateArray, fullSync));
};
