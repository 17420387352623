import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import getConfigValue from '@/lib/config';
import Categories from '@/lib/model/categories';
import Category from '@/lib/model/category';
import { getCategoryById, getParentCategory } from '@/lib/utils/category';
import { VIRTUAL_PDV_REF } from '@/lib/utils/pdv';
import { CategoryType } from './types';

export type SimpleFullMainNav = {
  title: string;
  picto: string | null;
  key: string;
  hasAlcohol?: boolean | null;
};
class CategoriesApi {
  axios: AxiosInstance;

  isMkpEnabled: boolean;

  constructor() {
    this.axios = createAxios({}, ['redApi']);
    this.isMkpEnabled = getConfigValue('IS_MKP_ENABLED', false).toBoolean();
  }

  getNavByPdv = async (
    pdvRef: string,
    maxDepth?: number
  ): Promise<Categories | null> => {
    let response = null;
    const url = `/arborescence-produit/v1/pdvs/${pdvRef}/categories`;

    try {
      response = await this.axios.get(
        this.isMkpEnabled ? `${url}?catalog=PDV&catalog=MKP` : url
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch nav by pdv ref',
        context: {
          pdvRef,
          maxDepth
        },
        error
      });
    }

    return response ? new Categories(response.data, maxDepth) : null;
  };

  findUniversById = async (
    id: string,
    pdvRef: string = getConfigValue('VIRTUAL_PDV_REF', '00000').toString()
  ): Promise<CategoryType | null> => {
    let response = null;
    let categories;
    let category;

    try {
      response = await this.axios.get(
        `/arborescence-produit/v1/pdvs/${pdvRef}/categories`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch category by id',
        context: {
          pdvRef,
          id
        },
        error
      });
    }

    if (response && response.data.categories.length > 0 && id) {
      categories = new Categories(response.data);
      category = categories.tree.find((cat: Category) => cat.id === `${id}`);

      if (category) {
        return {
          id: category.id,
          title: category.title,
          data: category
        } as CategoryType;
      }
    }
    return null;
  };

  getCategoryById = async (
    id: string,
    pdvRef: string = getConfigValue('VIRTUAL_PDV_REF', '00000').toString()
  ): Promise<Category | null> => {
    let response = null;
    const url = `/arborescence-produit/v1/pdvs/${pdvRef}/categories`;

    try {
      response = await this.axios.get(
        this.isMkpEnabled ? `${url}?catalog=PDV&catalog=MKP` : url
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch category by id',
        context: {
          pdvRef,
          id
        },
        error
      });
    }

    return response
      ? getCategoryById(id, response.data?.categories || [])
      : null;
  };

  getParentCategory = async (
    id: string,
    pdvRef: string = getConfigValue('VIRTUAL_PDV_REF', '00000').toString()
  ): Promise<Category | null> => {
    let response = null;
    const url = `/arborescence-produit/v1/pdvs/${pdvRef}/categories`;

    try {
      response = await this.axios.get(
        this.isMkpEnabled ? `${url}?catalog=PDV&catalog=MKP` : url
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch category by id',
        context: {
          pdvRef,
          id
        },
        error
      });
    }

    return response
      ? getParentCategory(id, response.data?.categories || [])
      : null;
  };

  getMainNavByPdv = async (
    pdvRef: string
  ): Promise<Array<SimpleFullMainNav>> => {
    const mainNav = await this.getNavByPdv(pdvRef, 1);
    const categories: Array<SimpleFullMainNav> =
      mainNav?.tree.map((item) => ({
        title: item.title,
        picto: item.picto || null,
        key: item.id
      })) ?? [];
    return categories;
  };

  getFullNav = async (maxDepth?: number): Promise<Categories | null> => {
    const virtualPdv = VIRTUAL_PDV_REF;

    if (!virtualPdv) {
      return null;
    }

    return this.getNavByPdv(virtualPdv, maxDepth);
  };

  getFullMainNav = async (): Promise<Array<SimpleFullMainNav>> => {
    const fullNav = await this.getFullNav(1);
    const categories: Array<SimpleFullMainNav> =
      fullNav?.tree.map((item) => ({
        title: item.title,
        picto: item.picto || null,
        key: item.id
      })) ?? [];
    return categories;
  };
}

export default new CategoriesApi();
