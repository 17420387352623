import base, { GlobalProductFormatter } from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const {
    product,
    path,
    index,
    qty,
    typeAdd,
    keyword,
    position
  } = action.payload;

  let route = path.split('/').splice(1).join('/');
  let pageType = path.split('/')[1];

  let type = typeAdd ?? 'classic';
  const autocomplete = typeAdd !== 'classic' ? ' autocomplétion' : '';
  if (path.match(/\/my-usual-products\.*/)?.length) {
    type = 'regular product';
    route = route.split('/').splice(1, 3).join('/');
    pageType = 'my-usual-products';
  }
  if (path.match(/\/my-shopping-lists\.*/)?.length) {
    type = 'shopping list';
    route = route.split('/').splice(1, 3).join('/');
    pageType = 'my-shopping-lists';
  }

  const productData = GlobalProductFormatter(product, route, qty);

  const data = {
    ...productData,
    product_list_position: index?.toString(),
    product_search_keywords: keyword,
    product_search_position: position
  };

  return {
    ...baseData,
    event: `add to cart${autocomplete}`,
    e_category: 'ecommerce',
    e_action: `add to cart::${type}`,
    e_label: pageType,
    product_addtocart: [data]
  };
};

export default getData;
