import Date from '@/lib/utils/date';
import { Dayjs } from 'dayjs';

export type Time = {
  hour: number;
  minute: number;
};

export type OpeningHour = {
  begin: Time;
  end: Time;
  endMorning: Time;
  beginAfternoon: Time;
};

export type CalendarOpeningHour = {
  days: string;
  is24?: boolean;
  startHours: string;
  endHours: string;
};

export type ExceptionDate = {
  type?: string;
  startDate?: Dayjs;
  endDate?: Dayjs;
  begin?: Time;
  end?: Time;
  isRecurrent?: boolean;
};

export type ExceptionDates = {
  opening?: Array<ExceptionDate>;
  closure?: Array<ExceptionDate>;
};

export const EXCEPTION_MAPPING: { [key: string]: string } = {
  OUVERTURE_EXCEPTIONNELLE: 'opening',
  FERMETURE_EXCEPTIONNELLE: 'closure'
};

export type Address = {
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  longitude: number;
  latitude: number;
  region: string;
};

export type Service = { [key: string]: string };

export type PdvCookie = {
  ref: string;
  isEcommerce: boolean;
};

export const TYPE_MAPPING: { [key: string]: string } = {
  DRIVE: 'drive',
  DRIVE24: 'drive24',
  CONSIGNE: 'drive24',
  DRIVE_PIETON: 'drivepieton',
  A_DOMICILE: 'delivery',
  POINT_DE_RETRAIT: 'drivepieton',
  DRIVE_LIGHT: 'driveLight',
  DRIVE_SOLO: 'driveSolo'
};

export const TYPE_MATCHING: { [key: string]: string } = {
  drive: 'drive',
  driveSolo: 'drive',
  driveLight: 'drive',
  drive24: 'drive24',
  delivery: 'delivery',
  drivepieton: 'drivepieton'
};

export const getDeliveryType = (deliveryType: string) => {
  switch (deliveryType) {
    case 'drive':
    case 'driveSolo':
    case 'driveLight':
      return 'drive';
    default:
      return deliveryType;
  }
};

export type PdvProps = {
  ref: string;
  format: string;
  address: Address;
  phone: string;
  isClosed: boolean;
  isOpen: boolean;
  nextOpeningDate?: string;
  nextClosingDate?: string;
  exceptionDate?: ExceptionDate;
};

const frenchDays = [
  'lundi',
  'mardi',
  'mercredi',
  'jeudi',
  'vendredi',
  'samedi',
  'dimanche'
];

type ConvertedCalendarItem = {
  debutMatin: string;
  finMatin: string;
  debutApresMidi: string;
  finApresMidi: string;
};

type ConvertedCalendar = Record<
  typeof frenchDays[number],
  ConvertedCalendarItem
>;

function convertCalendar(calendar: CalendarOpeningHour[]) {
  const conv: ConvertedCalendar = {};

  frenchDays.forEach((day) => {
    conv[day] = {
      debutMatin: '',
      finMatin: '',
      debutApresMidi: '',
      finApresMidi: ''
    };
  });

  const getConvertedHours = (cal: CalendarOpeningHour) => ({
    debutMatin: (cal?.startHours ?? '00:00').replace(':', 'h'),
    finMatin: '',
    debutApresMidi: '',
    finApresMidi: (cal?.endHours ?? '00:00').replace(':', 'h')
  });

  frenchDays.forEach((day, index) => {
    const cal = calendar.find((c) => c?.days?.[index] === '1');
    if (cal) {
      conv[day] = getConvertedHours(cal);
    }
  });
  return conv;
}

class Pdv {
  ref = '';

  idNetfective?: string;

  format: string;

  name: string;

  types: Array<string> = [];

  rawTypes: Array<string> = [];

  isPrivate = false;

  services: Service = {};

  allServicesCodes: Array<string> = [];

  isClosed: boolean;

  // In meter. Used when make a search.
  distance?: number;

  // Will be used to compute opening hours only if required
  originalOpeningHours?: any;

  openingHours: { [dayOfWeek: number]: OpeningHour } = {};

  calendar: { openingHours: CalendarOpeningHour[] };

  exceptionDates?: ExceptionDates = {};

  nextExceptionDate?: ExceptionDate = {};

  address?: Address;

  phone?: string;

  fax?: string;

  email?: string;

  message?: string;

  driveType?: string;

  area?: number;

  constructor(data: any) {
    this.computeAddress(data);
    this.computeContacts(data);
    this.computeMessage(data);
    this.computeExcepetionDate(data);

    const mode: string = data.modelLabel?.split(' ').shift() ?? '';

    this.format = mode.charAt(0).toUpperCase() + mode.slice(1).toLowerCase();
    this.name = [this.format, this.address?.city].join(' ');
    this.calendar = data.calendar;

    this.ref = data.entityCode;
    this.distance = data.distance;
    this.originalOpeningHours = convertCalendar(data.calendar?.openingHours);
    this.isPrivate = data.ecommerce.estPrive;
    this.driveType = TYPE_MAPPING[data.ecommerce.typeLivraisonDrive];
    this.area = data.structure?.surfaceTot;

    if (data.privateData) {
      const privateData = JSON.parse(data.privateData);
      this.idNetfective = privateData.idPdvNetfective;
    }

    this.rawTypes = data.ecommerce.typeLivraisonOuvert;

    data.ecommerce.typeLivraisonOuvert.forEach((t: string) => {
      const type = TYPE_MAPPING[t];

      if (type) {
        this.driveType && type === 'drive'
          ? this.types.push(this.driveType)
          : this.types.push(type);
      }
    });

    this.allServicesCodes = data.services?.map((s: any) => s.codeService);

    data.ecommerce.services.forEach((s: any) => {
      this.services[s.code] = s.libelle;
    });

    this.isClosed = !(data.carrelage || data.ecommerce.drive);
  }

  isEcommerce(): boolean {
    return !!this.types.length;
  }

  hasService(code: string): boolean {
    return this.services[code] !== undefined;
  }

  isDrive(): boolean {
    return this.types.includes('drive');
  }

  isDrive24(): boolean {
    return this.types.includes('drive24');
  }

  isDelivery(): boolean {
    return this.types.includes('delivery');
  }

  isPedestrianDrive(): boolean {
    return this.types.includes('drivepieton');
  }

  static getDayOfWeek(date: Dayjs) {
    let dayOfWeek = date.day() - 1;

    if (dayOfWeek === -1) {
      dayOfWeek = 6;
    }

    return dayOfWeek;
  }

  getNextOpeningDate(): Dayjs | null {
    if (this.originalOpeningHours) {
      this.computeOpeningHours();
    }

    if (!Object.keys(this.openingHours).length) return null;

    const today = Date();
    const dayOfWeek = Pdv.getDayOfWeek(today);

    let nextOpeningDate = null;
    let completeWeekLoop = false;

    let currentDayOfWeek = dayOfWeek;
    let daysToAdd = 0;

    do {
      const openingHour = this.openingHours[currentDayOfWeek];
      let openingDate = null;

      if (openingHour) {
        openingDate = Date()
          .add(daysToAdd, 'day')
          .hour(openingHour.begin.hour)
          .minute(openingHour.begin.minute);
      }

      if (openingDate && today < openingDate) {
        nextOpeningDate = openingDate;
      } else {
        if (dayOfWeek + daysToAdd === 6) {
          currentDayOfWeek = 0;
        } else {
          currentDayOfWeek += 1;
        }

        daysToAdd += 1;

        if (currentDayOfWeek === dayOfWeek) {
          completeWeekLoop = true;
        }
      }
    } while (!nextOpeningDate && !completeWeekLoop);

    return nextOpeningDate;
  }

  getNextClosingDate(): Dayjs | null {
    if (this.originalOpeningHours) {
      this.computeOpeningHours();
    }

    if (!Object.keys(this.openingHours).length) return null;

    const today = Date();
    const dayOfWeek = Pdv.getDayOfWeek(today);

    let nextClosingDate = null;
    let completeWeekLoop = false;

    let currentDayOfWeek = dayOfWeek;
    let daysToAdd = 0;

    do {
      const OpeningHour = this.openingHours[currentDayOfWeek];
      let closingDate = null;

      if (OpeningHour) {
        closingDate = Date()
          .add(daysToAdd, 'day')
          .hour(OpeningHour.end.hour)
          .minute(OpeningHour.end.minute);
      }

      if (closingDate && today < closingDate) {
        nextClosingDate = closingDate;
      } else {
        if (dayOfWeek + daysToAdd === 6) {
          currentDayOfWeek = 0;
        } else {
          currentDayOfWeek += 1;
        }

        daysToAdd += 1;

        if (currentDayOfWeek === dayOfWeek) {
          completeWeekLoop = true;
        }
      }
    } while (!nextClosingDate && !completeWeekLoop);

    return nextClosingDate;
  }

  isOpen() {
    if (this.originalOpeningHours) {
      this.computeOpeningHours();
    }

    if (!Object.keys(this.openingHours).length) return false;

    const today = Date();
    const dayOfWeek = Pdv.getDayOfWeek(today);

    const openingHour = this.openingHours[dayOfWeek];

    if (!openingHour) {
      return false;
    }

    const begin = Date()
      .hour(openingHour.begin.hour)
      .minute(openingHour.begin.minute);

    const endMorning =
      openingHour.endMorning.hour &&
      Date()
        .hour(openingHour.endMorning.hour)
        .minute(openingHour.endMorning.minute);

    const beginAfternoon =
      openingHour.beginAfternoon.hour &&
      Date()
        .hour(openingHour.beginAfternoon.hour)
        .minute(openingHour.beginAfternoon.minute);

    const end = Date()
      .hour(openingHour.end.hour)
      .minute(openingHour.end.minute);

    if (endMorning && beginAfternoon) {
      return (
        (today >= begin && today <= endMorning) ||
        (today >= beginAfternoon && today <= end)
      );
    }
    return today >= begin && today <= end;
  }

  getNextOpeningHours() {
    if (this.originalOpeningHours) {
      this.computeOpeningHours();
    }

    return this.openingHours;
  }

  computeOpeningHours() {
    const frenchTimeRegex = /([0-9]{1,2})h([0-9]{1,2})/i;

    frenchDays.forEach((frenchDay: string, index: number) => {
      const frenchDayData = this.originalOpeningHours[frenchDay];

      if (!frenchDayData?.debutMatin || !frenchDayData?.finApresMidi) {
        return;
      }
      const beginTimeMorningMatch = frenchDayData.debutMatin.match(
        frenchTimeRegex
      );
      const beginTimeAfterNoonMatch = frenchDayData.debutApresMidi.match(
        frenchTimeRegex
      );

      const endMorningTimeMatch = frenchDayData.finMatin.match(frenchTimeRegex);
      const endAfterNoonTimeMatch = frenchDayData.finApresMidi.match(
        frenchTimeRegex
      );

      const [beginMinute, beginHour] = beginTimeMorningMatch.reverse();
      const [endMinute, endHour] = endAfterNoonTimeMatch.reverse();
      const [beginAfterNoonMinute, beginAfterNoonHour] =
        beginTimeAfterNoonMatch?.reverse() || [];
      const [endMorningMinute, endMorningHour] =
        endMorningTimeMatch?.reverse() || [];

      this.openingHours[index] = {
        begin: {
          hour: parseInt(beginHour, 10),
          minute: parseInt(beginMinute, 10)
        },
        end: {
          hour: parseInt(endHour, 10),
          minute: parseInt(endMinute, 10)
        },
        endMorning: {
          hour: parseInt(endMorningHour, 10),
          minute: parseInt(endMorningMinute, 10)
        },
        beginAfternoon: {
          hour: parseInt(beginAfterNoonHour, 10),
          minute: parseInt(beginAfterNoonMinute, 10)
        }
      };
    });

    this.originalOpeningHours = null;
  }

  computeAddress(data: any) {
    const firstAddress = data['addresses'][0] ?? [];

    if (!firstAddress) {
      return;
    }

    this.address = {
      address1: firstAddress['address']?.trim(),
      address2: firstAddress['additionalAddress']?.trim(),
      zipCode: firstAddress['postCode']?.trim(),
      city: firstAddress['townLabel']?.trim(),
      longitude: parseFloat(firstAddress['longitude']),
      latitude: parseFloat(firstAddress['latitude']),
      region: data.geoIdentity?.regionLabel
    };
  }

  computeContacts(data: any) {
    const phone = data['contacts'].find((elt: any) => {
      return elt['contactCode'] === 'telephone';
    });

    const fax = data['contacts'].find((elt: any) => {
      return elt['contactCode'] === 'fax';
    });

    const email = data['contacts'].find((elt: any) => {
      return elt['contactCode'] === 'mail';
    });

    this.phone = phone?.contactValue;
    this.fax = fax?.contactValue;
    this.email = email?.contactValue;
  }

  computeMessage(data: any) {
    const newsMessages = data.ecommerce?.configuration?.newsMessages ?? [];
    const now = Date();

    const activeMessages = newsMessages.filter((message: any) => {
      const begin = Date(message.beginDate);
      const end = message.endDate ? Date(message.endDate) : null;

      return now >= begin && (end ? now < end : true) && message.isActive;
    });

    activeMessages.sort((a: any, b: any) =>
      a.weighting < b.weighting ? -1 : 1
    );

    const highestPriorityMessage = activeMessages.find((elt: any) => {
      return elt.highestPriority;
    });

    const message = highestPriorityMessage
      ? highestPriorityMessage.news
      : activeMessages.shift()?.news;

    if (message) {
      this.message = message.replace(/\n/g, '<br/>');
    }
  }

  computeExcepetionDate(data: any) {
    const openingDate: ExceptionDate[] = [] || null;
    const closureDate: ExceptionDate[] = [] || null;

    data.calendar?.exceptionDates.forEach((date: any) => {
      const exceptionDate: ExceptionDate = {
        type: EXCEPTION_MAPPING[date.exceptionType],
        startDate: date.startDate,
        endDate: date?.endDate,
        begin: {
          hour: date?.startHours?.split(':')[0],
          minute: date?.startHours?.split(':')[1]
        },
        end: {
          hour: date?.endHours?.split(':')[0],
          minute: date?.endHours?.split(':')[1]
        }
      };

      if (EXCEPTION_MAPPING[date.exceptionType] === 'opening') {
        openingDate.push(exceptionDate);
      }

      if (EXCEPTION_MAPPING[date.exceptionType] === 'closure') {
        closureDate.push(exceptionDate);
      }
    });
    if (openingDate.length > 0 || closureDate.length > 0) {
      this.exceptionDates = {
        opening: openingDate.sort(
          (a, b) =>
            Math.abs(Date(a.startDate) as any) -
            Math.abs(Date(b.startDate) as any)
        ),
        closure: closureDate.sort(
          (a, b) =>
            Math.abs(Date(a.startDate) as any) -
            Math.abs(Date(b.startDate) as any)
        )
      };
    } else {
      this.exceptionDates = undefined;
    }
  }

  getStoreLocatorExceptionDate() {
    let nextDate;

    const isValidDate = (expectionDate: Dayjs) => {
      const now = Date();
      const date = Date(expectionDate);
      const nextMonth = Date().month(now.month() + 1);
      return date >= now && date <= nextMonth;
    };

    if (this.exceptionDates) {
      const closureDate = this.exceptionDates.closure?.filter(
        ({ startDate }) => startDate && isValidDate(startDate)
      )[0];

      const openingDate = this.exceptionDates.opening?.filter(
        ({ startDate }) => startDate && isValidDate(startDate)
      )[0];

      if (openingDate && !closureDate) {
        nextDate = openingDate;
      } else if (!openingDate && closureDate) {
        nextDate = closureDate;
      } else if (openingDate?.startDate && closureDate?.startDate) {
        nextDate =
          openingDate.startDate > closureDate.startDate
            ? closureDate
            : openingDate;
      }
    }
    return nextDate;
  }
}

export default Pdv;
