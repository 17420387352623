import React from 'react';
import './style.scss';
import classnames from 'classnames';

type LoaderProps = {
  className?: string;
  color?: 'red' | 'white' | 'greenExcellent';
  size?: 'small' | 'medium' | 'large';
};

const Loader = ({ color = 'red', size = 'small', className }: LoaderProps) => {
  return (
    <div
      className={classnames(
        'loader',
        `loader--${color}`,
        `loader--${size}`,
        className
      )}
    >
      <svg className="loader__circular" viewBox="25 25 50 50">
        <circle
          className="loader__pathOutline"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="1"
          strokeMiterlimit="10"
        />
        <circle
          className="loader__path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="1"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};

export default Loader;
