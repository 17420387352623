import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';

class DataLayerApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  async getInfos(pdv: string, userId: string) {
    let response;
    try {
      response = await this.axios.get(`/data-layer/v1/principal`, {
        params: {
          pdv,
          userId
        }
      });
    } catch (error: any) {
      logger.error({
        message: 'Unable to update user email',
        error
      });

      throw error;
    }
    return response?.data;
  }
}

export default new DataLayerApi();
