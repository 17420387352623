import { AnyAction } from 'redux';
import { ADD_CHECKED_PDV } from './actions';

export type StoreLocatorStateType = {
  checked: Array<string>;
};

const defaultState = {
  checked: []
};

const StoreLocatorReducer = (
  state: StoreLocatorStateType = defaultState,
  action: AnyAction
) => {
  switch (action.type) {
    case ADD_CHECKED_PDV:
      return {
        ...state,
        checked: [...state.checked, action.payload.pdv]
      };
    default:
      return state;
  }
};

export default StoreLocatorReducer;
