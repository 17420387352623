import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const slug = action.payload.slug as string;

  return {
    ...baseData,
    page_type: 'edito',
    page_name: slug,
    page_virtual_path: `/sign/edito/${slug}`
  };
};

export default getData;
