import base, { GlobalProductFormatter } from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const { product, index, path } = action.payload;

  const route = path.split('/').splice(1).join('/');
  const pageType = path.split('/')[1];

  const productData = GlobalProductFormatter(product, route);

  const data = { ...productData, product_list_position: index?.toString() };

  return {
    ...baseData,
    e_action: 'product clic',
    e_category: 'ecommerce',
    event: 'product clic',
    e_label: pageType,
    product_clic: [data]
  };
};

export default getData;
