import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const type = action.payload.type as
    | 'optin'
    | 'slotType'
    | 'slotTime'
    | 'discount'
    | 'voucher'
    | 'closedShelves'
    | 'unavailableProducts'
    | 'promoExpired'
    | 'productComment'
    | 'removeUnavailableProducts'
    | 'validateCart'
    | 'substractingProducts'
    | 'paymentOption'
    | 'productCheckbox'
    | 'fidelity'
    | 'partnerInfo'
    | 'gameEvent'
    | 'continue'
    | 'optoutBag'
    | 'optinBag'
    | 'deliveryPartner'
    | 'deliveryPartnersOpt'
    | 'acceptSubstitution'
    | 'modifyAddress'
    | 'stepperClick'
    | 'openChoiceDeleteCart'
    | 'selectProvider'
    | 'selectMkpDelivery'
    | 'encombrants'
    | 'deliveryComment';

  const {
    slotId,
    slotTime,
    discount,
    voucher,
    productId,
    fidAmount,
    paymentOpt,
    nbrAuthorized,
    provider,
    animation,
    substitutedProducts,
    basketId,
    userId
  } = action.payload;

  let data = {};

  switch (type) {
    case 'optin':
      data = {
        e_action: 'retrait',
        e_label: 'm envoyer un message quand ma commande est prête'
      };
      break;
    case 'slotType':
      data = {
        e_action: 'choix livraison',
        e_label: slotId
      };
      break;
    case 'slotTime':
      data = {
        e_action: 'choix creneau',
        e_label: slotTime
      };
      break;
    case 'validateCart':
      data = {
        e_action: 'validation panier'
      };
      break;
    case 'discount':
      data = {
        e_action: 'utilisation code reduction',
        e_label: discount
      };
      break;
    case 'voucher':
      data = {
        e_action: 'utilisation bon dachat',
        e_label: voucher
      };
      break;
    case 'fidelity':
      data = {
        e_action: 'utilisation décagnottage',
        e_label: fidAmount
      };
      break;
    case 'closedShelves':
      data = {
        e_action: 'affichage popin',
        e_label: 'rayon trad ferme'
      };
      break;
    case 'unavailableProducts':
      data = {
        e_action: 'affichage popin',
        e_label: 'produits indisponibles'
      };
      break;
    case 'promoExpired':
      data = {
        e_action: 'affichage popin',
        e_label: 'avantages expires'
      };
      break;
    case 'productComment':
      data = {
        e_action: 'commentaire preparateur',
        e_label: productId
      };
      break;
    case 'removeUnavailableProducts':
      data = {
        e_action: 'suppression produits indisponibles',
        e_label: productId
      };
      break;
    case 'continue':
      data = {
        e_action: 'continuer sans remplacer',
        e_label: productId
      };
      break;
    case 'substractingProducts':
      data = {
        e_action: 'decochage opt-in substitution',
        e_label: basketId,
        event: 'Opt-out substitution'
      };
      break;
    case 'productCheckbox':
      data = {
        e_action: 'substitution produits',
        e_label: nbrAuthorized
      };
      break;
    case 'paymentOption':
      data = {
        e_action: 'choix mode de paiement',
        e_label: paymentOpt
      };
      break;
    case 'partnerInfo':
      data = {
        e_action: 'info prestaire',
        e_label: provider
      };
      break;
    case 'gameEvent':
      data = {
        e_action: 'participation jeux',
        e_label: animation
      };
      break;
    case 'optoutBag':
      data = {
        e_action: 'optout sacs',
        e_label: 'oui'
      };
      break;
    case 'optinBag':
      data = {
        e_action: 'optout sacs',
        e_label: 'non'
      };
      break;
    case 'deliveryPartner':
      data = {
        e_action: 'choix prestataire livraison',
        e_label: provider
      };
      break;
    case 'deliveryPartnersOpt':
      data = {
        e_action: 'prestataire creneau',
        e_label: provider
      };
      break;
    case 'acceptSubstitution':
      data = {
        e_action: 'substitution produits',
        e_label: substitutedProducts
      };
      break;
    case 'openChoiceDeleteCart':
      data = {
        e_label: action.payload.subCartType === 'PDV' ? 'itm' : 'mkp',
        e_action: 'demande de vider panier'
      };
      break;
    case 'stepperClick':
      data = {
        e_action: 'clic stepper',
        e_label: action.payload.step.value
      };
      break;
    case 'modifyAddress':
      data = {
        e_label: action.payload.mode,
        e_action: 'modifier adresse'
      };
      break;
    case 'selectProvider':
      data = {
        e_label: action.payload.seller.sellerName,
        e_action: 'panier partenaire',
        e_category: 'checkout'
      };
      break;
    case 'selectMkpDelivery':
      data = {
        e_label: action.payload.option,
        e_action: 'livraison partenaire',
        e_category: 'checkout'
      };
      break;
    case 'encombrants':
      data = {
        e_label: 'produits encombrants',
        e_action: 'affichage popin',
        e_category: 'checkout'
      };
      break;
    case 'deliveryComment':
      data = {
        e_label: userId,
        e_action: 'commentaire livreur',
        e_category: 'checkout'
      };
      break;
  }
  return {
    ...baseData,
    ...data,
    e_category: 'checkout'
  };
};

export default getData;
