import base, { GlobalProductFormatter } from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const { product, path, offer } = action.payload;

  const route = path.split('/').splice(1).join('/');
  const pageType = path.split('/')[1];

  const data = GlobalProductFormatter(product, route, undefined, offer);

  return {
    ...baseData,
    e_action: 'product detail',
    e_category: 'ecommerce',
    event: 'product detail',
    e_label: pageType,
    product_impression: [data]
  };
};

export default getData;
