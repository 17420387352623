import base from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const { autopromo, type, path, position } = action.payload;

  const campaignName = autopromo.badge
    ? `${autopromo.badge.split(' ').join('_')}::${autopromo.campaign}`
    : autopromo.campaign;

  const pathName = path.split('/')[1];
  const eventData = {
    /* autopromo_creative: '', */
    autopromo_name: campaignName,
    autopromo_position: position,
    autopromo_type: `${pathName}::${type}`
  };

  let data;

  switch (type) {
    case 'slider':
      data = { ...eventData, autopromo_position: position };
      break;
    default:
      data = eventData;
      break;
  }

  return {
    ...baseData,
    e_action: 'autopromo clic',
    e_category: 'ecommerce',
    event: 'autopromo clic',
    e_label: type,
    autopromo_click: [data]
  };
};

export default getData;
