/* eslint no-param-reassign: off */
import {
  TOGGLE_MINICART_OPENED,
  ADD_SLOTS,
  CLEAR_EVENTS,
  ADD_SELECTED_SLOT,
  UPDATE_SELECTED_VOUCHER,
  UPDATE_SELECTED_FID,
  UPDATE_CART,
  RESET_SLOTS,
  UPDATE_TRACKING_BY_PHONE,
  DELETE_SELLER_SUB_CART,
  UPDATE_DELIVERY_COMMENT,
  DELETE_ALL_CARTS,
  ACCEPT_GAMES_CONTEST,
  SELECT_PROVIDER,
  CONFIRM_PROVIDER
} from '@/store/cart/cartActions';
import Date from '@/lib/utils/date';
import {
  StateType,
  SubCartType,
  EventCart,
  ProviderType,
  PackageType
} from '@/store/cart/types';
import { AnyAction } from 'redux';
import { REHYDRATE } from 'redux-persist';

const initStateCart = {
  basketId: undefined,
  slots: {
    list: {},
    discountCodeActive: false,
    vouchersCodeActive: false,
    selectedSlot: {
      slot: null,
      deliveryType: '',
      maxLiquidVolume: 0,
      availablePayments: {}
    },
    selectedProvider: null,
    lastUpdate: null
  },
  mkpPackages: {},
  selectedVoucher: 0,
  selectedFID: 0,
  eventsBins: [],
  isHydrated: false,
  isMiniCartOpened: false,
  trackingByPhone: false,
  total: 0,
  hasAlcoholProduct: false,
  qty: 0,
  events: [],
  subCarts: [],
  isLastCartConnected: false,
  gamesContest: {
    isAccepted: false,
    idGameContest: null
  },
  immediateDiscount: 0,
  deferredDiscount: 0
};

const cartReducer: any = (
  state: StateType = initStateCart,
  action: AnyAction
) => {
  switch (action.type) {
    case TOGGLE_MINICART_OPENED:
      return {
        ...state,
        isMiniCartOpened: !state.isMiniCartOpened
      };
    case UPDATE_TRACKING_BY_PHONE:
      return {
        ...state,
        trackingByPhone: action.payload
      };
    case UPDATE_SELECTED_VOUCHER:
      return {
        ...state,
        selectedVoucher: action.payload
      };
    case UPDATE_SELECTED_FID:
      return {
        ...state,
        selectedFID: action.payload
      };
    case DELETE_SELLER_SUB_CART: {
      const { sellerId, isAuthentificated } = action.payload;
      const { events, subCarts, eventsBins } = state;
      let newEvents: Array<EventCart> = [];
      let newEventsBins: Array<EventCart> = eventsBins;
      const deletedProductsIdList: Array<string> = [];

      const deletedSubCart = subCarts.find(
        (subCart: SubCartType) => subCart.seller.sellerId === sellerId
      );
      const newSubCarts = subCarts.filter(
        (subCart: SubCartType) => subCart.seller.sellerId !== sellerId
      );

      if (isAuthentificated && eventsBins.length) {
        Object.keys(deletedSubCart?.items || {}).forEach((key: string) => {
          newEventsBins = newEventsBins.filter(
            (event: EventCart) => event.productId?.toString() !== key.toString()
          );
        });
      }

      if (!isAuthentificated && newSubCarts.length) {
        Object.keys(deletedSubCart?.items || {}).forEach((key: string) => {
          if (deletedSubCart?.items[key].product.id) {
            deletedProductsIdList.push(deletedSubCart?.items[key].product.id);
          }
        });

        newEvents = events.filter(
          (event: EventCart) =>
            event.productId && !deletedProductsIdList.includes(event.productId)
        );
      }

      const newMkpPackages = { ...state.mkpPackages };
      if (sellerId !== 'ITM') {
        delete newMkpPackages[sellerId];
      }

      return {
        ...state,
        isMiniCartOpened: newSubCarts.length,
        hasAlcoholProduct: newSubCarts.length ? null : false,
        trackingByPhone: false,
        selectedVoucher: 0,
        selectedFID: 0,
        slots:
          sellerId === 'ITM'
            ? {
                list: {},
                selectedSlot: {
                  slot: null,
                  deliveryType: '',
                  availablePayments: {}
                },
                selectedProvider: null
              }
            : { ...state.slots },
        mkpPackages: newMkpPackages,
        total: newSubCarts.reduce(
          (total: number, subCart: SubCartType) => total + subCart.total,
          0
        ),
        qty: newSubCarts.reduce(
          (total: number, subCart: SubCartType) =>
            total + Object.keys(subCart.items).length,
          0
        ),
        eventsBins: [...newEventsBins],
        isHydrated: true,
        subCarts: [...newSubCarts],
        events: [...newEvents],
        gamesContest:
          sellerId === 'ITM'
            ? { ...initStateCart.gamesContest }
            : { ...state.gamesContest },
        immediateDiscount: newSubCarts.reduce(
          (discount: number, subCart: SubCartType) =>
            discount + subCart.valorisation.immediateDiscount,
          0
        ),
        deferredDiscount: newSubCarts.reduce(
          (deffered: number, subCart: SubCartType) =>
            deffered + subCart.valorisation.deferredDiscount,
          0
        )
      };
    }
    case DELETE_ALL_CARTS: {
      return { ...initStateCart, isHydrated: true };
    }
    case ADD_SLOTS:
      return {
        ...state,
        slots: {
          ...state.slots,
          selectedSlot: {
            slot: null,
            deliveryType: state.slots.selectedSlot.deliveryType ?? '',
            maxLiquidVolume: 0,
            availablePayments: {}
          },
          vouchersCodeActive: action.payload.vouchersCodeActive,
          discountCodeActive: action.payload.discountCodeActive,
          list: action.payload.data,
          lastUpdate: Date().format('YYYY-MM-DDTHH:mm:ssZ')
        },
        mkpPackages: action.payload.mkpPackages,
        metaData: action.payload.metaData,
        defaultPayments: action.payload.defaultPayments
      };
    case SELECT_PROVIDER: {
      const {
        sellerId,
        selectedProvider,
        packageId
      }: {
        sellerId: string;
        selectedProvider: ProviderType | null;
        packageId: string;
      } = action.payload;
      if (sellerId === 'ITM') {
        return {
          ...state,
          slots: {
            ...state.slots,
            selectedProvider: {
              ...selectedProvider,
              isConfirmed: false
            }
          }
        };
      }
      return {
        ...state,
        mkpPackages: {
          ...state.mkpPackages,
          [sellerId]: {
            ...state.mkpPackages?.[sellerId],
            packages: {
              ...state.mkpPackages?.[sellerId].packages,
              [packageId]: {
                ...state.mkpPackages?.[sellerId].packages[packageId],
                selectedProvider
              }
            }
          }
        }
      };
    }
    case CONFIRM_PROVIDER: {
      const { sellerId } = action.payload;
      if (sellerId === 'ITM') {
        return {
          ...state,
          slots: {
            ...state.slots,
            selectedProvider: {
              ...state.slots.selectedProvider,
              isConfirmed: true
            }
          }
        };
      }
      const newPackages: { [key: string]: PackageType } =
        { ...state.mkpPackages?.[sellerId]?.packages } || {};
      Object.keys(newPackages).forEach((key) => {
        newPackages[key].isConfirmed = true;
      });
      return {
        ...state,
        mkpPackages: {
          ...state.mkpPackages,
          [sellerId]: {
            ...state.mkpPackages?.[sellerId],
            packages: newPackages
          }
        }
      };
    }
    case ADD_SELECTED_SLOT:
      return {
        ...state,
        slots: {
          ...state.slots,
          ...action.payload
        }
      };
    case UPDATE_CART:
      return {
        ...state,
        ...action.payload,
        events: action.isAuthentificated ? [] : [...action.payload.events],
        eventsBins: action.isAuthentificated
          ? [...state.eventsBins, ...action.payload.events]
          : [],
        isLastCartConnected: action.isAuthentificated
      };
    case CLEAR_EVENTS:
      return {
        ...state,
        events: [...state.events, ...state.eventsBins],
        eventsBins: [],
        isLastCartConnected: false
      };
    case RESET_SLOTS:
      return {
        ...state,
        slots: {
          list: {},
          discountCodeActive: false,
          vouchersCodeActive: false,
          selectedSlot: {
            slot: null,
            deliveryType: '',
            maxLiquidVolume: 0,
            availablePayments: {}
          },
          selectedProvider: null
        },
        mkpPackages: {}
      };
    case ACCEPT_GAMES_CONTEST:
      return {
        ...state,
        gamesContest: {
          isAccepted: action.payload.isAccepted,
          idGameContest: action.payload.idGameContest
        }
      };
    case UPDATE_DELIVERY_COMMENT:
      return {
        ...state,
        consigneLivraison: action.payload
      };
    case REHYDRATE:
      if (action.key === 'cart' && action.payload) {
        return {
          ...state,
          isHydrated: action.payload['_persist'].rehydrated
        };
      }
      return state;
    default:
      return state;
  }
};

export default cartReducer;
