import React, { useEffect } from 'react';
import Product from '@/lib/model/product';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { StateType as CartStateType } from '@/store/cart/types';
import { getPdvId } from './pdv';
import { getProductsInfos } from './products';
import { OnBuyType } from './confirmOrder/criteo';
import { addToCartApi } from './cart/addToCart';

export type LuckyCartCoversProps = {
  partnersDatas: PartenersDatasProps;
};

export type LuckyCartCover = {
  href: string;
  img: string;
  alt?: string;
  id?: string;
};

export type PartenersDatasProps = {
  covers: Array<LuckyCartCover> | null;
  criteoButterflyProducts1: Array<Product> | null;
  criteoButterflyProducts2: Array<Product> | null;
  tileCriteo1: any;
  tileCriteo2: any;
  tileHighCo: any;
  isHighcoExist: boolean;
  isLuckyCartExist: boolean;
};

declare global {
  interface Window {
    exposedApi: {
      getPdvId?: () => string;
      getProductsInfos?: (
        productIds: Array<any>,
        pdvRef: string
      ) => Promise<Array<Product | never>>;
      addHighCo?: () => void;
      addLuckyCart?: (data: { covers: Array<LuckyCartCover> }) => any;
      addShowCase?: (data: { products: any }) => any;
      getSearchResults?: () => Array<Product> | null;
      addToCart?: (ean: string, quantity: number) => any;
      addCriteo?: ({
        butterflyProducts1,
        butterflyProducts2
      }: {
        butterflyProducts1: any;
        butterflyProducts2: any;
      }) => Promise<any>;
    };
    __sto: {
      globals: {
        onBuy: (productsIds: Array<OnBuyType>) => void;
      };
    };
  }
}

const ThirdPartyApi = () => {
  const dispatch = useDispatch();
  // TODO : const cartStore = useShalloEqualSelector(useCart);
  const { cartStore } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      cartStore: cart
    };
  }, shallowEqual);

  useEffect(() => {
    window.exposedApi = window.exposedApi || {};
    if (window) {
      window.exposedApi = {
        ...window.exposedApi,
        getPdvId,
        getProductsInfos,
        addToCart: (ean: string, quantity: number) => {
          const previousQuantity = Object.fromEntries(
            cartStore.subCarts.flatMap(({ items }) =>
              Object.values(items).map(({ product, qty }) => [
                product.ean,
                { qty, maxQty: product.maxQty }
              ])
            )
          )[ean];
          const currentQuantity = (previousQuantity?.qty ?? 0) + quantity;

          if (
            !previousQuantity?.maxQty ||
            (previousQuantity.maxQty &&
              currentQuantity <= previousQuantity.maxQty)
          )
            addToCartApi(dispatch, ean, currentQuantity);
        }
      };
    }

    return () => {
      delete window.exposedApi.getPdvId;
      delete window.exposedApi.getProductsInfos;
    };
  }, [dispatch, cartStore]);

  return <></>;
};

export default ThirdPartyApi;
