import { store } from '@/store';
import { getPdvRef } from '@/lib/hooks/usePdv';
import Category from '../model/category';

interface CategoriesProps {
  universId: string;
  departmentId: string;
  famillyId: string;
  productType: string;
}

export const getCategoriesName = ({
  universId,
  departmentId,
  famillyId,
  productType
}: CategoriesProps) => {
  const storeState: any = store.getState();
  const pdvRef = getPdvRef();

  const productCategory = storeState?.nav?.mainCategories[pdvRef]?.data?.find(
    (cat: Category) => cat.id === universId
  );
  let productSubCategory;
  let productSubSubCategory;
  if (productCategory?.children) {
    productSubCategory = productCategory.children.find(
      (cat: Category) => cat.id === departmentId
    );
  }
  if (productSubCategory?.children) {
    productSubSubCategory = productSubCategory.children.find(
      (cat: Category) => cat.id === famillyId
    );
  }

  productSubCategory =
    productType === 'MKP' ? { title: 'marketplace' } : productSubCategory;

  productSubSubCategory =
    productType === 'MKP' ? { title: 'marketplace' } : productSubSubCategory;

  return {
    productCategory: productCategory?.title,
    productSubCategory: productSubCategory?.title,
    productSubSubCategory: productSubSubCategory?.title
  };
};
