import ProductModel from '@/lib/model/product';
import base, { GlobalProductFormatter } from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const { products, path } = action.payload;
  let pageType = path.split('/')[1];

  let route = path.split('/').splice(1).join('/');
  let type = 'classic';

  if (path.match(/\/my-usual-products\.*/)?.length) {
    type = 'regular product total';
    route = route.split('/').splice(1, 3).join('/');
    pageType = 'my-usual-products';
  }

  if (path.match(/\/my-shopping-lists\.*/)?.length) {
    type = 'shopping list total';
    route = route.split('/').splice(1, 3).join('/');
    pageType = 'my-shopping-lists';
  }

  const allProductToCartPayload = (
    productsList: Array<ProductModel>
  ): Array<any> =>
    productsList.map((product: ProductModel) => {
      return GlobalProductFormatter(product, route);
    });

  const data = allProductToCartPayload(products);

  return {
    ...baseData,
    e_action: `add to cart::${type}`,
    e_category: 'ecommerce',
    event: `add to cart`,
    e_label: pageType,
    product_addtocart: [...data]
  };
};

export default getData;
