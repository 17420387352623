import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const type = action.payload.type as string;

  const { label } = action.payload;

  let data = {};

  switch (type) {
    case 'formAccessFromPdp':
      data = {
        e_action: 'acces questionnaire',
        e_label: label,
        e_category: 'conso mieux'
      };
      break;
    case 'consentementSes':
      data = {
        e_action: 'consentement utilisation questionnaire',
        e_label: label
      };
      break;

    case 'RefuseQues':
      data = {
        e_action: 'refus utilisation questionnaire',
        e_label: label
      };
      break;
    case 'optinParcours':
      data = {
        e_action: 'optin parcours personnalise',
        e_label: label
      };
      break;
    case 'deleteQues':
      data = {
        e_action: 'effacer reponses',
        e_label: label
      };
  }
  return {
    ...baseData,
    ...data,
    e_category: 'consommieux'
  };
};

export default getData;
