import Categories from '@/lib/model/categories';
import Category from '@/lib/model/category';

export type CategoryApi = {
  id_categorie: number;
  libelle: string;
  isPresentAlcoholProduct: boolean;
  categories?: Array<CategoryApi>;
};

export const getCategoryById = (
  id: string,
  data: Array<CategoryApi>,
  basePath = '/shelves',
  maxDepth?: number
): Category | null => {
  const searchById = (
    idTemp: string,
    dataTemp: Array<CategoryApi>,
    basePathTemp: string,
    maxDepthTemp?: number,
    levelTemp = 1
  ): Category | null => {
    for (let i = 0; i < dataTemp.length; i++) {
      const categoryTemp: CategoryApi = dataTemp[i];

      if (idTemp === categoryTemp.id_categorie.toString()) {
        return Categories.buildTree(
          [categoryTemp],
          maxDepthTemp || levelTemp,
          levelTemp,
          basePathTemp
        )[0];
      }

      if (categoryTemp.categories?.length) {
        const category = searchById(
          idTemp,
          categoryTemp.categories,
          basePathTemp,
          maxDepthTemp,
          levelTemp + 1
        );
        if (category) return category;
      }
    }
    return null;
  };

  return searchById(id, data, basePath, maxDepth);
};

export const getParentCategory = (
  id: string,
  data: Array<CategoryApi>,
  basePath = '/shelves',
  maxDepth?: number
): Category | null => {
  const searchById = (
    idTemp: string,
    dataTemp: any,
    basePathTemp: string,
    maxDepthTemp?: number,
    levelTemp = 1,
    parentCatergoryTemp: CategoryApi | null = null
  ): Category | null => {
    for (let i = 0; i < dataTemp.length; i++) {
      const categoryTemp: CategoryApi = dataTemp[i];

      if (
        idTemp === categoryTemp.id_categorie.toString() &&
        parentCatergoryTemp
      ) {
        return Categories.buildTree(
          [parentCatergoryTemp],
          maxDepth,
          levelTemp - 1,
          basePathTemp
        )[0];
      }

      if (categoryTemp.categories?.length) {
        const category = searchById(
          idTemp,
          categoryTemp.categories,
          basePathTemp,
          maxDepthTemp,
          levelTemp + 1,
          categoryTemp
        );
        if (category) return category;
      }
    }
    return null;
  };

  return searchById(id, data, basePath, maxDepth);
};

export const findCategory = (
  id: string,
  data: Array<Category>
): Category | null => {
  const searchById = (idTemp: string, dataTemp: any): Category | null => {
    for (let i = 0; i < dataTemp.length; i++) {
      const categoryTemp: Category = dataTemp[i];

      if (idTemp === categoryTemp.id.toString()) {
        return categoryTemp;
      }

      if (categoryTemp.children?.length) {
        const category = searchById(idTemp, categoryTemp.children);
        if (category) return category;
      }
    }
    return null;
  };

  return searchById(id, data);
};
