import Product from '@/lib/model/product';
import { AnyAction } from 'redux';
import { REHYDRATE } from 'redux-persist';
import {
  SET_LAST_EAN_SKU,
  SET_OP_DISCLAIMER_DISPLAYED_PDV_ACTIVE,
  SET_OP_DISCLAIMER_DISPLAYED_PDV_NON_ACTIVE,
  ADD_SEARCH_HISTORY,
  REMOVE_SEARCH_HISTORY,
  CLEAN_SEARCH_HISTORY,
  SET_SMARTCONSO_DISPLAYED,
  SET_RECOMMENDATIONS_POPIN_DISPLAYED,
  SET_SELECTED_PRODUCT
} from './commonActions';

export type CommonStateType = {
  lastEanOrSku: string | null;
  isHydrated: boolean;
  oPdisclaimerDisplayedPdvActive: boolean;
  oPdisclaimerDisplayedPdvNonActive: boolean;
  searchHistory: Array<string>;
  smartConsoDisplayed: Array<string>;
  recommendationsPopinDisplayed: boolean;
  selectedProduct?: Product;
};

const defaultState = {
  lastEanOrSku: null,
  isHydrated: false,
  oPdisclaimerDisplayedPdvActive: false,
  oPdisclaimerDisplayedPdvNonActive: false,
  searchHistory: [],
  smartConsoDisplayed: [],
  recommendationsPopinDisplayed: false
};

const CommonReducer = (
  state: CommonStateType = defaultState,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_LAST_EAN_SKU:
      return {
        ...state,
        lastEanOrSku: action.payload.lastEanOrSku
      };
    case SET_OP_DISCLAIMER_DISPLAYED_PDV_ACTIVE:
      return {
        ...state,
        oPdisclaimerDisplayedPdvActive: action.payload.value
      };
    case SET_OP_DISCLAIMER_DISPLAYED_PDV_NON_ACTIVE:
      return {
        ...state,
        oPdisclaimerDisplayedPdvNonActive: action.payload.value
      };
    case SET_SMARTCONSO_DISPLAYED:
      return {
        ...state,
        smartConsoDisplayed: [
          ...(typeof state.smartConsoDisplayed === 'boolean'
            ? []
            : state.smartConsoDisplayed),
          action.payload.userId
        ]
      };
    case SET_RECOMMENDATIONS_POPIN_DISPLAYED:
      return {
        ...state,
        recommendationsPopinDisplayed: action.payload.value
      };
    case ADD_SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: action.payload.value?.trim()?.length
          ? [
              ...new Set([action.payload.value.trim(), ...state.searchHistory])
            ].slice(0, 5)
          : [...state.searchHistory]
      };
    case REMOVE_SEARCH_HISTORY: {
      const searchHistory = [...state.searchHistory];
      searchHistory.splice(action.payload.index, 1);
      return {
        ...state,
        searchHistory
      };
    }
    case CLEAN_SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: []
      };
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload.product
      };
    case REHYDRATE:
      if (action.key === 'common' && action.payload) {
        return {
          ...state,
          isHydrated: action.payload['_persist'].rehydrated
        };
      }
      return state;
    default:
      return state;
  }
};

export default CommonReducer;
