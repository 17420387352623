import getConfigValue from '@/lib/config';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import Cookie from 'js-cookie';

const isNode = typeof window === 'undefined';

const cookiesInRedApiMiddleware: any = {};

export const setCookieInRedApiMiddleware = (name: string, value: string) => {
  if (!name) return;
  if (!value) return;
  cookiesInRedApiMiddleware[name] = value;
};

const RedApiMiddleware = (axios: AxiosInstance) => {
  /* eslint-disable no-param-reassign */
  axios.defaults.headers = {
    ...axios.defaults.headers,
    'x-red-device': getConfigValue('AXIOS_X_RED_DEVICE', 'fo-desktop'),
    'x-red-version': getConfigValue('AXIOS_X_RED_VERSION', 3)?.toNumber()
  };

  if (isNode) {
    const baseURLProtocol = getConfigValue(
      'API_HOST_PROTOCOL',
      'http'
    ).toString();
    const baseURLDomain = getConfigValue(
      'API_HOST_DOMAIN',
      'localhost'
    ).toString();
    axios.defaults.baseURL = `${baseURLProtocol}://${baseURLDomain}`;
  } else {
    axios.defaults.baseURL = '/api/service';
  }

  axios.interceptors.request.use((requestConfig: AxiosRequestConfig) => {
    requestConfig.headers = {
      ...requestConfig.headers,
      'x-service-name': requestConfig.url?.split('/').filter((elt) => !!elt)[0],
      ...{
        'x-itm-device-fp':
          Cookie.get('itm_device_id') ||
          cookiesInRedApiMiddleware['itm_device_id'] ||
          'undefined',
        'x-itm-session-id':
          Cookie.get('itm_usid') ||
          cookiesInRedApiMiddleware['itm_usid'] ||
          'undefined',
        'x-pdv':
          Cookie.get('itm_pdv') ||
          cookiesInRedApiMiddleware['itm_pdv'] ||
          'undefined'
      }
    };
    return requestConfig;
  });
};

export default RedApiMiddleware;
