import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import Date from '@/lib/utils/date';
import t from '@/lib/i18n';
import { BillingAddress } from '@/lib/api/address/types';
import dayjs from 'dayjs';
import { store } from '@/store';
import { UPDATE_INFOS } from '@/store/auth/authActions';
import { Informations, OptIn, OptOut, UpdateInfoRequest } from './types';
import {
  OptInMapping,
  OptInReverseMapping,
  OptOutMapping,
  OptOutReverseMapping
} from './mapping';
import { InvalidOldPasswordException } from './exceptions';
import { getFidelityCardNumberValidationError } from '../fidelity/exceptions';
import { FidelityInfo } from '../fidelity/types';

class UserInformation {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  async getInformations(userId: string): Promise<Informations> {
    let response = null;

    try {
      response = await this.axios.get(
        `/gestiondecompte/v1/moncompte/${userId}`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to get user information',
        error
      });
    }

    const data = {
      ...response?.data
    };

    const informations: Informations = {
      id: userId,
      isPro: data.isPro === 'true',
      civility: data.civilite === '1' ? 'male' : 'female',
      lastName: data.nom,
      firstName: data.prenom,
      company: data.societe,
      nif: data.nif,
      email: data.email,
      address: data.adresse3,
      additionalAddress: data.adresse4,
      additionalInformation: data.adresse2,
      zipCode: data.codePostal,
      city: data.ville,
      country: data.pays,
      mobilePhone: data.mob,
      phone: data.tel,
      lastVisitedPdv: data.pdv,
      pdvFidelity: data.pdvFid,
      fidelityCardNumber: data.numfid,
      birthdayDate: Date(data.dateN, 'DD/MM/YYYY'),
      fidelityCardStatus: data.statutCarteFidDemat,
      optIn: {
        itmEmail: false,
        itmSms: false,
        itmLetter: false,
        partnerEmail: false,
        partnerSms: false
      },
      optOut: {
        personalCommunication: true,
        personalNavigation: true,
        usePersonalDataForAds: true
      },
      verifiedClient: data.verifiedClient
    };

    if (informations.isPro) {
      informations.company = data.personneMorale.raisonSociale;
    }

    data.optins?.forEach((elt: any) => {
      if (OptInMapping[elt.nom]) {
        const key = OptInMapping[elt.nom] as keyof OptIn;
        informations.optIn[key] = elt.valeur === 'true';
      }
    });

    data.optouts?.forEach((elt: any) => {
      if (OptOutMapping[elt.nom]) {
        const key = OptOutMapping[elt.nom] as keyof OptOut;
        informations.optOut[key] = elt.valeur === 'false';
      }
    });

    if (response?.data) {
      store.dispatch({
        type: UPDATE_INFOS,
        payload: {
          lastName: informations.lastName,
          firstName: informations.firstName,
          mobilePhone: informations.mobilePhone
        }
      });
    }
    return informations;
  }

  async updateAddress(userId: string, address: BillingAddress) {
    const body = {
      statutAdrOrda: '3',
      addresseRc: address.type === 'billing' && address.isDefault,
      dateAdrOrda: dayjs().format('DD/MM/YYYY'),
      libelle: address.name,
      adresse3: address.streetName,
      adresse4: address.additionalStreetName,
      adresse2: address.additionalInformation,
      codePostal: address.zipCode,
      ville: address.city,
      pays: address.country
    };

    try {
      await this.axios.put(`/gestiondecompte/v1/moncompte/${userId}`, body);
    } catch (error: any) {
      logger.error({
        message: 'Unable to update user address',
        error
      });
    }
  }

  async updateFidelityCard(
    userInfo: Informations,
    newCardNumber: string,
    cardInfos: FidelityInfo | null,
    attachCard?: boolean
  ) {
    let body;
    const cgus = [
      {
        codeCgu: t('common.cguFid.code'),
        dateAcceptation: Date().format('DD/MM/YYYY'),
        idVerCgu: t('common.cguFid.version')
      }
    ];
    try {
      if (cardInfos && cardInfos.status === 'OPE') {
        body = {
          numfid: newCardNumber,
          cgus
        };
        await this.axios.put(
          `/gestiondecompte/v1/moncompte/${userInfo.id}`,
          body
        );
      } else {
        body = {
          attachCard: !!attachCard,
          activeCard: true,
          numFid: newCardNumber,
          lastName: userInfo.lastName,
          firstName: userInfo.firstName,
          dateN: userInfo.birthdayDate.format('DD/MM/YYYY'),
          pdvFid: cardInfos?.pdv ?? userInfo?.pdvFidelity,
          cgus
        };

        await this.axios.post(
          `/cartefidelite/v1/activate_card/account/${userInfo.id}`,
          body
        );
      }
    } catch (error: any) {
      logger.error({
        message: 'Unable to update user fidelity card',
        error
      });
      if (error?.response) {
        const { status } = error?.response;
        const { code } =
          error?.response?.data?.err?.[0] ||
          error?.response?.data ||
          error?.response?.data?.errors?.[0];
        throw getFidelityCardNumberValidationError(
          status === 500 ? 'TECHNICAL_ERROR' : code
        );
      }
    }
  }

  async updateInfo(updateInfoRequest: UpdateInfoRequest) {
    const data: {
      [key: string]:
        | string
        | boolean
        | { [key: string]: string | boolean | undefined };
    } = {};

    const excludedKeys = ['id', 'isPro', 'company'];

    Object.keys(updateInfoRequest).forEach((key) => {
      const userInfoKey = key as keyof UpdateInfoRequest;
      const value = updateInfoRequest[userInfoKey];

      if (!excludedKeys.includes(userInfoKey as string)) {
        switch (userInfoKey) {
          case 'civility':
            data.title = (value as string) === 'male' ? '1' : '2';
            break;
          case 'mobilePhone':
            data['mobileNumber'] = value as string;
            break;
          case 'phone':
            data['phoneNumber'] = value as string;
            break;
          case 'nif':
            data['nif'] = value as string;
            break;
          case 'birthdayDate':
            data.birthdate = (value as dayjs.Dayjs).format(
              'YYYY-MM-DD'
            ) as string;
            break;
          default:
            data[userInfoKey] = value as string;
            break;
        }
      }
    });

    if (updateInfoRequest.isPro && updateInfoRequest.company) {
      data.companyName = updateInfoRequest.company as string;
    }

    try {
      await this.axios.patch(
        `/profile/v1/profiles/${updateInfoRequest.id}`,
        data
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to update user info',
        context: {
          userId: updateInfoRequest.id
        },
        error
      });

      throw error;
    }
  }

  async changeEmail(userId: string, email: string) {
    try {
      await this.axios.put(`/gestiondecompte/v1/moncompte/${userId}`, {
        email
      });
    } catch (error: any) {
      logger.error({
        message: 'Unable to update user email',
        error
      });

      throw error;
    }
  }

  async changePhone(userId: string, mobilePhone: string) {
    try {
      await this.axios.put(`/gestiondecompte/v1/moncompte/${userId}`, {
        mob: mobilePhone
      });
    } catch (error: any) {
      logger.error({
        message: 'Unable to update user mobile phone',
        error
      });

      throw error;
    }
  }

  async changePassword(
    userId: string,
    oldPassword: string,
    newPassword: string
  ) {
    try {
      await this.axios.put(`/gestiondecompte/v1/moncompte/${userId}`, {
        ancienMdp: oldPassword,
        mdp: newPassword
      });
    } catch (error: any) {
      if (error.response.status === 400) {
        throw new InvalidOldPasswordException();
      } else {
        logger.error({
          message: 'Unable to update user password',
          context: {
            userId
          },
          error
        });

        throw error;
      }
    }
  }

  async removeFidelityCard(userId: string) {
    try {
      await this.axios.put(`/gestiondecompte/v1/moncompte/${userId}`, {
        numfid: ''
      });
    } catch (error: any) {
      logger.error({
        message: 'Unable to remove user fidelity card',
        error
      });

      throw error;
    }
  }

  async removeAccount(userId: string) {
    try {
      await this.axios.delete(`/gestiondecompte/v1/moncompte/${userId}`);
    } catch (error: any) {
      logger.error({
        message: 'Unable to remove user account',
        error
      });

      throw error;
    }
  }

  async updatePersonalData(
    userId: string,
    {
      optIn,
      optOut
    }: {
      optIn?: OptIn;
      optOut?: OptOut;
    }
  ): Promise<void> {
    const data: {
      optins: Array<{ [key: string]: string }>;
      optouts: Array<{ [key: string]: string }>;
    } = {
      optins: [],
      optouts: []
    };

    if (optIn) {
      Object.keys(optIn).forEach((key: any) => {
        data.optins.push({
          nom: OptInReverseMapping[key],
          valeur: optIn[key as keyof OptIn] ? 'true' : 'false'
        });
      });
    }

    if (optOut) {
      Object.keys(optOut).forEach((key: any) => {
        data.optouts.push({
          nom: OptOutReverseMapping[key],
          valeur: optOut[key as keyof OptOut] ? 'false' : 'true'
        });
      });
    }

    try {
      await this.axios.put(`/gestiondecompte/v1/moncompte/${userId}`, data);
    } catch (error: any) {
      logger.error({
        message: 'Unable to update user personal data',
        error
      });

      throw error;
    }
  }

  async updateLastVisitedPdv(userId: string, ref: string) {
    try {
      await this.axios.put(`/gestiondecompte/v1/moncompte/${userId}`, {
        pdv: ref
      });
    } catch (error: any) {
      logger.error({
        message: 'Unable to update last visited pdv',
        error
      });

      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserInformation();
