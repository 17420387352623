import { store } from '@/store';
import ProductModel from '@/lib/model/product';
import { slugify } from '@/lib/utils/stringUtils';
import { getCategoriesName } from '@/lib/utils/categories';
import { Offer } from '@/lib/model/productTypes';
import getConfigValue from '@/lib/config';

const getData = (action: any, state: any) => {
  return {
    hitType: 'event',
    type: 'ecommerce'
  };
};

interface ProductPromoTypeProps {
  [key: string]: string;
}

const productType: ProductPromoTypeProps = {
  immediateDiscount: 'remise immediate',
  deferredDiscount: 'avantage carte',
  quantityDiscount: 'lot virtuel',
  offeredDiscount: 'prix net',
  collectorPartner: 'avantage carte LV',
  undefined: 'undefined'
};

export const getPromoTypeOrCategory = (
  product: ProductModel,
  offer: Offer | undefined,
  typeOrUnit: 'type' | 'unit'
) => {
  if (product?.type === 'PDV') {
    const promo = product?.promotions?.[0]?.[typeOrUnit] ?? 'undefined';
    return productType[promo];
  }

  if (product.type === 'MKP') {
    const promo = offer?.promotions?.[0]?.[typeOrUnit] ?? 'undefined';
    return productType[promo];
  }
  return 'undefined';
};

export default getData;

export const GlobalProductFormatter = (
  product: ProductModel,
  path: string,
  quantity?: number,
  offer?: Offer
) => {
  if (!product) return {};
  const storeState: any = store.getState();
  const userId = storeState?.auth?.user?.id;
  const IS_SMART_CONSO_LOT2 = getConfigValue(
    'IS_SMART_CONSO_LOT2',
    false
  ).toBoolean();
  const hasFilledMandatoriesQuestions =
    storeState?.survey?.responses?.length > 3;
  const isCustomScore =
    hasFilledMandatoriesQuestions && !!userId && IS_SMART_CONSO_LOT2;
  const score = isCustomScore ? product.customScore : product.score;

  let poorNutritionnelValue = 0;
  if (product.informations?.nutritionalScores) {
    Object.keys(product.informations.nutritionalScores).forEach((k) => {
      // @ts-ignore
      const str = JSON.stringify(product.informations.nutritionalScores?.[k]);
      poorNutritionnelValue += str.includes('"bad"') ? 1 : 0;
    });
  }

  const currentOffer =
    offer ?? product.offers?.find((o) => o.itemId === product.id);
  const {
    productCategory,
    productSubCategory,
    productSubSubCategory
  } = getCategoriesName({
    universId: product.universId?.toString(),
    famillyId: product.famillyId?.toString(),
    departmentId: product.departmentId?.toString(),
    productType: product?.type
  });

  const data = {
    list_name: path.split('/').join('::'),
    product_id: product.id,
    product_name: slugify(product.informations.title),
    product_brand: slugify(product.informations.brand ?? ''),
    product_type: product.type === 'PDV' ? 'classique' : 'marketplace',
    product_category: productCategory,
    product_sub_category: productSubCategory,
    product_sub_sub_category: productSubSubCategory,
    product_price: product.prices.productPrice?.value.toString(),
    product_quantity: quantity || 1,
    product_seller_name: currentOffer?.seller.name,
    product_offer_number: product.offers?.length.toString(),
    // product_add_to_basket_device: 'desktop::site', // temprory desactivate
    // product_add_to_basket_page_context: path.split('/')[0], // temprory desactivate
    // product_promo_type: getPromoTypeOrCategory(product, offer, 'type'), //FAUX respecte pas le PDM : a revoir avec PO/PM
    product_promo_mechanics: getPromoTypeOrCategory(product, offer, 'type'),
    product_availability: product.stock >= 1 ? 1 : 0,
    product_repairability_index: product.repairIndex?.value,
    product_energy_class: product.energyClass,
    product_score_innit: score?.default,
    product_score_innit_type: isCustomScore ? '1' : '0',
    product_labels:
      score?.qualifications &&
      score?.avoidables &&
      `${score.qualifications.length}::${score.avoidables.length}`,
    product_additives_to_avoid:
      score?.additives &&
      `${score.additives.length}::${
        score.additives.filter((add: any) => add?.level?.key === 'high')?.length
      }`,
    product_poor_nutritional_value: `mauvais=${poorNutritionnelValue}`
    /* product_statut: '',
    product_reco_type: '' */
  };

  if (product?.type === 'MKP') {
    const { reviewsCount = '' } = currentOffer?.seller.rating || {};
    return {
      ...data,
      product_seller_ranking: currentOffer?.seller?.rating?.rate.toString(),
      product_seller_rating_number: reviewsCount.toString()
    };
  }

  return data;
};
