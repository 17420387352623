import { AxiosInstance, AxiosResponse } from 'axios';
import { DO_LOGOUT } from '@/store/auth/authActions';
import { store } from '@/store';

const isNode = typeof window === 'undefined';

const OAuthMiddleware = (axios: AxiosInstance) => {
  /* eslint-disable no-param-reassign */
  axios.defaults.headers = {
    ...axios.defaults.headers,
    'x-oauth': true
  };

  if (!isNode) {
    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          store.dispatch({
            type: DO_LOGOUT
          });
        }

        return Promise.reject(error);
      }
    );
  }
};

export default OAuthMiddleware;
