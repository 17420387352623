import Product from '@/lib/model/product';
import { slugify } from '@/lib/utils/stringUtils';
import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const product = action.payload.product as Product;

  return {
    ...baseData,
    page_type: 'produit',
    page_name: 'fiche produit',
    page_virtual_path: `/courses/fiche_produit/${slugify(
      product?.informations?.title
    )}`,
    page_brand: product?.informations?.brand ?? '',
    page_score_innit: product?.score ? 1 : 0
  };
};

export default getData;
