import { UPDATE_MAIN_CATEGORIES } from '@/store/nav/navActions';
import CategoriesApi from '@/lib/api/categories';
import dayjs from 'dayjs';

export const getMainCategories = (pdvRef: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      const fullCategories = await CategoriesApi.getNavByPdv(pdvRef, 3);
      dispatch({
        type: UPDATE_MAIN_CATEGORIES,
        payload: {
          pdvRef,
          categories: {
            data: fullCategories?.tree,
            updatedAt: dayjs()
          }
        }
      });
    } catch (error: any) {
      // Silent error
    }
  };
};
