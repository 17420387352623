import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import analyticsMiddleware from '@/lib/analytics';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import { SurveyState } from './survey/types';
import { AuthStateType } from './auth/authReducer';
import { NewUserLocal } from './register/types';
import { NavStateType } from './nav/navReducer';
import { StateType as CartStateType } from './cart/types';
import { CommonStateType } from './common/commonReducer';
import { CardCreationStateType } from './cardCreation/reducer';

// TODO : State used in order to type the reducer,
// need to be enhanced with the other stores
export interface GlobalState {
  survey: SurveyState;
  auth: AuthStateType;
  register: NewUserLocal;
  nav: NavStateType;
  cart: CartStateType;
  common: CommonStateType;
  cardCreation: CardCreationStateType;
}

const isClient = typeof window !== 'undefined';

const middlewares = [analyticsMiddleware, thunkMiddleware];

let composeEnhancers = compose(applyMiddleware(...middlewares));

if (process.env.NODE_ENV === 'development' && typeof window != 'undefined') {
  composeEnhancers = composeWithDevTools(applyMiddleware(...middlewares));
}

export const store = createStore(rootReducer, composeEnhancers);

export const persistor = isClient ? persistStore(store) : null;
