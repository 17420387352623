import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import Product from '@/lib/model/product';
import { CartType, EventCart } from '@/store/cart/types';
import NormalizerLegacyVersion from './normalizer/normalizerLegacyVersion';
import NormalizerNewVersion from './normalizer/normalizerNewVersion';
import { NormalizerInterface } from './normalizer';

const normalizers: { [key: string]: NormalizerInterface } = {
  legacyVersion: NormalizerLegacyVersion,
  newVersion: NormalizerNewVersion
};

export type PostCartBody = {
  dateClient: Date;
  events: Array<EventCart>;
  lastSynchronizedPanier: {
    montantPanierTotal: number;
    products: Array<Product>;
  };
};

class CartApi {
  axios: AxiosInstance;

  apiVersion: string;

  constructor() {
    this.axios = createAxios(
      {
        timeout: 10000
      },
      ['redApi', 'optionalOAuth']
    );

    this.apiVersion = 'newVersion';
  }

  async updateCart(
    pdv: string,
    cart: CartType,
    allowValorisation: boolean,
    userId: string,
    firstConnectedSynchro: boolean
  ): Promise<CartType> {
    let response;
    const params: any = {};
    if (allowValorisation) {
      params.actions = 'VALUATION,ANIMATIONS';
    }
    if (userId) {
      params.customerId = userId;
    }
    try {
      response = await this.axios.post(
        `/panier/v1/stores/${pdv}/carts`,
        normalizers[this.apiVersion].normalize(
          cart,
          !!userId,
          firstConnectedSynchro
        ),
        { params }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to update cart',
        error
      });

      throw new Error(error);
    }
    return normalizers[this.apiVersion].denormalize(response.data);
  }

  async deleteCart(userId: string, sellerId?: string): Promise<void> {
    const params: any = {};
    params.sellerId = sellerId;
    try {
      return await this.axios.delete(`/panier/v1/customers/${userId}/carts`, {
        params
      });
    } catch (error: any) {
      logger.error({
        message: 'Unable to delete cart',
        error
      });

      throw new Error(error);
    }
  }
}

export default new CartApi();
