import { getPdvRef } from '@/lib/hooks/usePdv';
import { useDispatch, useSelector } from 'react-redux';
import userInfoApi from '@/lib/api/userInformation';
import DataLayerApi from '@/lib/api/dataLayer';
import { AuthStateType, AuthUserStateType } from '@/store/auth/authReducer';
import { Informations as UserInformations } from '@/lib/api/userInformation/types';
import { useState } from 'react';

type Data = { [key: string]: any };

declare global {
  interface Window {
    didomiOnReady: any;
  }
}

class PageView {
  dispatch: any;

  user?: AuthUserStateType;

  ref?: string;

  constructor(dispatch: any, user?: AuthUserStateType, ref?: string) {
    this.dispatch = dispatch;
    this.user = user;
    this.ref = ref;
  }

  async send(name: string, data: Data = {}) {
    if (typeof window === 'undefined') return;

    let userInfo: UserInformations | null = null;
    let dataLayer: any | null = null;

    if (this.user?.id) {
      try {
        userInfo = await userInfoApi.getInformations(this.user.id);
      } catch (error: any) {
        userInfo = null;
      }
    }

    if (this.ref && this.user?.id) {
      try {
        dataLayer = await DataLayerApi.getInfos(this.ref, this.user.id);
      } catch (error: any) {
        dataLayer = null;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(() => {
      self.dispatch({
        type: `pageview_${name}`,
        payload: {
          ...data,
          user: userInfo,
          dataLayer
        }
      });
    });
  }
}

const usePageView = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }: { auth: AuthStateType }) => auth.user);

  let pdvRef: string | undefined;

  try {
    pdvRef = getPdvRef();
  } catch (error: any) {
    pdvRef = undefined;
  }

  const [pageView] = useState(new PageView(dispatch, user, pdvRef));

  return pageView;
};

export default usePageView;
