import { Informations as UserInformations } from '@/lib/api/userInformation/types';
import { createHash } from 'crypto';
import MAPPINGS from './mappings';

const getData = (action: any, state: any) => {
  const typeTemp = action.type.replace('pageview_', '');
  const mappingData = MAPPINGS[typeTemp] ?? {};
  const { dataLayer } = action.payload;

  let analyticsPdv: typeof ANALYTICS_PDV;

  try {
    analyticsPdv = ANALYTICS_PDV;
  } catch (error: any) {
    analyticsPdv = {};
  }

  const user = action.payload.user as UserInformations | null;

  const userOptMapping = () => {
    const optTab = [];
    user?.optOut.personalCommunication && optTab.push('com');
    user?.optOut.personalNavigation && optTab.push('nav');
    user?.optOut.usePersonalDataForAds && optTab.push('pub');
    return optTab.length > 0 ? optTab.join('::') : '';
  };

  return {
    hitType: 'pageview',
    ...mappingData,
    ...analyticsPdv,

    // Part of Store variable are preset /analytics/esi/pdvESI.tsx

    store_next_slots: dataLayer?.store_next_slots ?? '',
    store_area_segmentation: dataLayer?.store_area_segmentation ?? '',
    store_minimum_order_values: dataLayer?.store_minimum_order_values ?? '',
    store_online_payment_services:
      dataLayer?.store_online_payment_services ?? '',

    user_account_type: dataLayer?.user_account_type ?? '',
    user_card_holder: dataLayer?.user_card_holder ?? '',
    user_cmty_mem: dataLayer?.user_cmty_mem ?? '',
    user_contactchan_opt: dataLayer?.user_contact_channel_optin ?? '',
    user_device: 'desktop',
    user_ecustomer_stat: dataLayer?.user_ecustomer_stat ?? '',
    user_email_md5: user?.email
      ? createHash('md5').update(user.email).digest('hex')
      : '',
    user_email_sha256: user?.email
      ? createHash('sha256').update(user.email).digest('hex')
      : '',
    user_fid_promo_level: dataLayer?.user_fid_promo_level ?? '',
    user_fid_store_id_itm: user?.pdvFidelity ?? '',
    user_id: user?.id ?? '',
    user_id_RCU: dataLayer?.rcu_id ?? '',
    user_id_RCU_sha256: dataLayer?.user_id_rcu_sha256 ?? '',
    user_id_sha256: dataLayer?.user_id_sha256 ?? '',
    user_id_web: dataLayer?.user_id_web || '',
    user_internal_trafic: '',
    user_last_order_date: dataLayer?.user_last_order_date ?? '',
    user_last_order_serv: dataLayer?.user_last_order_serv ?? '',
    user_last_pymt_serv: dataLayer?.user_last_pymt_serv ?? '',
    user_login: dataLayer?.user_login ?? '',
    user_newsletter_sub: dataLayer?.user_newsletter_subscription ?? '',
    user_order_channels: dataLayer?.user_order_channel ?? '',
    user_personnalisation_opt: userOptMapping()
  };
};

export default getData;
