import { UPDATE_NAV_PROMOTIONS } from '@/store/nav/navActions';
import ProductApi from '@/lib/api/product';
import dayjs from 'dayjs';
import flatMap from 'lodash/flatMap';

export const getNavPromotions = (pdvRef: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      const galeriesFromAPI = await ProductApi.getGaleries(pdvRef, 'BOUTIQUES');

      // Extract "Boutiques" from API response.
      const boutiques = flatMap(
        galeriesFromAPI,
        (galerie) => galerie.boutiques
      ).filter((boutique) => boutique);

      dispatch({
        type: UPDATE_NAV_PROMOTIONS,
        payload: {
          pdvRef,
          promotions: {
            data: boutiques,
            updatedAt: dayjs()
          }
        }
      });
    } catch (error: any) {
      // Silent error
    }
  };
};
