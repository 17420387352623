import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const type = action.payload.type as 'openLayer' | 'storeDetail';
  const { pdvInfo } = action.payload;
  let data = {};

  switch (type) {
    case 'openLayer':
      data = {
        e_action: 'fiche pdv'
      };
      break;
    case 'storeDetail':
      data = {
        e_action: 'voir fiche pdv'
      };
      break;
  }

  return {
    ...baseData,
    ...data,
    e_category: 'information pdv',
    e_label: pdvInfo
  };
};

export default getData;
