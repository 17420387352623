import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import ShoppingListApi from '@/lib/api/shoppingList';
import { UPDATE_LIST } from '@/store/list/listActions';

const ShoppingList = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }: { auth: AuthStateType }) => {
    return {
      user: auth.user
    };
  });

  useEffect(() => {
    (async () => {
      if (!user?.id) {
        return;
      }

      if (
        window.location.pathname.match(
          /\/(commande|gestion-de-compte|connexion|prehome).*/
        )
      ) {
        return;
      }

      const list = await ShoppingListApi.list(user.id);

      dispatch({
        type: UPDATE_LIST,
        payload: {
          list,
          selected: null
        }
      });
    })();
  }, [user?.id, dispatch]);

  return <></>;
};

export default ShoppingList;
