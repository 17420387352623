/* eslint  class-methods-use-this: off */

import {
  CartType,
  SubCartType,
  EventCart,
  CollectorItemType,
  Animation
} from '@/store/cart/types';
import ProductModel from '@/lib/model/product';
import createProducts from '@/lib/utils/products';
import Date from '@/lib/utils/date';
import {
  EventCart as ApiEventCart,
  CartType as ApiCartType,
  DetailPaniers,
  LastSynchronizedMetaData,
  CollectorItemApiType,
  ApiAnimationType,
  ApiAnimationsImageType,
  ApiAnimationsActionsType
} from './types';
import { NormalizerInterface } from '..';

class Normalizer implements NormalizerInterface {
  normalize(
    cart: CartType,
    isAuthentificated: boolean,
    firstConnectedSynchro: boolean
  ): ApiCartType {
    const pdvCart = cart.subCarts.find((subCart) => {
      return subCart.type === 'PDV';
    });

    const initLastSynchronizedPanier = {
      products: [],
      montantPanierTotal: pdvCart?.total ?? 0
    };

    const data: ApiCartType = {
      dateClient: Date().format('YYYY-MM-DDTHH:mm:ssZ'),
      events: [],
      lastSynchronizedPanier: initLastSynchronizedPanier
    };

    if (pdvCart) {
      const collectors: Array<CollectorItemApiType> =
        pdvCart.valorisation?.collectors?.map((item) => {
          return {
            idCollector: item.id,
            nbVignette: item.quantity,
            pictoCollector: item.picto,
            libelleVignette: item.unit
          };
        }) || [];

      data.lastSynchronizedPanier =
        isAuthentificated && !firstConnectedSynchro
          ? {
              ...data.lastSynchronizedPanier,
              accepteSubstitution: pdvCart.acceptSubstitution,
              animations: pdvCart.animations,
              products: createProducts(pdvCart),
              metaData: {
                invalid: {
                  avantage: pdvCart.meta.invalidItems.advantages,
                  indisponible: pdvCart.meta.invalidItems.unavailables,
                  stock: pdvCart.meta.invalidItems.stocks,
                  partialStocks: pdvCart.meta.invalidItems.partialStocks
                },
                valid: pdvCart.meta.validItems
              },
              detailPaniers: {
                pdv: {
                  estimationNombreBacs: pdvCart.numberOfBags,
                  montantTotalPanier: pdvCart.total,
                  nombreArticles: pdvCart.qty,
                  poidsArticles: pdvCart.itemsWeight,
                  valorisation: {
                    collectors,
                    statutCalculFID: pdvCart.valorisation.collectorsStatus,
                    montantTotalBRI: pdvCart.valorisation.immediateDiscount,
                    statutCalculBRI:
                      pdvCart.valorisation.immediateDiscountStatus,
                    montantTotalFID: pdvCart.valorisation.deferredDiscount,
                    statutCalculCollectors:
                      pdvCart.valorisation.deferredDiscountStatus
                  },
                  volumeLiquide: pdvCart.liquidVolume
                }
              },
              valorisation: {
                collectors,
                statutCalculFID: pdvCart.valorisation.collectorsStatus,
                montantTotalBRI: pdvCart.valorisation.immediateDiscount,
                statutCalculBRI: pdvCart.valorisation.immediateDiscountStatus,
                montantTotalFID: pdvCart.valorisation.deferredDiscount,
                statutCalculCollectors:
                  pdvCart.valorisation.deferredDiscountStatus
              },
              lastSynchronizedDate: cart.synchronizedAt // ?? Date().format('YYYY-MM-DDTHH:mm:ssZ')
            }
          : initLastSynchronizedPanier;
    }

    cart.events.forEach((event: EventCart) => {
      let eventCart: ApiEventCart = {
        modificationDate: event.date,
        type: event.type
      };

      switch (event.type) {
        case 'QUANTITY':
          eventCart = {
            ...eventCart,
            productId: event.productEan,
            privateData: event.nfLotId
              ? `{"nfProductId":${event.productId}, "nfLotId":${event.nfLotId}}`
              : `{"nfProductId":${event.productId}}`,
            quantity: event.quantity
          };
          break;
        case 'COMMENTAIRE':
          eventCart = {
            ...eventCart,
            productId: event.productEan,
            privateData: `{"nfProductId":${event.productId}}`,
            commentaire: event.comment
          };
          break;
        case 'SUBSTITUTION_PRODUIT':
          eventCart = {
            ...eventCart,
            productId: event.productEan,
            privateData: `{"nfProductId":${event.productId}}`,
            accepteSubstitution: event.acceptSubstitution
          };
          break;
        case 'SUBSTITUTION_PANIER':
          eventCart = {
            ...eventCart,
            accepteSubstitution: event.acceptSubstitution
          };
          break;
      }

      data.events.push(eventCart);
    });

    return data;
  }

  denormalize(data: {
    id?: string;
    isPresentAlcoholProduct: boolean;
    products: any;
    animations: [];
    montantPanierTotal: number;
    detailPaniers: DetailPaniers;
    metaData: LastSynchronizedMetaData;
    accepteSubstitution: boolean;
    lastSynchronizedDate: string;
    valorisation: {
      montantTotalBRI: number;
      montantTotalFID: number;
    };
  }): CartType {
    const {
      id,
      metaData,
      animations,
      products,
      montantPanierTotal,
      accepteSubstitution,
      isPresentAlcoholProduct,
      detailPaniers,
      lastSynchronizedDate,
      valorisation
    } = data;

    const hasAlcoholProduct = isPresentAlcoholProduct;
    const basketId = id;

    const detailPanierType = detailPaniers.pdv;
    const collectors: Array<CollectorItemType> =
      detailPanierType.valorisation?.collectors?.map((ct) => {
        return {
          id: ct.idCollector,
          quantity: ct.nbVignette,
          picto: ct.pictoCollector,
          unit: ct.libelleVignette
        };
      }) || [];

    const normalizeAnimationsSubCart = (
      animationsApiData: Array<ApiAnimationType>
    ): Array<Animation> =>
      animationsApiData.map((animationApiData: ApiAnimationType) => ({
        title: animationApiData.titre,
        texte: animationApiData.texte,
        order: animationApiData.ordre,
        footer: animationApiData.footer,
        images: animationApiData.images.map(
          (image: ApiAnimationsImageType) => ({
            url: image.url,
            type: image.type
          })
        ),
        actions: animationApiData.actions.map(
          (action: ApiAnimationsActionsType) => ({
            typeAction: action.typeAction,
            redirectUrl: action.valeur,
            protcolRedirect: action.type
          })
        ),
        type: animationApiData.type,
        id: animationApiData.identifiant,
        backColor: animationApiData.couleurFond
      }));

    const newSubCarts = products.reduce(
      (subCarts: Array<SubCartType>, item: any) => {
        const sub = subCarts?.find(
          (s: SubCartType) => s.type === item.product.catalog
        );
        const productComputed = new ProductModel(item.product);
        if (!sub) {
          /* eslint-disable no-param-reassign */
          subCarts = [
            ...subCarts,
            {
              type: productComputed.type,
              total: detailPanierType.montantTotalPanier,
              qty: detailPanierType.nombreArticles,
              valorisation: {
                collectors,
                collectorsStatus:
                  detailPanierType.valorisation.statutCalculCollectors,
                immediateDiscountStatus:
                  detailPanierType.valorisation.statutCalculBRI,
                deferredDiscountStatus:
                  detailPanierType.valorisation.statutCalculFID,
                immediateDiscount:
                  detailPanierType.valorisation.montantTotalBRI,
                deferredDiscount: detailPanierType.valorisation.montantTotalFID
              },
              itemsWeight: detailPanierType.poidsArticles,
              numberOfBags: detailPanierType.estimationNombreBacs,
              liquidVolume: detailPanierType.volumeLiquide,
              meta: {
                validItems: metaData.valid,
                invalidItems: {
                  advantages: metaData.invalid.avantage,
                  unavailables: metaData.invalid.indisponible,
                  stocks: metaData.invalid.stock,
                  partialStocks: metaData.invalid?.partialStocks || []
                }
              },
              acceptSubstitution: accepteSubstitution,
              animations: normalizeAnimationsSubCart(animations),
              items: {
                [productComputed.id]: {
                  acceptSubstitution: item.accepteSubstitution,
                  product: productComputed,
                  qty: item.quantity,
                  total: item.montant,
                  comment: item.commentaire
                }
              },
              seller: {
                sellerId: 'ITM',
                sellerName: 'Intermarché'
              }
            }
          ];
        } else {
          sub.items[productComputed.id] = {
            acceptSubstitution: item.accepteSubstitution,
            product: productComputed,
            qty: item.quantity,
            total: item.montant,
            comment: item.commentaire
          };
        }

        return subCarts;
      },
      []
    );

    return {
      basketId,
      hasAlcoholProduct,
      selectedFID: 0,
      selectedVoucher: 0,
      slots: {
        list: {},
        vouchersCodeActive: false,
        discountCodeActive: false,
        selectedSlot: {
          maxLiquidVolume: 0,
          slot: null,
          deliveryType: '',
          availablePayments: {},
          volumeCosts: 0,
          housingCosts: 0
        },
        selectedProvider: null
      },
      trackingByPhone: false,
      eventsBins: [],
      subCarts: newSubCarts,
      total: montantPanierTotal,
      qty: detailPanierType.nombreArticles,
      events: [],
      synchronizedAt: Date(lastSynchronizedDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      immediateDiscount: valorisation?.montantTotalBRI,
      deferredDiscount: valorisation?.montantTotalFID
    };
  }
}

export default new Normalizer();
