import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const pdvRef = action.payload.pdvRef as string;

  return {
    ...baseData,
    page_type: 'magasin',
    page_name: 'fiche magasin',
    page_virtual_path: `/stores/fiche_magasin/${pdvRef}`
  };
};

export default getData;
