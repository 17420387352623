export type MappingType = {
  page_type: string;
  page_name: string;
  page_virtual_path: string;
};

const MAPPINGS: { [key: string]: MappingType } = {
  prehome: {
    page_type: 'store selector',
    page_name: 'prehome',
    page_virtual_path: '/home'
  },
  allUniverses: {
    page_type: 'rayons',
    page_name: 'tous les univers',
    page_virtual_path: '/courses/rayons/tous_les_univers'
  },
  connexion: {
    page_type: 'identification',
    page_name: 'se connecter',
    page_virtual_path: '/se_connecter'
  },
  emailChangeConfirmation: {
    page_type: 'identification',
    page_name: 'changement email',
    page_virtual_path: '/home/email_change_confirmation'
  },
  passwordForgotten: {
    page_type: 'identification',
    page_name: 'mot de passe oublie',
    page_virtual_path: '/home/forgotten_password'
  },
  changePassword: {
    page_type: 'identification',
    page_name: 'mot de passe oublie',
    page_virtual_path: '/home/renew_password'
  },
  driveCatalogue: {
    page_type: 'catalogue drive',
    page_name: 'catalogue drive',
    page_virtual_path: '/drive-catalogue'
  },
  savMail: {
    page_type: 'infos',
    page_name: 'contact mail',
    page_virtual_path: '/infos/contact/mail'
  },
  productRecall: {
    page_type: 'infos',
    page_name: 'rappel produit',
    page_virtual_path: '/infos/rappel_produit'
  },
  cgu: {
    page_type: 'infos',
    page_name: 'cgu',
    page_virtual_path: '/infos/cgu'
  },
  contact: {
    page_type: 'infos',
    page_name: 'contact',
    page_virtual_path: '/infos/contact'
  },
  contactLetter: {
    page_type: 'infos',
    page_name: 'contact courrier',
    page_virtual_path: '/infos/contact/courrier'
  },
  contactPhone: {
    page_type: 'infos',
    page_name: 'contact tel',
    page_virtual_path: '/infos/contact/telephone'
  },
  personalData: {
    page_type: 'infos',
    page_name: 'donnees perso',
    page_virtual_path: '/infos/donnees_perso'
  },
  legalMentions: {
    page_type: 'infos',
    page_name: 'mentions legales',
    page_virtual_path: '/infos/mentions_legales'
  },
  contactForm: {
    page_type: 'infos',
    page_name: 'contact formulaire',
    page_virtual_path: '/infos/contact/contactez_nous'
  },
  miniCart: {
    page_type: 'cart',
    page_name: 'mini cart',
    page_virtual_path: '/orders/cart/mini_cart'
  },
  cart: {
    page_type: 'cart',
    page_name: 'my cart',
    page_virtual_path: '/orders/cart/mon_panier'
  },
  ClearingMiniCart: {
    page_type: 'panier',
    page_name: 'mini panier supprimer panier',
    page_virtual_path: '/orders/cart/mini_panier/supprimer_panier'
  },
  ClearingCart: {
    page_type: 'panier',
    page_name: 'mon panier supprimer panier',
    page_virtual_path: '/orders/cart/mon_panier/supprimer_panier'
  },
  slots: {
    page_type: 'slots',
    page_name: 'slots',
    page_virtual_path: '/orders/slots'
  },
  slotSelectAddress: {
    page_type: 'slots',
    page_name: 'slots selectionner adresse livraison',
    page_virtual_path: '/commandes/creneau/selectionner_adresse_livraison'
  },
  slotModifyAddress: {
    page_type: 'slots',
    page_name: 'creneau modifier adresse',
    page_virtual_path: '/commandes/creneau/modifier_adresse_livraison'
  },
  slotAddAddress: {
    page_type: 'slots',
    page_name: 'creneau ajouter adresse livraison',
    page_virtual_path: '/commandes/creneau/ajouter_adresse_livraison'
  },
  onlinePayment: {
    page_type: 'payment',
    page_name: 'payment',
    page_virtual_path: '/orders/online_payment'
  },
  onlinePaymentApp: {
    page_type: 'payment',
    page_name: 'payment',
    page_virtual_path: '/commandes/payment_en_ligne_app'
  },
  payment: {
    page_type: 'payment',
    page_name: 'payment',
    page_virtual_path: '/commandes/payment'
  },
  paymentValidation: {
    page_type: 'payment',
    page_name: 'payment',
    page_virtual_path: '/commandes/payment_en_ligne/validation'
  },
  paymentError: {
    page_type: 'payment',
    page_name: 'payment erreur',
    page_virtual_path: '/commandes/payment/erreur'
  },
  SlotIssue: {
    page_type: 'payment',
    page_name: 'payment erreur',
    page_virtual_path: '/orders/payment/unavailable_slot'
  },
  accountCreationIdentification: {
    page_type: 'creation compte',
    page_name: 'identifiants',
    page_virtual_path: '/compte/creation_compte/identifiants'
  },
  accountCreationPersonalData: {
    page_type: 'creation compte',
    page_name: 'infos perso',
    page_virtual_path: '/compte/creation_compte/infos_perso'
  },
  accountCreationAddress: {
    page_type: 'creation compte',
    page_name: 'adresse',
    page_virtual_path: '/compte/creation_compte/adresse'
  },
  accountCreationFidelity: {
    page_type: 'creation compte',
    page_name: 'carte fid',
    page_virtual_path: '/compte/creation_compte/carte_fid'
  },
  accountActivation: {
    page_type: 'creation compte',
    page_name: 'activation',
    page_virtual_path: '/compte/creation_compte/activation'
  },
  accountCreationConfirmation: {
    page_type: 'creation compte',
    page_name: 'confirmation',
    page_virtual_path: '/compte/creation_compte/confirmation'
  },
  CGUFidelityCreation: {
    page_type: 'creation compte',
    page_name: 'cgu carte fid',
    page_virtual_path: '/compte/creation_compte/cgu_carte_fid'
  },
  livraison: {
    page_type: 'livraison',
    page_name: 'livraison choisir prestataire',
    page_virtual_path: '/commandes/livraison/choisir_prestataire'
  },
  partnerDelivery: {
    page_type: 'livraison',
    page_name: 'livraison partenaire choisir prestataire',
    page_virtual_path: '/commandes/livraison/choisir_prestataire'
  },
  confirmOrder: {
    page_type: 'confirmation',
    page_name: 'confirmation',
    page_virtual_path: '/commandes/confirmation'
  },
  myFidelity: {
    page_type: 'mon compte',
    page_name: 'ma fidélité',
    page_virtual_path: '/compte/mon_compte/ma_fidelite'
  },
  programFidelity: {
    page_type: 'avantages fid',
    page_name: 'mon programme fidelite',
    page_virtual_path: '/compte/mon_compte/ma_fidelite/mon_programme_fidelite'
  },
  myAddresses: {
    page_type: 'mon compte',
    page_name: 'mes adresses',
    page_virtual_path: '/compte/mon_compte/mes_adresses'
  },
  myAccountAddAddress: {
    page_type: 'mon compte',
    page_name: 'ajouter adresse',
    page_virtual_path: '/compte/mon_compte/ajouter_adresse'
  },
  myAccountModifyAddress: {
    page_type: 'mon compte',
    page_name: 'modifier adresse',
    page_virtual_path: '/compte/mon_compte/modifier_adresse'
  },
  myVouchers: {
    page_type: 'mon compte',
    page_name: "mes bons d'achats",
    page_virtual_path: '/compte/mon_compte/mes_bons_dachats'
  },
  orders: {
    page_type: 'mon compte',
    page_name: 'mes commandes en ligne',
    page_virtual_path: '/compte/mon_compte/mes_commandes_en_ligne'
  },
  orderDetail: {
    page_type: 'mon compte',
    page_name: 'mes commandes en ligne details',
    page_virtual_path: '/compte/mon_compte/mes_commandes_en_ligne_details'
  },
  myDetails: {
    page_type: 'mon compte',
    page_name: 'mes coordonnees',
    page_virtual_path: '/compte/mon_compte/mes_coordonnees'
  },
  UserInfo: {
    page_type: 'mon compte',
    page_name: 'mes infos',
    page_virtual_path: '/compte/mon_compte/mes_infos_personnelles'
  },
  MyPersonalData: {
    page_type: 'mon compte',
    page_name: 'mes donnees perso',
    page_virtual_path: '/compte/mon_compte/mes_donnees_perso'
  },
  MyStores: {
    page_type: 'mon compte',
    page_name: 'mes magasins',
    page_virtual_path: '/compte/mon_compte/mes_magasins'
  },
  MyNewsletters: {
    page_type: 'mon compte',
    page_name: 'mes newsletters',
    page_virtual_path: '/compte/mon_compte/mes_newsletters'
  },
  MyLists: {
    page_type: 'mon compte',
    page_name: 'mes listes',
    page_virtual_path: '/compte/mon_compte/mes_listes'
  },
  MyShoppingList: {
    page_type: 'mon compte',
    page_name: 'mes listes de courses',
    page_virtual_path: '/compte/mon_compte/mes_listes_de_courses'
  },
  MyShoppingListDetail: {
    page_type: 'mon compte',
    page_name: 'mes listes de courses detail',
    page_virtual_path: '/compte/mon_compte/mes_listes_de_courses_details'
  },
  MyFrequentShoppingList: {
    page_type: 'mon compte',
    page_name: 'mes produits habituels',
    page_virtual_path: '/compte/mon_compte/mes_produits_habituels'
  },
  MyAccount: {
    page_type: 'mon compte',
    page_name: 'mon espace personnel',
    page_virtual_path: '/compte/mon_compte/mon_espace_personnel'
  },
  MyPaymentsMethods: {
    page_type: 'mon compte',
    page_name: 'moyens de payment',
    page_virtual_path: '/compte/mon_compte/moyens_de_payment'
  },
  ReOrder: {
    page_type: 'mon compte',
    page_name: 'repasser commande disponible',
    page_virtual_path: '/compte/mon_compte/repasser_commande_disponible'
  },
  DeleteMyAccount: {
    page_type: 'mon compte',
    page_name: 'supprimer compte',
    page_virtual_path: '/compte/mon_compte/supprimer_compte'
  },
  MyAccountCardDissociation: {
    page_type: 'mon compte',
    page_name: 'dissocier carte fid',
    page_virtual_path: '/compte/mon_compte/ma_fidelite/dissociation_carte'
  },
  SubmitToNewsletters: {
    page_type: 'mon compte',
    page_name: 'abonnement newsletters',
    page_virtual_path: '/compte/mon_compte/abonnement_newsletters'
  },
  ChangeStore: {
    page_type: 'mon compte',
    page_name: 'changer magasin',
    page_virtual_path: '/compte/mon_compte/changer_magasin'
  },
  RemoveStore: {
    page_type: 'mon compte',
    page_name: 'supprimer magasin',
    page_virtual_path: '/compte/mon_compte/supprimer_magasin'
  },
  CancelOrder: {
    page_type: 'my account',
    page_name: 'cancel my order',
    page_virtual_path: '/orders/payment/cancel'
  },
  RemoveMyFrequentProductsList: {
    page_type: 'mon compte',
    page_name: 'supprimer mes produits habituels',
    page_virtual_path: '/compte/mon_compte/supprimer_mes_produits_habituels'
  },
  MyStickers: {
    page_type: 'vignettes',
    page_name: 'mes vignettes',
    page_virtual_path: '/fid/vignettes/mes_vignettes_full_investi'
  },
  MyStickersError: {
    page_type: 'vignettes',
    page_name: 'mes vignettes erreur',
    page_virtual_path: '/fid/vignettes/mes_vignettes_erreur'
  },
  MyShareStickers: {
    page_type: 'vignettes',
    page_name: 'vignettes',
    page_virtual_path: '/fid/vignettes/partage'
  },
  MyShareStickersSuccess: {
    page_type: 'vignettes',
    page_name: 'pop_in',
    page_virtual_path: '/fid/vignettes/partage_succes'
  },
  MyShareStickersError: {
    page_type: 'vignettes',
    page_name: 'pop_in',
    page_virtual_path: '/fid/vignettes/partage_erreur'
  },
  FidelityAdvantages: {
    page_type: 'avantages fid',
    page_name: 'avantages programme',
    page_virtual_path: '/fid/avantages_fid/avantages_programme'
  },
  FidelityPersonnalOffer: {
    page_type: 'avantages fid',
    page_name: 'mat',
    page_virtual_path: '/fid/avantages_fid/mat'
  },
  FidelityQuizzes: {
    page_type: 'avantages fid',
    page_name: 'jeux concours',
    page_virtual_path: '/fid/avantages_fid/jeux_concours'
  },
  FidelityCommunity: {
    page_type: 'avantages fid',
    page_name: 'toutes les communautes',
    page_virtual_path: '/fid/avantages_fid/toutes_les_communautes'
  },
  FidelityCreation_CGU: {
    page_type: 'creation carte fid',
    page_name: 'cgu carte fid',
    page_virtual_path: '/fid/creation_carte_fid/cgu_carte_fid'
  },
  FidelityCreation_ERROR: {
    page_type: 'creation carte fid',
    page_name: 'echec',
    page_virtual_path: '/fid/creation_carte_fid/echec'
  },
  FidelityCreation_SUCCESS: {
    page_type: 'creation carte fid',
    page_name: 'confirmation',
    page_virtual_path: '/fid/creation_carte_fid/confirmation'
  },
  FidelityCreation_USER_IS_PRO: {
    page_type: 'creation carte fid',
    page_name: 'echec',
    page_virtual_path: '/fid/creation_carte_fid/echec'
  },
  FidelityCreation_IN_PROGRESS: {
    page_type: 'creation carte fid',
    page_name: 'en cours',
    page_virtual_path: '/fid/creation_carte_fid/en_cours'
  },
  FidelityCreation_MISSING_USER_INFOS: {
    page_type: 'creation carte fid',
    page_name: 'infos perso incompletes',
    page_virtual_path: '/fid/creation_carte_fid/infos_perso_incompletes'
  },
  FidelityCreation_TIMEOUT: {
    page_type: 'creation carte fid',
    page_name: 'magasin ko',
    page_virtual_path: '/fid/creation_carte_fid/magasin_ko'
  },
  AttachFidCard: {
    page_type: 'rattachement carte fid',
    page_name: 'carte fid',
    page_virtual_path: '/fid/rattachement_carte_fid/carte_fid'
  },
  AttachFidCardCGU: {
    page_type: 'rattachement carte fid',
    page_name: 'cgu carte fid',
    page_virtual_path: '/fid/rattachement_carte_fid/cgu_carte_fid'
  },
  ModifyAttachFidInfo: {
    page_type: 'rattachement carte fid',
    page_name: 'modifier infos',
    page_virtual_path: '/fid/rattachement_carte_fid/modifier_infos'
  },
  AttachFidCardError: {
    page_type: 'rattachement carte fid',
    page_name: 'echec',
    page_virtual_path: '/fid/rattachement_carte_fid/echec'
  },
  AttachFidCardSuccess: {
    page_type: 'activation carte fid',
    page_name: 'carte fid active',
    page_virtual_path: '/fid/activation_carte_fid/carte_fid_active'
  },
  FidelityCgu: {
    page_type: 'creation carte fid',
    page_name: 'cgu carte fid',
    page_virtual_path: '/fid/creation_carte_fid/cgu_carte_fid'
  },
  Additifs: {
    page_type: 'infos',
    page_name: 'additifs produits',
    page_virtual_path: '/infos/produit/additifs'
  },
  ScoreInit: {
    page_type: 'infos',
    page_name: 'score innit',
    page_virtual_path: '/infos/produit/score_innit'
  },
  Nutrition: {
    page_type: 'infos',
    page_name: 'valeurs nutritionnelles',
    page_virtual_path: '/infos/produit/valeurs_nutritionnelles'
  },
  SellerPage: {
    page_type: 'marketplace',
    page_name: 'fiche vendeur',
    page_virtual_path: '/vendeurs_partenaires/id_vendeur'
  },
  OrdersPartnerHistory: {
    page_type: 'mon compte',
    page_name: 'mes commandes partenaires',
    page_virtual_path: '/compte/mon_compte/mes_commandes_partenaires'
  },
  OverlayForgottenProducts: {
    page_type: 'emerch',
    page_name: 'produits oubliés',
    page_virtual_path: '/orders/cart/my_cart/overlay_produits_oublies/'
  },
  ModalConsentSes: {
    page_type: 'conso mieux',
    page_name: 'pop_in optin perso',
    page_virtual_path: '/compte/conso_mieux/optin_perso'
  },
  ModalRefusePerso: {
    page_type: 'conso mieux',
    page_name: 'pop_in refuser perso',
    page_virtual_path: '/compte/conso_mieux/refuser_perso'
  },
  ModalConfirmBack: {
    page_type: 'conso mieux',
    page_name: 'pop_in quitter questionnaire',
    page_virtual_path: '/compte/conso_mieux/quitter_questionnaire'
  },
  ModalReinitSes: {
    page_type: 'conso mieux',
    page_name: 'pop_in effacer réponses',
    page_virtual_path: '/compte/conso_mieux/effacer_reponses'
  },
  FlyMenu: {
    page_type: 'recettes flyMenu',
    page_name: 'page recettes flyMenu',
    page_virtual_path: '/recettes'
  },
  CharteAlcool: {
    page_type: 'infos',
    page_name: 'charte alcool',
    page_virtual_path: '/sign/infos/charte_alcool'
  }
};

export default MAPPINGS;
