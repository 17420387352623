import React from 'react';
import { REHYDRATE } from 'redux-persist';
import {
  Action,
  CleanSurveyAction,
  CLEAN_SURVEY_STORE,
  INIT_SURVEY_STORE,
  UPDATE_SURVEY_ANSWER,
  START_SURVEY,
  PASS_SURVEY_FIRST_STEPS,
  FINISH_SURVEY_STEPS
} from './actions';
import { SurveyState } from './types';

export const initialState: SurveyState = {
  questionnaireId: 0,
  responses: [],
  postReinit: false,
  isHydrated: false,
  hasPassedFirstStep: false,
  withMessage: false,
  finishAllSteps: false
};

function surveyReducer(
  prevState: SurveyState = initialState,
  action: Action
): SurveyState {
  switch (action.type) {
    case UPDATE_SURVEY_ANSWER: {
      const responses = [...prevState.responses];
      const { response } = action.payload;
      const questionIndex = responses.findIndex(
        (res) => res.questionId === response.questionId
      );
      if (questionIndex >= 0) {
        responses[questionIndex] = response;
      } else {
        responses.push(response);
      }

      return {
        ...prevState,
        responses
      };
    }
    case INIT_SURVEY_STORE:
      return {
        ...action.payload.survey,
        hasPassedFirstStep: action.payload.survey?.responses?.length >= 3,
        finishAllSteps: false
      };
    case START_SURVEY:
      return {
        ...prevState,
        postReinit: false,
        finishAllSteps: false
      };
    case PASS_SURVEY_FIRST_STEPS:
      return {
        ...prevState,
        hasPassedFirstStep: true
      };
    case CLEAN_SURVEY_STORE:
      return {
        ...initialState,
        postReinit: true,
        finishAllSteps: false,
        withMessage: action.payload.withMessage
      };
    case FINISH_SURVEY_STEPS:
      return {
        ...prevState,
        finishAllSteps: true
      };
    case REHYDRATE:
      if (action.key === 'survey' && action.payload) {
        return {
          ...prevState,
          isHydrated: action.payload['_persist'].rehydrated
        };
      }
      return {
        ...prevState
      };
    default:
      return prevState;
  }
}

export const actionCleanSurvey = (
  dispatch: React.Dispatch<CleanSurveyAction>
) => (payload: { withMessage: boolean }) => {
  dispatch({
    type: CLEAN_SURVEY_STORE,
    payload
  });
};

export default surveyReducer;
