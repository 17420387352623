import ProductList from '@/lib/model/productList';
import Product from '@/lib/model/product';
import productApi from '@/lib/api/product';
import usePdv from '@/lib/hooks/usePdv';

export const getProductsInfos = async (
  productIds: Array<any>,
  pdvRef = usePdv().ref
): Promise<Array<Product | never>> => {
  /* eslint-disable no-console */
  console.info('*** getProductsInfos ***');
  if (!Array.isArray(productIds) || !pdvRef) {
    throw new Error('getProductsInfos Wrong parameters');
  }
  if (!productIds.length) {
    return [];
  }

  const products: ProductList = await productApi.findByIds(pdvRef, productIds);

  return products.products;
};
