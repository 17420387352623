import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const pageType = action.payload?.pageType || 'mon compte';
  const pageName = action.payload?.pageName || 'conso_mieux';
  const vPath = action.payload?.vPath || '/compte/mon_compte/conso_mieux';

  return {
    ...baseData,
    page_type: pageType,
    page_name: pageName,
    page_virtual_path: vPath
  };
};

export default getData;
