import getConfigValue from '@/lib/config';
import Date from '@/lib/utils/date';
import { slugify } from '../utils/stringUtils';
import { parseToPrice, Price } from './price';
import {
  Informations,
  Offer,
  PromotionType,
  LotType,
  OfferDelivery,
  OfferDeliveryMode,
  OFFER_STATE_MAPPING,
  SmartConso,
  ScoreRatingType
} from './productTypes';

const CDN_HOST = getConfigValue('CDN_HOST').toString();
const CDN_FOR_IMAGES = getConfigValue('CDN_FOR_IMAGES', false).toBoolean();

const computePromotion = (data: any): Array<PromotionType> => {
  if (!data?.avantages) return [];

  const promotions: Array<PromotionType> = data?.avantages?.reduce(
    (acc: Array<PromotionType>, current: any) => {
      const isValid =
        Date() >= Date(current.dateDebut) && Date() <= Date(current.dateFin);
      if (!isValid && data?.catalog?.toLowerCase() === 'pdv') return [...acc];
      let unit;
      let value;
      switch (current.type) {
        case 'DEVISE':
          unit = 'amount';
          value = String(current.remise.toFixed(2)).replace('.', ',');
          break;
        case 'PCT':
          unit = 'percentage';
          value = current.valeur;
          break;
        case 'UNITE':
          unit = 'quantity';
          value = current.valeur;
          break;
      }
      let type;
      switch (current.categorie.toLowerCase()) {
        case 'ri':
          type = 'immediateDiscount';
          break;
        case 'ac':
          type = 'deferredDiscount';
          break;
        case 'lv':
          type = unit === 'quantity' ? 'offeredDiscount' : 'quantityDiscount';
          break;
      }
      if (!type || !unit || !value) return [...acc];

      let quantity;

      switch (type) {
        case 'offeredDiscount':
          quantity = current.quantiteLot - value;
          break;
        default:
          quantity = current.quantiteLot;
          break;
      }

      const c: PromotionType = {
        value,
        type,
        quantity,
        endDate: current.dateFin,
        unit,
        flashSale: !!current.venteFlash,
        discount: String((current.remise || 0).toFixed(2)).replace('.', ',')
      };
      return [...acc, c];
    },
    []
  );
  if (data.vignetteCollector > 0 || data.collectorPartenaire) {
    promotions.push({
      type: 'collectorPartner',
      value: data.vignetteCollector,
      picto: data.pictoCollector,
      unit: data.libelleVignette
    });
  }
  return promotions;
};
class Product {
  type: 'PDV' | 'MKP' = 'PDV';

  id: string;

  ean: string;

  universId: string;

  informations: Informations;

  offers: Array<Offer> = [];

  bestOffer: Offer | null;

  allowSubstituable: boolean;

  itemParentId: string;

  maxQty = 0;

  promotions: Array<PromotionType> = [];

  available: boolean;

  stock: number;

  famillyId: string;

  subFamillyId: string;

  departmentId: string;

  privateData: string;

  description: string;

  prices = {
    unitPrice: null as Price | null,
    productPrice: null as Price | null,
    crossedOutPrice: null as Price | null
  };

  lot?: LotType;

  url: string;

  compatibleConsigne: boolean;

  score?: SmartConso;

  customScore?: SmartConso;

  nutriscore?: string;

  alternativeProducts: Array<Product> = [];

  repairIndex: { id: string; value: number };

  energyClass: string;

  privateCopie?: number;

  tracking?: { code: string; type: string };

  francoScore?: number;

  constructor(data: any) {
    if (data.typeLot) {
      this.lot = {
        type: data.typeLot,
        products:
          data.produitsLot?.map((product: any) => {
            return {
              id: product.idProduit ?? product.produitEan13,
              promotions: computePromotion(product),
              quantity: product.quantite,
              brand: product.marque,
              itemParentId: product.itemParentId,
              itemId: product.itemId,
              productId: product.idProduit ?? product.produitEan13,
              description: product.libelle,
              image: product.images?.[0],
              allImages: product.images,
              price: String(product.prix?.toFixed(2)).replace('.', ','),
              crossPrice: String(product.prixBarre?.toFixed(2)).replace(
                '.',
                ','
              ),
              priceBy: product.prixKg
                ? `${product.prixKg}${product.unitePrixVente.value}`
                : null
            };
          }) ?? []
      };
    }
    this.privateData = data.privateData;
    this.type = data.catalog?.toUpperCase() ?? 'PDV';
    this.id = data.itemId ?? data.idProduit;
    this.ean = data.produitEan13 ?? data.idProduit;
    this.universId = data.idUnivers;
    this.allowSubstituable = data.substituable;
    this.compatibleConsigne = data.compatibleConsigne;
    this.itemParentId = data.itemParentId;
    this.stock = data.stock;
    this.available = data.dispoCataloguePdv;
    this.famillyId = data.idFamille;
    this.subFamillyId = data.idSousFamille;
    this.departmentId = data.idRayon;
    this.description = data.description;
    this.score = data.score;
    const labels = data.customScore?.labels
      ? {
          avoidable: data.customScore.labels?.avoidable,
          desirable: [
            ...(data.customScore.labels?.desirable || []),
            ...(data.customScore.labels?.qualification || [])
          ]
        }
      : undefined;
    this.customScore = data.customScore
      ? {
          ...data.customScore,
          isCustom: true,
          default: data.customScore.innit,
          qualifications: labels?.desirable,
          avoidables: labels?.avoidable
        }
      : undefined;
    this.francoScore = data.francoScore;
    this.alternativeProducts = data.alternativeProducts?.map(
      (product: any) => new Product(product.product)
    );
    this.informations = {
      dynamicFieldInformations: data?.extension?.informations,
      title: data.libelle,
      pvi: data.typeProduit?.code === 'PVI' ? data.pviIncrement : null,
      poidsMinimum: data.typeProduit?.code === 'PVI' ? data.poidsMinimum : null,
      originPlp: data.origine?.trim() !== '-' ? data.origine : null,
      originPdp:
        data.categories?.origine?.origine &&
        data.categories?.origine?.origine?.trim() !== '-'
          ? data.categories.origine.origine
          : null,
      packaging: data.conditionnement,
      hasAlcohol: data.isPresentAlcoholProduct,
      brand: data.marque,
      category: data.categories?.origine?.categorie
        ? data.categories?.origine?.categorie
        : data.categorie,
      variety: data.categories?.origine?.variete,
      species: data.categories?.origine?.espece,
      slaughterCountry: data.categories?.origine?.paysAbattage,
      animalCountryOrigin: data.categories?.origine?.paysNaissance,
      animalCountryGrowing: data.categories?.origine?.paysElevage,
      fishingArea: data.categories?.origine?.zonePeche,
      subFishingArea: data.categories?.origine?.sousZonePeche,
      methodeCapture: data.categories?.origine?.methodeCapture,
      caliber: data.categories?.origine?.calibre,
      chemicalTreatment: data.categories?.origine?.traitementChimique,
      alcoholRate: data.categories?.global?.tauxAlcool,
      conservation: data.categories?.global?.infoConservation,
      ingredients: data.categories?.ingredients?.infoIngredients,
      fabricant: data.categories?.ingredients?.fabricant,
      allergens: data.categories?.ingredients?.allergene,
      practicalInformation: data.categories?.ingredients?.infoPratiques,
      legalInformation: data.categories?.ingredients?.infoLegales,
      advantages: data.categories?.renseignementsPratiques?.infoAvantages,
      emballage: data.categories?.renseignementsPratiques?.emballage,
      countryTransformation: data.categories?.origine?.paysTransform,
      nutriscore: data.categories?.global?.nutriscore,
      flags: [],
      features: null,
      allImages: []
    };
    this.repairIndex = data.repairIndex;
    this.energyClass = data.energyClass;

    this.tracking = data?.tracking;

    const highlight = data.avantages?.find(
      (promo: any) =>
        promo.categorie === 'PROSPECTUS' && Date() <= Date(promo.dateFin)
    );

    if (highlight) {
      this.informations.highlight = {
        endDate: Date(highlight.dateFin)
      };
    }

    const getOfferDelivery = (delivery: any): OfferDelivery | undefined => {
      if (!delivery) {
        return undefined;
      }

      return {
        shippingMode: delivery.shippingMode,
        shippingPrice: parseToPrice(delivery.shippingPrice),
        modes:
          delivery.modes
            ?.map((mode: any) => {
              return {
                code: mode.code,
                label: mode.label
              };
            })
            ?.sort((a: OfferDeliveryMode, b: OfferDeliveryMode) => {
              if (a.code === delivery.shippingMode) return -1;
              if (b.code === delivery.shippingMode) return 1;
              return 0;
            }) || []
      };
    };

    const addInformationsExtension = () => {
      if (data.extension?.informations) {
        this.informations.extension = {
          picto: data.extension?.informations?.picto,
          label: data.extension?.informations?.label,
          sections: {}
        };

        data.extension?.informations?.section?.forEach((section: any) => {
          if (this.informations.extension) {
            this.informations.extension.sections[section.id] = {
              label: section.label,
              value: section.value,
              id: section.id,
              type: section.type
            };
          }
        });
      }
      if (data.extension?.features) {
        this.informations.features = {
          picto: data.extension?.informations?.picto,
          label: data.extension?.informations?.label,
          sections: {}
        };
        data.extension?.features?.section?.forEach((section: any) => {
          if (this.informations.features) {
            this.informations.features.sections[section.id] = {
              label: section.label,
              value: section.value,
              id: section.id,
              type: section.type
            };
          }
        });
        const privateCopie = data.extension?.features?.section?.find(
          (section: any) => section.id === 'taxe_copie_privee'
        );
        this.privateCopie = privateCopie?.value;
      }
    };

    switch (this.type) {
      case 'PDV':
        this.maxQty = Math.min(data.qteMaxPanier, data.stock);
        this.offers = [
          {
            itemId: this.id,
            seller: {
              id: 'ITM',
              name: 'Intermarché'
            },
            bestOffer: true,
            ecoPart: data.ecoparticipationDeee
          }
        ];
        break;
      case 'MKP': {
        const sellectedOffer = Product.findOfferByItemId(
          data.itemId,
          data?.marketplace?.offers || []
        );
        this.maxQty = Math.min(sellectedOffer?.stock, 99);
        this.offers = data?.marketplace?.offers?.map((offer: any) => ({
          itemParentId: offer.itemParentId,
          itemId: offer.itemId,
          seller: {
            id: offer.seller.id,
            name: offer.seller.name,
            rating: {
              rate: offer?.seller?.rating?.rate,
              reviewsCount: offer?.seller?.rating?.reviewsCount
            },
            url: `/partners-sellers/${
              offer?.seller?.url?.split('/')?.reverse()[0]
            }`
          },
          bestOffer: offer.bestOffer,
          promotions: computePromotion(offer),
          state: OFFER_STATE_MAPPING[offer.state?.toLowerCase()],
          price: parseToPrice(offer.price),
          crossedOutPrice: offer.prixBarre && parseToPrice(offer.prixBarre),
          ecoPart: offer?.ecoparticipationDeee,
          delivery: getOfferDelivery(offer.delivery)
        }));
        break;
      }
    }
    addInformationsExtension();

    this.bestOffer = this.getBestOffer() || null;

    if (data.categories?.valeursNutritionnelles) {
      this.informations.nutritionalValues = {
        rate: `100${data.categories?.global?.libelleNutrition}`,
        energy: {
          kj: data.categories?.valeursNutritionnelles?.valEnergieKj,
          kcal: data.categories?.valeursNutritionnelles?.valEnergieKcal
        },
        fat: {
          value: data.categories?.valeursNutritionnelles?.matiereGrasse,
          detail: {
            saturatedFattyAcid:
              data.categories?.valeursNutritionnelles?.matiereGrasseDontSatures,
            monounsaturatedFattyAcid:
              data.categories?.valeursNutritionnelles
                ?.matiereGrasseDontMonoinstature,
            polyunsaturatedFattyAcid:
              data.categories?.valeursNutritionnelles
                ?.matiereGrasseDontPolyinstature
          }
        },
        carbohydrates: {
          value: data.categories?.valeursNutritionnelles?.glucides,
          detail: {
            sugar: data.categories?.valeursNutritionnelles?.glucidesDontSucre,
            polyols:
              data.categories?.valeursNutritionnelles?.glucidesDontPolyols,
            starch: data.categories?.valeursNutritionnelles?.glucidesDontAmidon
          }
        },
        protein: data.categories?.valeursNutritionnelles?.proteines,
        salt: data.categories?.valeursNutritionnelles?.sel,
        dietaryFiber: data.categories?.valeursNutritionnelles?.fibre
      };
    }

    if (data.extension?.nutrition) {
      const scoresNutrionnels = Object.fromEntries(
        data.extension?.nutrition?.section[0]?.groups
          .map(
            ({
              value
            }: {
              value: Array<{
                key: string;
                label: string;
                rating: ScoreRatingType;
                value: string;
              }>;
            }) => value
          )
          .flat()
          .map(({ key, rating }: { key: string; rating: ScoreRatingType }) => [
            key,
            rating
          ])
          .filter(
            ([key, rating]: [string, ScoreRatingType]) => rating !== undefined
          )
      );

      if (Object.keys(scoresNutrionnels).length > 0) {
        this.informations.nutritionalScores = {
          energy: {
            kj: scoresNutrionnels?.valEnergieKj,
            kcal: scoresNutrionnels?.valEnergieKcal
          },
          fat: {
            value: scoresNutrionnels?.matiereGrasse,
            detail: {
              saturatedFattyAcid: scoresNutrionnels?.matiereGrasseDontSatures,
              monounsaturatedFattyAcid:
                scoresNutrionnels?.matiereGrasseDontMonoinstature,
              polyunsaturatedFattyAcid:
                scoresNutrionnels?.matiereGrasseDontPolyinstature
            }
          },
          carbohydrates: {
            value: scoresNutrionnels?.glucides,
            detail: {
              sugar: scoresNutrionnels?.glucidesDontSucre,
              polyols: scoresNutrionnels?.glucidesDontPolyols,
              starch: scoresNutrionnels?.glucidesDontAmidon
            }
          },
          protein: scoresNutrionnels?.proteines,
          salt: scoresNutrionnels?.sel,
          dietaryFiber: scoresNutrionnels?.fibre
        };
      }
    }

    this.url = `/product/${slugify(this.informations.title)}/${
      this.type === 'PDV' ? this.ean : data.idProduit
    }`;

    if (data.prix) {
      this.prices.productPrice = parseToPrice(data.prix);
    }

    if (data.prixBarre) {
      this.prices.crossedOutPrice = parseToPrice(data.prixBarre);
    }

    if (data.unitPrice) {
      this.prices.unitPrice = parseToPrice(
        data.unitPrice,
        data.unitePrixVente?.value
      );
    }

    this.promotions = computePromotion(data);

    if (data.images?.length) {
      data.images?.forEach((element: string) => {
        let src = element;
        if (CDN_FOR_IMAGES && element?.match(/^.*\.intermarche\.com\/.*/)) {
          const url = new URL(element);
          src = CDN_HOST + url.pathname;
        }
        this.informations.allImages?.push({
          src,
          alt: this.informations.title
        });
      });
      this.informations.image = this.informations.allImages
        ? this.informations.allImages[0]
        : undefined;
    }

    if (data.pictogrammes?.length > 0) {
      this.informations.flags = data.pictogrammes.map((elt: any) => elt.image);
    }
  }

  getBestOffer() {
    return this.offers?.find((offer) => offer.bestOffer);
  }

  static findOfferByItemId(itemId: string, offerList: Array<any>): any {
    return offerList?.find((offer: any) => offer.itemId === itemId);
  }
}

export default Product;
