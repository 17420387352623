import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const type = action.payload.type as 'onCancel' | 'onValidate';
  let data = {};

  switch (type) {
    case 'onCancel':
      data = {
        ...data,
        e_action: 'non, merci'
      };
      break;
    case 'onValidate':
      data = {
        ...data,
        e_action: 'valider'
      };
      break;
  }

  return {
    ...baseData,
    ...data,
    e_category: 'produits oubliés'
  };
};

export default getData;
