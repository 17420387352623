import base from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  return {
    ...baseData,
    event: 'generic',
    e_category: 'marketplace',
    e_action: 'affichage fiche vendeur',
    e_label: action.payload.sellerName
  };
};

export default getData;
