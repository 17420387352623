import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const type = action.payload.type as
    | 'login'
    | 'cart'
    | 'categories'
    | 'catalogs'
    | 'home'
    | 'fid';

  let data = {};

  switch (type) {
    case 'home':
      data = {
        e_action: 'logo',
        e_label: '/home'
      };
      break;
    case 'login':
      data = {
        e_action: 'login',
        e_label: '/connection'
      };
      break;
    case 'cart':
      data = {
        e_action: 'basket',
        e_label: '/cart'
      };
      break;
    case 'categories':
      data = {
        e_action: 'rayons',
        e_label: '/rayons'
      };
      break;
    case 'catalogs':
      data = {
        e_action: 'catalagoues',
        e_label: '/catalogues'
      };
      break;
    case 'fid':
      data = {
        e_action: 'avantages fidelité',
        e_label: '/fidelity-advantages'
      };
      break;
  }
  return {
    ...baseData,
    ...data,
    e_category: 'header'
  };
};

export default getData;
