import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import { CHANGE_LAST_VISITED_PDV } from '@/store/auth/authActions';
import usePdv from '@/lib/hooks/usePdv';
import userInformationApi from '@/lib/api/userInformation';

const LastVisitedPdv = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }: { auth: AuthStateType }) => {
    return {
      user: auth.user
    };
  }, shallowEqual);

  useEffect(() => {
    (async () => {
      const pdv = usePdv();
      if (!user?.id) {
        return;
      }
      if (!pdv.isVirtual && pdv.ref !== user?.lastVisitedPdv) {
        await userInformationApi.updateLastVisitedPdv(user.id, pdv.ref);

        dispatch({
          type: CHANGE_LAST_VISITED_PDV,
          payload: pdv.ref
        });
      }
    })();
  }, [user, dispatch]);

  return <></>;
};

export default LastVisitedPdv;
