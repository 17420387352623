/* eslint  class-methods-use-this: off */

import {
  CartType,
  SubCartType,
  EventCart,
  CartItemType,
  CollectorItemType,
  PartialItem
} from '@/store/cart/types';
import ProductModel from '@/lib/model/product';
import Date from '@/lib/utils/date';
import { NormalizerInterface } from '..';
import {
  CartType as ApiCartType,
  EventCart as ApiEventCart,
  LastSynchronizedMetaData,
  LastSynchronizedApiCart,
  LastSynchronizedApiCarts
} from './types';

class Normalizer implements NormalizerInterface {
  normalize(
    cart: CartType,
    isAuthentificated: boolean,
    firstConnectedSynchro: boolean
  ) {
    const customerDateTime = Date().format('YYYY-MM-DDTHH:mm:ssZ');
    const data: ApiCartType = {
      customerDateTime,
      events: []
    };
    const itmCart = cart.subCarts.find(
      (subCart: SubCartType) => subCart.type === 'PDV'
    );

    cart.events.forEach((event: EventCart) => {
      let eventCart: ApiEventCart = {};

      switch (event.type) {
        case 'QUANTITY':
          eventCart = {
            catalog: event.typeCatalog,
            itemId: event.productId,
            quantity: event.quantity,
            dateTime: event.date,
            type: 'QUANTITY'
          };
          if (event.itemParentId) {
            eventCart.itemParentId = event.itemParentId;
          }
          if (itmCart) {
            eventCart.acceptSubstitution = itmCart.acceptSubstitution;
          }
          break;
        case 'COMMENTAIRE':
          eventCart = {
            catalog: event.typeCatalog,
            comment: event.comment,
            dateTime: event.date,
            itemId: event.productId,
            type: 'COMMENT'
          };
          break;
        case 'SUBSTITUTION_PRODUIT':
          eventCart = {
            catalog: event.typeCatalog,
            acceptSubstitution: event.acceptSubstitution,
            dateTime: event.date,
            itemId: event.productId,
            type: 'PRODUCT_SUBSTITUTION'
          };
          break;
        case 'SUBSTITUTION_PANIER':
          eventCart = {
            catalog: event.typeCatalog,
            acceptSubstitution: event.acceptSubstitution,
            dateTime: event.date,
            type: 'CART_SUBSTITUTION'
          };
          break;
      }
      data.events.push(eventCart);
    });

    if (isAuthentificated && !firstConnectedSynchro) {
      const lastSynchronizedCart: LastSynchronizedApiCart = {
        carts: [],
        synchronizeDateTime:
          cart.synchronizedAt || Date().format('YYYY-MM-DDTHH:mm:ssZ')
      };

      cart.subCarts.forEach((subCart) => {
        const lastSynchroCartTemp: LastSynchronizedApiCarts = {
          items: [],
          catalog: subCart.type,
          amount: subCart.total,
          seller: {
            id: subCart.seller.sellerId,
            name: subCart.seller.sellerName
          },
          acceptSubstitution: subCart.acceptSubstitution
        };
        Object.values(subCart.items).forEach((item: CartItemType) => {
          lastSynchroCartTemp.items.push({
            id: item.product.id,
            itemParentId: item.product.itemParentId,
            quantity: item.qty,
            price: item.product.prices.productPrice?.value,
            amount: item.total,
            acceptSubstitution: item.acceptSubstitution,
            comment: item.comment,
            item: {
              itemId: item.product.id,
              itemParentId: item.product.itemParentId,
              idProduit: item.product.id,
              produitEan13: item.product.ean,
              pviIncrement: item.product.informations.pvi,
              prix: item.product.prices.productPrice?.value,
              prixBarre: item.product.prices.crossedOutPrice?.value,
              privateData: item.product.privateData,
              poidsMinimum: item.product.informations.poidsMinimum,
              substituable: item.product.allowSubstituable,
              dispoCataloguePdv: item.product.available,
              stock: item.product.stock,
              catalog: item.product.type,
              libelle: item.product.informations.title,
              compatibleConsigne: item.product.compatibleConsigne,
              marque: item.product.informations.brand,
              conditionnement: item.product.informations.packaging,
              qteMaxPanier: item.product.maxQty,
              isPresentAlcoholProduct: item.product.informations.hasAlcohol
            }
          });
        });
        lastSynchronizedCart.carts.push(lastSynchroCartTemp);
      });
      data.lastSynchronizedCart = lastSynchronizedCart;
    }

    return data;
  }

  denormalize(data: {
    id?: string;
    amount: number;
    animations: [];
    carts: any;
    isPresentAlcoholProduct: boolean;
    itemsNumber: number;
    metaData: LastSynchronizedMetaData;
    synchronizeDateTime: string;
    valuations: {
      discountAmount: number;
      loyaltyAmount: number;
    };
  }): CartType {
    const {
      id,
      animations,
      amount,
      isPresentAlcoholProduct,
      itemsNumber,
      synchronizeDateTime,
      carts,
      valuations
    } = data;

    const newSubCarts: Array<SubCartType> = carts.map((cart: any) => {
      const productsComputed: { [key: string]: CartItemType } = {};
      cart.items.forEach((item: any) => {
        productsComputed[item.id] = {
          acceptSubstitution: item.acceptSubstitution,
          product: new ProductModel(item.item),
          qty: item.quantity,
          total: item.amount,
          comment: item.comment
        };
      });
      const collectors: Array<CollectorItemType> =
        cart.valuations?.collectors?.map(
          (item: {
            id: any;
            thumbnailNumber: any;
            pictoCollector: any;
            thumbnailLabel: any;
          }) => {
            return {
              id: item.id,
              quantity: item.thumbnailNumber,
              picto: item.pictoCollector,
              unit: item.thumbnailLabel
            };
          }
        ) || [];
      return {
        type: cart.catalog,
        acceptSubstitution: cart.acceptSubstitution,
        seller: {
          sellerId: cart.seller.id,
          sellerName: cart.seller.name
        },
        total: cart.amount,
        qty: cart.itemsNumber,
        itemsWeight: null,
        numberOfBags: null,
        valorisation: {
          collectors,
          collectorsStatus: cart.valuations.collectorsCountStatus,
          immediateDiscountStatus: cart.valuations.discountStatus,
          deferredDiscountStatus: cart.valuations.loyaltyStatus,
          immediateDiscount: cart.valuations.discountAmount,
          deferredDiscount: cart.valuations.loyaltyAmount
        },
        liquidVolume: null,
        items: productsComputed,
        meta: {
          validItems: null,
          invalidItems: {
            advantages: cart.metaData?.invalid?.advantages?.map(
              (m: any) => m.itemId
            ),
            unavailables: cart.metaData?.invalid?.unavailables?.map(
              (m: any) => m.itemId
            ),
            stocks: cart.metaData?.invalid?.stocks?.map((m: any) => m.itemId),
            partialStocks: cart.metaData?.invalid?.partialStocks?.map(
              (m: any) => m.itemId
            ),
            partialStocks409: cart.metaData?.invalid?.partialStocks?.map(
              (partialItem: PartialItem) => {
                return {
                  itemId: partialItem.itemId,
                  availableStock: partialItem.availableStock,
                  sellerId: cart.seller.id
                };
              }
            ),
            exceededQuantities: cart.metaData?.invalid?.exceededQuantities?.map(
              (m: any) => m.itemId
            )
          }
        },
        animations
      };
    });

    return {
      basketId: id,
      hasAlcoholProduct: isPresentAlcoholProduct,
      selectedFID: 0,
      selectedVoucher: 0,
      slots: {
        list: {},
        vouchersCodeActive: false,
        discountCodeActive: false,
        selectedSlot: {
          maxLiquidVolume: 0,
          slot: null,
          deliveryType: '',
          availablePayments: {},
          volumeCosts: 0,
          housingCosts: 0
        },
        selectedProvider: null
      },
      mkpPackages: {},
      trackingByPhone: false,
      eventsBins: [],
      subCarts: newSubCarts,
      total: amount,
      qty: itemsNumber,
      events: [],
      synchronizedAt: Date(synchronizeDateTime).format('YYYY-MM-DDTHH:mm:ssZ'),
      immediateDiscount: valuations.discountAmount,
      deferredDiscount: valuations.loyaltyAmount
    };
  }
}

export default new Normalizer();
