import Cookie from 'js-cookie';
import getConfigValue from '@/lib/config';
import Pdv from '@/lib/model/pdv';
import Date from './date';

export const setPdvCookie = (pdv: Pdv) => {
  Cookie.set(
    'itm_pdv',
    JSON.stringify({ ref: pdv.ref, isEcommerce: pdv.isEcommerce() }),
    {
      secure: getConfigValue('COOKIE_SECURE', true).toBoolean(),
      expires: Date().add(3, 'month').toDate()
    }
  );

  // TODO: Remove as soon as CACOM swith to itm_pdv cookie
  Cookie.set('novaParams', JSON.stringify({ pdvRef: pdv.ref }), {
    secure: getConfigValue('COOKIE_SECURE', true).toBoolean(),
    expires: Date().add(3, 'month').toDate()
  });
};

export const VIRTUAL_PDV_REF: string = getConfigValue(
  'VIRTUAL_PDV_REF',
  '00000'
).toString();
