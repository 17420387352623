import ProductList from '@/lib/model/productList';
import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const list = action.payload.list as ProductList;

  return {
    ...baseData,
    page_type: 'recherche',
    page_name: action.payload.filters.length ? 'recherche_filtre' : 'recherche',
    page_virtual_path: `/courses/recherche/${action.payload.keyword}`,
    search_keywords: action.payload.keyword ?? '',
    search_page_number: list.meta.page ?? '',
    search_results_number: list.meta.total ?? ''
  };
};

export default getData;
