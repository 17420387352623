import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const type = action.payload.type as 'success' | 'error';
  const { name } = action.payload;

  let data = {};

  switch (name) {
    case 'signUp':
      data = {
        e_action: 'creation',
        e_category: 'compte'
      };
      break;
    case 'login':
      data = {
        e_action: 'connexion',
        e_category: 'compte'
      };
      break;
    case 'logout':
      data = {
        e_action: 'deconnexion',
        e_category: 'compte'
      };
      break;
    case 'delete':
      data = {
        e_action: 'suppression',
        e_category: 'compte'
      };
      break;
    case 'forgottenPassword':
      data = {
        e_action: 'mot de passe oublie',
        e_category: 'compte'
      };
      break;
    case 'changePassword':
      data = {
        e_action: 'mot_de_passe',
        e_category: 'compte'
      };
      break;
    case 'activation':
      data = {
        e_action: 'activation',
        e_category: 'compte'
      };
      break;
    case 'reorder':
      data = {
        e_action: 'repasser commande',
        e_category: 'compte'
      };
      break;
    case 'createShoppingList':
      data = {
        e_action: 'créer liste de course',
        e_category: 'compte'
      };
      break;
    case 'changeEmail':
      data = {
        e_action: 'changement adresse mail',
        e_category: 'compte'
      };
      break;
    case 'createFid':
      data = {
        e_action: 'creation carte',
        e_category: 'fid'
      };
      break;
    case 'attachCard':
      data = {
        e_action: 'rattachement carte',
        e_category: 'fid'
      };
      break;
    case 'removeFid':
      data = {
        e_action: 'dissociation carte',
        e_category: 'fid'
      };
      break;
    case 'shareStickers':
      data = {
        e_action: 'etat partage vignettes',
        e_category: 'fid'
      };
      break;
    case 'paymentStatus':
      data = {
        e_action: 'validation paiement',
        e_category: 'paiement'
      };
      break;
  }

  switch (type) {
    case 'success':
      data = {
        ...data,
        e_label: 'succes'
      };
      break;
    case 'error':
      data = {
        ...data,
        e_label: 'erreur'
      };
      break;
  }

  return {
    ...baseData,
    ...data
  };
};

export default getData;
