import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const type = action.payload.type as 'optin' | 'optout' | 'yes' | 'no';
  const { name } = action.payload;

  let data = {};

  switch (name) {
    case 'optOut.personalCommunication':
      data = {
        e_action: 'personnalisation communication'
      };
      break;
    case 'optOut.personalNavigation':
      data = {
        e_action: 'personnalisation navigation'
      };
      break;
    case 'optOut.usePersonalDataForAd':
      data = {
        e_action: 'ciblage publicitaire'
      };
      break;
    case 'optIn.itmEmail':
      data = {
        e_action: 'optin email itm'
      };
      break;
    case 'optIn.itmSms':
      data = {
        e_action: 'option sms itm'
      };
      break;
    case 'optIn.itmLetter':
      data = {
        e_action: 'option courrier itm'
      };
      break;
    case 'optIn.partnerEmail':
      data = {
        e_action: 'optin email partenaires'
      };
      break;
    case 'optIn.partnerSms':
      data = {
        e_action: 'optin sms partenaires'
      };
      break;
  }

  switch (type) {
    case 'optin':
      data = {
        ...data,
        e_label: 'optin'
      };
      break;
    case 'optout':
      data = {
        ...data,
        e_label: 'optout'
      };
      break;
    case 'yes':
      data = {
        ...data,
        e_label: 'oui'
      };
      break;
    case 'no':
      data = {
        ...data,
        e_label: 'non'
      };
      break;
  }

  return {
    ...baseData,
    ...data,
    e_category: 'compte'
  };
};

export default getData;
