import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const anchor = action.payload.anchor as string;

  return {
    ...baseData,
    page_type: 'jeu',
    page_name: 'luckycart',
    page_virtual_path: `jeu_concours/luckycart#code/${anchor}`
  };
};

export default getData;
