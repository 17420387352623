import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const productId = action.payload.productId as string;

  return {
    ...baseData,
    page_type: 'overlay produits similaires',
    page_name: 'produits similaires',
    page_virtual_path: `/courses/rayons/overlay_produits_similaires/${productId}`
  };
};

export default getData;
