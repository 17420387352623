import { AnyAction } from 'redux';
import Category from '@/lib/model/category';
import NavPromotion from '@/lib/model/navPromotion';
import { Dayjs } from 'dayjs';
import { REHYDRATE } from 'redux-persist';
import {
  TOGGLE_NAV_ACTIVE,
  TOGGLE_NAV_DESCUBRA_ACTIVE,
  CLOSE_NAV,
  UPDATE_MAIN_CATEGORIES,
  UPDATE_NAV_PROMOTIONS
} from './navActions';

type MainCategories = {
  data: Array<Category>;
  updatedAt: Dayjs | null;
};

type NavPromotions = {
  data: Array<NavPromotion>;
  updatedAt: Dayjs | null;
};

export type NavStateType = {
  isActive: boolean;
  isDescubraActive: boolean;
  isHydrated: boolean;
  mainCategories: { [key: string]: MainCategories };
  promotions: { [key: string]: NavPromotions };
};

const defaultState = {
  isActive: false,
  isDescubraActive: false,
  isHydrated: false,
  mainCategories: {},
  promotions: {}
};

const NavReducer = (state: NavStateType = defaultState, action: AnyAction) => {
  switch (action.type) {
    case CLOSE_NAV:
      return { ...state, isActive: false, isDescubraActive: false };
    case TOGGLE_NAV_ACTIVE:
      return { ...state, isActive: !state.isActive };
    case TOGGLE_NAV_DESCUBRA_ACTIVE:
      return { ...state, isDescubraActive: !state.isDescubraActive };
    case UPDATE_MAIN_CATEGORIES:
      return {
        ...state,
        mainCategories: {
          ...state.mainCategories,
          [action.payload.pdvRef]: action.payload.categories
        }
      };
    case UPDATE_NAV_PROMOTIONS:
      return {
        ...state,
        promotions: {
          ...state.promotions,
          [action.payload.pdvRef]: action.payload.promotions
        }
      };
    case REHYDRATE:
      if (action.key === 'nav' && action.payload) {
        return {
          ...state,
          isHydrated: action.payload['_persist'].rehydrated
        };
      }
      return state;

    default:
      return state;
  }
};

export default NavReducer;
