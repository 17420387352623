import { AnyAction } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { NewUserLocal } from './types';

import {
  USER_IDENTIFIANTS,
  USER_INFORMATIONS,
  USER_CONTACT,
  USER_FIDELITY,
  RESET_USER_REGISTER,
  CLEANING_REGISTER_STORE
} from './registerActions';

const initialState = {};

function registerReducer(prevState: NewUserLocal | any, action: AnyAction) {
  if (typeof prevState === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case USER_IDENTIFIANTS:
      return {
        ...prevState,
        pdv: action.payload.pdv,
        email: action.payload.email,
        password: action.payload.password
      };
    case USER_INFORMATIONS:
      return {
        ...prevState,
        isPro: action.payload.isPro,
        company: action.payload.company,
        hasFidelityCard: action.payload.hasFidelityCard,
        nif: action.payload.nif,
        fidelityCardNumber: action.payload.fidelityCardNumber,
        civility: action.payload.civility,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        birthdayDate: action.payload.birthdayDate
      };
    case USER_CONTACT:
      return {
        ...prevState,
        address: action.payload.address,
        additionalAddress: action.payload.additionalAddress,
        additionalInformation: action.payload.additionalInformation,
        zipCode: action.payload.zipCode,
        city: action.payload.city,
        country: action.payload.country,
        phone: action.payload.phone,
        mobilePhone: action.payload.mobilePhone,
        itmEmail: action.payload.itmEmail,
        itmSms: action.payload.itmSms,
        partnerEmail: action.payload.partnerEmail,
        partnerSms: action.payload.partnerSms,
        personalCommunication: action.payload.personalCommunication
      };
    case USER_FIDELITY:
      return {
        ...prevState,
        cguValided: action.payload.cguValided,
        cardInfos: action.payload.cardInfos
      };
    case RESET_USER_REGISTER:
      return { email: prevState.email };
    case CLEANING_REGISTER_STORE:
      return initialState;
    case REHYDRATE:
      if (action.key === 'register' && action.payload) {
        return {
          ...prevState,
          isHydrated: action.payload['_persist'].rehydrated
        };
      }
      return prevState;
    default:
      return prevState;
  }
}

export default registerReducer;
