import { QuestionnaireData, Response } from '@/lib/api/preferences';

export const UPDATE_SURVEY_ANSWER = 'UPDATE_SURVEY_ANSWER';
export const CLEAN_SURVEY_STORE = 'CLEAN_SURVEY_STORE';
export const INIT_SURVEY_STORE = 'INIT_SURVEY_STORE';
export const PASS_SURVEY_FIRST_STEPS = 'PASS_SURVEY_FIRST_STEPS';
export const FINISH_SURVEY_STEPS = 'FINISH_SURVEY_STEPS';
export const START_SURVEY = 'START_SURVEY';

export type UpdateSurveyAction = {
  type: typeof UPDATE_SURVEY_ANSWER;
  payload: {
    response: Response;
  };
};

export type CleanSurveyAction = {
  type: typeof CLEAN_SURVEY_STORE;
  payload: {
    withMessage: boolean;
  };
};

export type InitSurveyAction = {
  type: typeof INIT_SURVEY_STORE;
  payload: {
    survey: QuestionnaireData;
  };
};

export type StartSurvey = {
  type: typeof START_SURVEY;
};

export type PassSurveyFirstStepsAction = {
  type: typeof PASS_SURVEY_FIRST_STEPS;
};

export type FinishSurveyStepsAction = {
  type: typeof FINISH_SURVEY_STEPS;
};

export const passSurveyFirstStepsAction = (
  dispatch: React.Dispatch<PassSurveyFirstStepsAction>
) => () => {
  dispatch({
    type: PASS_SURVEY_FIRST_STEPS
  });
};

export const finishSurveyStepsAction = (
  dispatch: React.Dispatch<FinishSurveyStepsAction>
) => () => {
  dispatch({
    type: FINISH_SURVEY_STEPS
  });
};

export type Action =
  | UpdateSurveyAction
  | CleanSurveyAction
  | InitSurveyAction
  | PassSurveyFirstStepsAction
  | StartSurvey
  | FinishSurveyStepsAction
  | any;
