import { AnyAction } from 'redux';
import { Content as ContentfulContent } from '@/lib/contentful';
import { REHYDRATE } from 'redux-persist';
import {
  SET_FLOWS,
  SET_STATUS,
  SET_CGU,
  SET_PARTIAL_FLOWS,
  SET_CARD_LS_CREATION_STATE
} from './actions';

export type CardCreationStateType = {
  status: string;
  flows: {
    [key: string]: any;
  } | null;
  cgu: ContentfulContent;
  lsCreation: {
    inProgress: boolean;
    nextFidelityCardNumber: string;
  };
  isHydrated: boolean;
};

export const STATUS = {
  CGU: 'CGU',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  TIMEOUT: 'TIMEOUT',
  ERROR: 'ERROR',
  USER_IS_PRO: 'USER_IS_PRO',
  MISSING_USER_INFOS: 'MISSING_USER_INFOS'
};

const defaultState = {
  status: STATUS.CGU,
  flows: null,
  cgu: {
    components: []
  },
  isHydrated: false,
  lsCreation: {
    nextFidelityCardNumber: '',
    inProgress: false
  }
};

const CardCreationReducer = (
  state: CardCreationStateType = defaultState,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_STATUS:
      return { ...state, status: action.payload.status };
    case SET_FLOWS:
      return {
        ...state,
        status: defaultState.status,
        flows: action.payload.flows
      };
    case SET_CGU:
      return {
        ...state,
        cgu: action.payload.cgu
      };
    case SET_CARD_LS_CREATION_STATE:
      return {
        ...state,
        lsCreation: { ...action.payload }
      };
    case SET_PARTIAL_FLOWS:
      return {
        ...state,
        flows: {
          ...state.flows,
          [action.payload.field]: action.payload.value,
          status: defaultState.status
        }
      };
    case REHYDRATE:
      if (action.key === 'cardCreation' && action.payload) {
        return {
          ...state,
          isHydrated: action.payload['_persist'].rehydrated
        };
      }
      return state;
    default:
      return state;
  }
};

export default CardCreationReducer;
