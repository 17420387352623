export type Price = {
  currency: string;
  value: number;
  decimal: string;
  integer: string;
  concatenated?: string;
};

export const parseToPrice = (value = 0, currency = '€'): Price => {
  const price = value.toFixed(2).split('.');

  return {
    currency,
    value,
    integer: price[0],
    decimal: price[1],
    concatenated: `${price[0]},${price[1]}${currency}`
  };
};
