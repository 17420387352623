import getConfigValue from '@/lib/config';
import fetch from 'sync-fetch';

type Translation = { [key: string]: string };
type Catalog = { [key: string]: Translation };

const catalogs: { [key: string]: Catalog } = {};

const t = (
  text: string,
  replacements?: { [key: string]: any },
  catalog = 'common',
  locale = getConfigValue('LOCALE', 'pt').toString()
) => {
  if (!catalogs[locale]) {
    catalogs[locale] = {};
  }
  const useCdn = getConfigValue('USE_CDN', false).toBoolean();
  if (!catalogs[locale][catalog] && useCdn) {
    try {
      const CDN_TRAD = getConfigValue('CDN_TRAD').toString();
      catalogs[locale][catalog] = fetch(
        `${CDN_TRAD}/${locale}/${catalog}.json`
      ).json();
    } catch (error) {
      /* eslint-disable import/no-dynamic-require */
      catalogs[locale][
        catalog
      ] = require(`../../translations/${locale}/${catalog}.json`);
    }
  }
  if (
    !catalogs?.[locale]?.[catalog] ||
    !Object.keys(catalogs?.[locale]?.[catalog]).length
  ) {
    /* eslint-disable import/no-dynamic-require */
    catalogs[locale][
      catalog
    ] = require(`../../translations/${locale}/${catalog}.json`);
  }

  let value = text;

  if (catalogs[locale][catalog][text] !== undefined) {
    value = catalogs[locale][catalog][text];
  }

  if (replacements) {
    for (const [pattern, replacement] of Object.entries(replacements)) {
      value = value.replace(new RegExp(pattern, 'g'), replacement);
    }
  }

  return value;
};

export default t;
