import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const type = action.payload.type as 'goBack' | 'understood';
  let label = '';

  switch (type) {
    case 'goBack':
      label = 'retour';
      break;
    case 'understood':
      label = 'compris';
      break;
  }

  return {
    ...baseData,
    e_category: 'enseigne',
    e_action: 'charte alcool',
    e_label: label
  };
};

export default getData;
