import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const slug = action.payload.slug as string;
  const isPromo = slug.match(/^promotion/);

  return {
    ...baseData,
    page_type: 'bons plans',
    page_name: isPromo ? 'promotions' : 'jeu',
    page_virtual_path: isPromo
      ? `/sign/top-deals/promotion/${slug}`
      : `/sign/top-deals/${slug}`
  };
};

export default getData;
