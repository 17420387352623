import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  return {
    ...baseData,
    page_error: action.payload.statusCode
  };
};

export default getData;
