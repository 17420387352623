import base, { GlobalProductFormatter } from './baseEcommerce';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);
  const { product, path, typeRemove, keyword, position } = action.payload;

  const route = path.split('/').splice(1).join('/');
  const pageType = path.split('/')[1];

  const actionType = typeRemove ?? 'product';
  const autocomplete = typeRemove !== 'product' ? 'autocomplétion' : '';

  const productData = GlobalProductFormatter(product, route);

  const data = {
    ...productData,
    product_search_keywords: keyword,
    product_search_position: position
  };

  return {
    ...baseData,
    e_action: `remove from cart::${actionType}`,
    e_category: 'ecommerce',
    event: `remove from cart ${autocomplete}`,
    e_label: pageType,
    product_removefromcart: [data]
  };
};

export default getData;
