export const OptInMapping: { [key: string]: string } = {
  emailITM: 'itmEmail',
  smsITM: 'itmSms',
  mail: 'itmLetter',
  emailGrptPrn: 'partnerEmail',
  smsGrptPrn: 'partnerSms',
  prefSES: 'preferences'
};

export const OptInReverseMapping: { [key: string]: string } = {
  itmEmail: 'emailITM',
  itmSms: 'smsITM',
  itmLetter: 'mail',
  partnerEmail: 'emailGrptPrn',
  partnerSms: 'smsGrptPrn',
  preferences: 'prefSES'
};

export const OptOutMapping: { [key: string]: string } = {
  solCom: 'personalCommunication',
  navWeb: 'personalNavigation',
  crmDig: 'usePersonalDataForAds'
};

export const OptOutReverseMapping: { [key: string]: string } = {
  personalCommunication: 'solCom',
  personalNavigation: 'navWeb',
  usePersonalDataForAds: 'crmDig'
};
