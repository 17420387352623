import usePdv, { ReturnType } from '@/lib/hooks/usePdv';
import React, { useEffect } from 'react';
import pdvApi from '@/lib/api/pdv';
import { setPdvCookie } from '@/lib/utils/pdv';

const PdvCheck = () => {
  const checkPdvRef = async (urlPdv: string, pdv: ReturnType) => {
    const { ref, isVirtual } = pdv;
    const pdvInfo = await pdvApi.getPdvInfo(urlPdv);

    if (!pdvInfo?.ref && (!ref || isVirtual)) {
      window.location.href = '/';
    }
    if (pdvInfo?.ref) {
      setPdvCookie(pdvInfo);
      window.location.href = '/home';
    }
  };

  useEffect(() => {
    const pdv = usePdv();
    const { isVirtual, ref: pdvRef } = pdv;
    const urlParams = new URLSearchParams(window.location.search);
    const urlPdv = urlParams.get('pdv');
    const isPdvNumCorrect = urlPdv && /^\d{5}$/.test(urlPdv);
    const currentURL = window.location.href;
    const whitelistUrls = [
      '/orders/payment?token=',
      '/connection',
      '/forgotten-password',
      '/sign/magazine/regulamentos',
      '/sign/magazine/politica-de-privacidade',
      '/sign/magazine/recolha-de-produtos',
      '/sign/brands/catalog-page',
      '/fidelity-advantages',
      '/renew-password?token=',
      'my-account/creation/confirmation?UID='
    ];
    const isUrlWhitelisted = whitelistUrls.some((whitelistPath) =>
      currentURL.includes(whitelistPath)
    );

    if (!isUrlWhitelisted) {
      if ((!urlPdv || !isPdvNumCorrect) && (!pdvRef || isVirtual)) {
        window.location.href = '/';
      }
      if (urlPdv && isPdvNumCorrect) {
        checkPdvRef(urlPdv, pdv);
      }
    }
  }, []);

  return <></>;
};

export default PdvCheck;
