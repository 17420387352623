import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType as CartStateType } from '@/store/cart/types';
import { RESET_SLOTS } from '@/store/cart/cartActions';

const Slots = () => {
  const dispatch = useDispatch();
  // TODO : const isHydrated = useShallowEqualSelector(({ cart }) => cart.isHydrated);
  const { isHydrated } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      cart,
      isHydrated: cart.isHydrated
    };
  });

  useEffect(() => {
    (async () => {
      if (!isHydrated) return;

      if (!window.location.pathname.match(/\/orders\/.*/)?.length) {
        dispatch({ type: RESET_SLOTS });
      }
    })();
  }, [isHydrated, dispatch]);
  return <></>;
};

export default Slots;
