import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import ProductModel from '@/lib/model/product';
import ProductListModel from '@/lib/model/productList';

export type List = {
  id: number;
  name: string;
  count: number;
};

class ShoppingListApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  list = async (userId: string): Promise<Array<List>> => {
    let response;

    try {
      response = await this.axios.get(
        `consommateur/v1/consommateurs/${userId}/listes_courses`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch shopping list',
        error
      });
    }

    if (!response) {
      return [];
    }

    const lists: Array<List> = [];
    const listesCourse = response.data.listesCourse ?? [];

    listesCourse.forEach((elt: any) => {
      lists.push({
        id: elt.idListeCourses,
        name: elt.libelle,
        count: elt.nombreProduits
      });
    });

    return lists;
  };

  create = async (userId: string, name: string): Promise<void> => {
    try {
      await this.axios.post(
        `/consommateur/v1/consommateurs/${userId}/listes_courses`,
        {
          nom: name
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to create shopping list',
        error
      });

      throw error;
    }
  };

  delete = async (userId: string, listId: number): Promise<void> => {
    try {
      await this.axios.delete(
        `/consommateur/v1/consommateurs/${userId}/listes_courses/${listId}`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to delete shopping list',
        error
      });

      throw error;
    }
  };

  addProducts = async (
    userId: string,
    listId: number,
    pdvRef: string,
    products: Array<ProductModel>
  ): Promise<void> => {
    const data = products.map((product) => {
      return {
        ean: product.ean,
        privateData: `{"nfProductId":${product.id}}`,
        quantity: 1
      };
    });

    try {
      await this.axios.patch(
        `/consommateur/v1/consommateurs/${userId}/listes_courses/${listId}/produits?numero_pdv=${pdvRef}`,
        {
          produits: data
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to add products to shopping list',
        error
      });

      throw error;
    }
  };

  deleteProducts = async (
    userId: string,
    listId: number,
    pdvRef: string,
    products: Array<ProductModel>
  ): Promise<void> => {
    const data = products.map((product) => {
      return {
        ean: product.ean,
        privateData: `{"nfProductId":${product.id}}`,
        quantity: 0
      };
    });

    try {
      await this.axios.patch(
        `/consommateur/v1/consommateurs/${userId}/listes_courses/${listId}/produits?numero_pdv=${pdvRef}`,
        {
          produits: data
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to delete products from shopping list',
        error
      });

      throw error;
    }
  };

  listProducts = async (
    userId: string,
    listId: number,
    pdvRef: string,
    page = 1,
    size = 40
  ): Promise<ProductListModel> => {
    let response = null;

    try {
      response = await this.axios.post(
        `/consommateur/v1/consommateurs/${userId}/listes_courses/${listId}?numero_pdv=${pdvRef}`,
        {
          filtres: [],
          ordreTri: 'CROISSANT',
          page,
          size,
          tri: 'FAVORIS'
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to delete shopping list',
        error
      });

      throw error;
    }

    return new ProductListModel(response?.data ?? []);
  };

  updateListName = async (userId: string, listId: number, name: string) => {
    let response;

    try {
      response = await this.axios.patch(
        `/consommateur/v1/consommateurs/${userId}/listes_courses/${listId}`,
        {
          nom: name
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to update list name',
        error
      });
      throw new Error(error);
    }

    return response;
  };
}

export default new ShoppingListApi();
