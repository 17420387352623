import base from './base';

const getData = (action: any, state: any) => {
  const baseData = base(action, state);

  const type = action.payload.type as
    | 'chatOpening'
    | 'chatClosure'
    | 'addToShoppingList'
    | 'addUsualProduct';
  const { path, productId } = action.payload;
  let data = {};

  switch (type) {
    case 'chatOpening':
      data = {
        e_action: 'ouverture chatbot',
        e_label: path
      };
      break;
    case 'chatClosure':
      data = {
        e_action: 'fermeture chatbot',
        e_label: path
      };
      break;
    case 'addToShoppingList':
      data = {
        e_action: 'ajout liste de course',
        e_label: productId
      };
      break;
    case 'addUsualProduct':
      data = {
        e_action: 'ajouter produits habituels',
        e_label: productId
      };
      break;
  }

  return {
    ...baseData,
    ...data,
    e_category: 'engagement'
  };
};

export default getData;
