import { AnyAction } from 'redux';
import { List } from '@/lib/api/shoppingList';
import { UPDATE_LIST } from './listActions';

export type ListType = {
  list: Array<List>;
  selected: number | null;
};

const defaultState = {
  list: [],
  selected: null
};

const ListReducer = (state: ListType = defaultState, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_LIST:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

export default ListReducer;
