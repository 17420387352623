import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import getConfigValue from '@/lib/config';
import Cookie from 'js-cookie';
import * as Sentry from '@sentry/browser';
import usePdv from '@/lib/hooks/usePdv';
import { AuthStateType } from '@/store/auth/authReducer';

const SentryInit = () => {
  // TODO :   const userId = useShallowEqualSelector(selectUserId);
  const { userId } = useSelector(({ auth }: { auth: AuthStateType }) => {
    return {
      userId: auth.user?.id
    };
  });

  useEffect(() => {
    const { ref } = usePdv();

    Sentry.configureScope((scope) => {
      if (userId) {
        scope.setUser({ id: userId });
      } else {
        scope.setUser(null);
      }

      scope.setTag('pdv', ref);
      scope.setTag('session', Cookie.get('itm_usid'));
      scope.setTag(
        'environment',
        getConfigValue('APP_ENV', 'production').toString()
      );
    });
  }, [userId]);

  return <></>;
};

export default SentryInit;
