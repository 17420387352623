import React, { useEffect } from 'react';
import getConfigValue from '@/lib/config';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import Date from '@/lib/utils/date';

// Set "itm_device_id" cookie, if not set yet.

const SetCookieITMDeviceId = () => {
  useEffect(() => {
    if (Cookies.get('itm_device_id')) return;
    Cookies.set('itm_device_id', uuidv4(), {
      secure: getConfigValue('COOKIE_SECURE', true).toBoolean(),
      path: '/',
      expires: Date().add(1, 'year').toDate()
    });
  }, []);
  return <></>;
};

export default SetCookieITMDeviceId;
